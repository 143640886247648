export class GaugeItem {
  id: number;
  stationId: string;
  lat: string;
  lng: string;
  name: string;
}

export class WeatherInfoDTNError {
  errorKey: string;
  defaultMessage: string;
  statusCode: string;
  businessCode: string;
  rootCause: any;
  translatedMessage: string;
}

export class LocationResponse {

  locationResponse: WeatherResponseItem[];

}

export class WeatherResponseItem {
  avgSoilMoisture: UOMValueItem[];
  avgTemperature: UOMValueItem[];
  country: string[]; //["US"]
  endDate: string[];//["2018-09-30T06:00:00+00:00"]
  offset: string[];//["-6"]
  placeName: string[]; //["Allen Pecos"]
  soilMoistures: UOMValueItem[];
  startDate: string[]; //["2018-09-01T06:00:00+00:00"]
  stationID: string[]; //["DI7216"]
  stationLatitude: string[]; //["30.755626"]
  stationLongitude: string[]; //["-101.923079"]
  validDateTime: string[]; //["2018-09-01T06:00:00+00:00"];
  avgWindSpeed: UOMValueItem[];
  maxWindSpeed: UOMValueItem[];
  precipitationAmount: UOMValueItem[];
  solarRadiation: UOMValueItem[];
}

export class UOMValueItem {
  uom: string;
  value: string;
  notAvailable?: boolean;
  type?: string;
}

export class SensorItem {
  avgSoilMoisture: UOMValueItem[];
  depth: UOMValueItem[];
  id: string[];
  type: string[];
}

export class WeatherDataResponse {
  locationResponseList: LocationResponse[];
  $: any;
  error: any;
  station: any;
  retreiveTime?: any;
}

export class WeatherInfoQuickLookSummary {
  startDate: Date;
  endDate: Date;
  weatherInfo: any;
  loading?: boolean;
  errorKey?: string;
}

export class WeatherInfoSummary {

  error?: any;
  maxTemp?: UOMValueItem[];
  minTemp?: UOMValueItem[];
  avgTemp?: UOMValueItem[];
  maxWindSpeed?: UOMValueItem[];
  avgWindSpeed?: UOMValueItem[];
  precipitationAmounts?: UOMValueItem[];
  precipitationProbility?: any[];
  humidity?: UOMValueItem[];
  currentWindSpeed?: UOMValueItem[];
  currentWindDirection?: UOMValueItem[];
}
