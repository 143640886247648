import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserMarkerConfig } from '../../model';
import { SvgService } from '../svg-service';
import { CommonState } from '../../store/reducer';


const markerPath = 'assets/imgs/markers/';

@Injectable({
  providedIn: 'root'
})
export class UserMarkerConfigs {

  constructor(
    private svgService: SvgService,
    private store: Store<CommonState>
  ) {
  }

  markerTypes: UserMarkerConfig[];

  readonly generalMarkerType = 100;
  readonly waterSourceMarkerType = 200;


  public async getMarkerTypes(): Promise<UserMarkerConfig[]> {
    if (this.markerTypes) {
      return Promise.resolve(this.markerTypes);
    } else {
      const types = await this.store
        .select(state => state.mappings.markers.markerTypes)
        .pipe(
          filter(mt => !!mt && mt.length > 0),
          take(1)
        ).toPromise();
      this.markerTypes = types;
      return this.markerTypes;
    }
  }

  async getMarkerConfig(type: number): Promise<UserMarkerConfig> {
    const types = await this.getMarkerTypes();
    return types.find(m => +m.id === type) || types.find(marker => +marker.id === this.generalMarkerType);
  }

  async getMarkepPath(type: number) {
    const marker = await this.getMarkerConfig(type);
    return markerPath + marker.markerSVG;
  }

  async loadSvg(marker): Promise<string> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return await this.svgService.getSvgSrc(markerPath + marker.markerSVG).toPromise();
  }

  async getMarkerSvgSrc(type: number): Promise<string> {
    let config = await this.getMarkerConfig(type);
    if (config.markerSrc) {
      return config.markerSrc;
    } else {
      const markerSrc = await this.loadSvg(config);
      config = {...config, markerSrc}
      return markerSrc;
    }
  }

  async getTaskMarker() {
    const types = await this.getMarkerTypes();
    return types.find(marker => marker.taskMarker);
  }


}
