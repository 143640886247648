import { Inject, Injectable } from '@angular/core';
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { HttpClient } from "@angular/common/http";
import { ServicesCommon } from "./services-common.service";
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";
import { Observable } from "rxjs";
import { NotificationType } from "../model";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private currentState: CurrentState<CommonState>,
              protected http: HttpClient,
              private servicesCommon: ServicesCommon,
              @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment) {
  }

  public getTypes(): Observable<NotificationType[]> {
    console.log('getTypes');
    return this.servicesCommon.get<any>(
      `/private/notifications/types`);
  }

  getPushSubscriptions(userId, token, serial) {
    console.log('getPushSubscriptions');
    return this.servicesCommon.get<any>(
      `/private/notifications/${userId}/push/subscriptions/token/${token}/serial/${serial}`);
  }
}
