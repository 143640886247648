import { Component, Inject } from "@angular/core";
import {  MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'dialog-component',
    templateUrl: './dialog-component.html',
    styleUrls: ['./dialog-component.scss']

  })
  export class DialogComponent {

    file;
    text;
    select;
    checked;

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
      header: string,
      content?: string,
      primaryBtnLabel: string,
      secondaryBtnLabel?: string,
      fileInput?: boolean,
      textInput?: string[],
      select?: any[],
      label?: string,
      checkbox?: string,
    }) {
      if (this.data.textInput) {
        this.text = Array(this.data.textInput.length).fill(null);
      }
    }

    fileChanged(files) {
      if (files?.length) {
        this.file = files[0];
      }
    }

  }
