import { Action } from '@ngrx/store';
import { type } from "../types";
import { UserLogin } from "../../model";

export const AuthActionTypes = {
  LOGIN: type('[app] [Authentication] Login'),
  LOGIN_SUCCESS: type('[app] [Authentication] Login Success'),
  LOGIN_FAIL: type('[app] [Authentication] Login Fail'),
  LOGOUT: type('[app] [Authentication] Logout'),
  LOGOUT_SUCCESS: type('[app] [Authentication] Logout Success'),

  SAVE_REDIRECT_URL: type('[url] [Authentication] Save Redirect URL'),
  PIN_REQUEST_SUCCESS: type('[app] [Authentication] Pin Request Success'),
  LOAD_SESSION: type('[app] [Authentication] Load Session'),
  LOAD_SESSION_FROM_COOKIE: type('[app] [Authentication] Load Session From Cookie'),
  LOAD_SESSION_FROM_COOKIE_SUCCESS: type('[app] [Authentication] Load Session From Cookie Success'),
  DELETE_SESSION: type('[app] [Authentication] Delete session'),
  SET_SESSION_FROM_URL: type('[url] [Authentication] Set Session From URL'),
  SET_SESSION_TOKEN: type('[app] [Authentication] Set session token'),
  PUBLIC_MODE_SUCCESS: type('[app] [Authentication] Public mode success'),
};

export class LoginAction implements Action {
  type = AuthActionTypes.LOGIN;

  constructor(public payload: UserLogin) {
  }
}

export class LoginSuccessAction implements Action {
  type = AuthActionTypes.LOGIN_SUCCESS;

  constructor(public payload?: {
    created: number | string
    token: string
    userId: number | string
    validuntil: any
  }) {
  }
}

export class LoginFailAction implements Action {
  type = AuthActionTypes.LOGIN_FAIL;

  constructor(public errorCode: number, public payload: { error: any, loginData: UserLogin }) {
  }
}

export class LogoutAction implements Action {
  type = AuthActionTypes.LOGOUT;

  constructor(public payload?: string) {
  }
}

export class LogoutSuccessAction implements Action {
  type = AuthActionTypes.LOGOUT_SUCCESS;

  constructor(public payload?: string) {
  }
}

export class SaveRedirectURLAction implements Action {
  type = AuthActionTypes.SAVE_REDIRECT_URL;

  constructor(public payload: string) {
  }
}

export class LoadSessionAction implements Action {
  type = AuthActionTypes.LOAD_SESSION;

  constructor(public payload?: string) {
  }
}

export class LoadSessionFromCookieAction implements Action {
  type = AuthActionTypes.LOAD_SESSION_FROM_COOKIE;

  constructor(public payload?: string) {
  }
}

export class PinRequestSuccessAction implements Action {
  type = AuthActionTypes.PIN_REQUEST_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SetSessionFromURL implements Action {
  type = AuthActionTypes.SET_SESSION_FROM_URL;

  constructor(public payload: string) {
  }
}

export class PublicModeSuccessAction implements Action {
  type = AuthActionTypes.PUBLIC_MODE_SUCCESS;

  constructor() {
  }
}

export class DeleteSessionAction implements Action {
  type = AuthActionTypes.DELETE_SESSION;

  constructor(public payload: { sessionToken: string }) {
  }
}

export class SetSessionToken implements Action {
  type = AuthActionTypes.SET_SESSION_TOKEN;

  constructor(public payload: string) {
  }
}

export type AuthenticationActions
  = LoginAction
  | LoginSuccessAction
  | LoginFailAction
  | LogoutAction
  | LogoutSuccessAction
  | SaveRedirectURLAction
  | PinRequestSuccessAction
  | LoadSessionFromCookieAction
  | LoadSessionAction
  | SetSessionFromURL
  | DeleteSessionAction
  | SetSessionToken
  | PublicModeSuccessAction;
