import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { UserMarkerConfig, UserMarkerType } from '../../model';
import { UserMarkerConfigs } from '../../services/map';
import { SvgService } from '../../services/svg-service';


interface SanitizedUserMarker extends UserMarkerConfig {
  safeMarkerSrc: SafeHtml;
}

@Component({
  selector: 'marker-selector-component',
  templateUrl: './marker-selector.component.html',
  styleUrls: ['./marker-selector.component.scss'],
})
export class MarkerSelectorComponent implements OnInit {


  constructor(
    private markerConfigs: UserMarkerConfigs,
    private svgService: SvgService
  ) {
  }

  @Input() selectedMarker: number | string;
  @Input() selectedColor: string;
  @Output() selectedMarkerChange = new EventEmitter<UserMarkerType>();
  @Input() urgent: boolean;

  markers: SanitizedUserMarker[];

  async ngOnInit() {

    
    this.markers = [];
    const markerTypes = await this.markerConfigs.getMarkerTypes();
    markerTypes.filter(marker => !marker.taskMarker)
    .forEach(config => this.markerConfigs.getMarkerSvgSrc(+config.id).then(
      src => this.markers.push({ ...config, safeMarkerSrc: this.svgService.sanitize(src) })
      ));
    }
    
    onSelectedMarkerChange(marker) {
      this.selectedMarkerChange.emit(marker);
    }
    
    getClass(marker) {
      let s = '';
      
      s += (marker.id===this.selectedMarker) ? 'selected ' : '';
      s += (this.urgent) ? 'urgent' : '';
      
      return s;

  }

}
