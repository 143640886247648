import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  LoadTutorialsAction,
  LoadTutorialsFailedAction,
  LoadTutorialsSuccessAction,
  TutorialActionTypes
} from "./tutorial.actions";
import { CommonFeBaseEffects } from '../common-fe-base.effects';
import { TutorialEntity } from '../../model';
import { TutorialsService } from '../../services/tutorials-service';


@Injectable()
export class TutorialEffects extends CommonFeBaseEffects {

  @Effect()
  loadTutorials: Observable<Action>;

  constructor(
    private readonly tutorialService: TutorialsService,
    private actions: Actions
  ) {
    super();

    this.loadTutorials = this.actions.pipe(ofType(TutorialActionTypes.LOAD_TUTORIALS)
      , map((action: LoadTutorialsAction) => {
        console.log(action)
        return action.payload;
      })
      , switchMap((onlyActive) => {
        return this.tutorialService.getTasksTutorial().pipe(map((c: TutorialEntity[]) => {
          return new LoadTutorialsSuccessAction(c);
        }), catchError(error => {
          console.error('Error during loading loadAllCamerasMostRecent', error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadTutorialsFailedAction(error));
            observer.complete()
          });
        }))
      }));
  }
}
