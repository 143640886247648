import * as fromUIState from './ui/ui.reducer';

import { ActionReducerMap } from "@ngrx/store";
import { CommonState } from 'common-lib';

export interface PortalAppState extends CommonState {
  uiState: fromUIState.UIState;
}

export const reducers: ActionReducerMap<any> = {
  uiState: fromUIState.reducer,
};
