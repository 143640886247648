import { Injectable } from '@angular/core';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Fill, Stroke, Style, Text } from 'ol/style.js';
import { MapOperationService } from './map-operation.service';
import {Feature} from 'ol';
import { bbox } from 'ol/loadingstrategy';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import { Projection } from 'ol/proj';
import { HttpClient } from '@angular/common/http';
import GML2 from "ol/format/GML2";
import { CommonConstants } from '../../app.constants';

const GRID_COLOR = '49, 159, 211';
const RAINFALL_LAYER = 'rainfall';
const MAX_RAINFALL_GRID_RESOLUTION = 500;
const projectionCode = 'EPSG:3857';

@Injectable({
  providedIn: 'root'
})
export class RainfallGridService extends LayerBase {

  private rainfallMapServerUrl =
    `${CommonConstants.PARCEL.data_server}?map=/usr/share/rainfall.map&service=WFS&version=1.1.0&request=GetFeature&OUTPUTFORMAT=GML2&typename=rainfall&SRS=EPSG:3857`;

  constructor(
    mapOperationsService: MapOperationService,
    mapBubbleService: MapBubbleService,
    private http: HttpClient
    ) {
    super(mapOperationsService, mapBubbleService);
    this.layerName = RAINFALL_LAYER;
    this.maxResolution = MAX_RAINFALL_GRID_RESOLUTION;
  }

  getFeatureStyle(feature, fill) {
    const text = feature.values_.gridid;
    return new Style({
      text: new Text({
        text,
        font: '12px Calibri,sans-serif',
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      }),
      fill: new Fill({
        color: fill
      }),
      stroke: new Stroke({
        color: `rgb(${GRID_COLOR})`,
        width: 1
      }),
    });
  }

  baseStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature, 'transparent');
  };
  selectedStyle = (feature: Feature) => {
    return this.getFeatureStyle(feature, `rgba(${GRID_COLOR}, .5)`);
  };

  addRainFallGrid() {
    if (!this.layer) {
      this.mapOperationsService.fireEvent('loading', true, null);
      const projection = new Projection({
        code: projectionCode
      });
      const rainfallSource = new VectorSource({
        //FIXME szabi: 2022-05-18 this projection is gone after lib update?
        // projection,
        strategy: bbox,
        loader: (extent, resolution, projection) => {
          const proj = projection.getCode();
          this.mapOperationsService.fireEvent('loading', true, null);
          this.http.get(this.rainfallMapServerUrl + `&bbox=${extent.join(',')},${proj}`, {responseType: 'text'})
            .subscribe(data => {
              const format = new GML2();
              rainfallSource.addFeatures(format.readFeatures(data, {featureProjection: projection}));
            });
        }
      });

      this.layer = new VectorLayer({
        source: rainfallSource,
        style: (feature, resolution) => {
          return this.getFeatureStyle(feature, 'transparent');
        },
        maxResolution: this.maxResolution
      });

      
      console.log(this.layer)


      this.layer.on('change', e => {
        console.log('change')
        console.log(e)
        if (e.target.state_.sourceState === 'ready') {
          this.mapOperationsService.fireEvent('loading', false, null);
          this.layer.getSource().getFeatures().forEach(f => f.layerName = this.layerName);
        }
      });
      this.addLayer();
    } else {
      this.setLayerVisibility(true);
    }
  }

  show = (show: boolean) => {
    if (show) {
      this.addRainFallGrid();
    } else {
      this.setLayerVisibility(false);
    }
  };

  overlayHtml = (feature: Feature) => null;
}
