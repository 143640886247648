import { Action } from '@ngrx/store';
import { AdminActionTypes } from './admin.actions';
import { ActionWithPayload } from "../types";
import { MessageEntity, RanchOfUser, UserDetail, UserRequest } from "../../model";
import * as eq from "../../model/Equipments";
import { equipmentActionTypes } from "../equipments";


export interface AdminState {
  users: {
    entities: UserDetail[],
    pageSize: number,
    currentPage: number,
    hasNextPage: boolean
  };
  usersSearchString: string | null;
  selectedUser;
  roles: any[];
  selectedRole;
  actions: any[];
  sysfunctions: any[];
  interruptionMessages: MessageEntity [];
  ranchesOfSelectedUser: RanchOfUser[];
  assetsOfSelectedUser: { subscriptions: eq.SubscriptionDto[], ownedAndSharedEquipments: eq.EquipmentDto[] },
  ranches: any[];
  selectedRanch: any;
  userOfSelectedRanch: any[];
}


export const adminInitialState: AdminState = {
  users: {
    entities: [],
    currentPage: 1,
    pageSize: 24,
    hasNextPage: true,
  },
  usersSearchString: null,
  selectedUser: null,
  roles: [],
  selectedRole: null,
  actions: [],
  sysfunctions: [],
  interruptionMessages: [],
  ranchesOfSelectedUser: [],
  assetsOfSelectedUser: {
    subscriptions: [], ownedAndSharedEquipments: []
  },
  ranches: [],
  selectedRanch: null,
  userOfSelectedRanch: []
};


export function adminReducer(state: AdminState = adminInitialState, action: Action): AdminState {
  switch (action.type) {
    case AdminActionTypes.LOAD_USERS_NEXT_PAGE_SUCCESS: {
      const usersPage = (action as ActionWithPayload<UserDetail[]>).payload;
      let newUsersPage = state.users.entities.concat(usersPage);
      const key = 'id';
      newUsersPage = [...new Map(newUsersPage.map(item => [item[key], item])).values()]
      return {
        ...state,
        users: {
          ...state.users,
          entities: newUsersPage,
          currentPage: state.users.currentPage + 1,
          hasNextPage: usersPage.length !== 0
        }
      };
    }
    case AdminActionTypes.LOAD_FILTERED_USERS_SUCCESS: {
      const usersData = (action as ActionWithPayload<UserDetail[]>).payload;
      return {
        ...state,
        users: {
          ...state.users,
          entities: usersData,
          currentPage: 1,
          hasNextPage: false
        }
      };
    }
    case AdminActionTypes.RESET_USER_LIST:
      return {
        ...state,
        users: {...adminInitialState.users}
      };
    case AdminActionTypes.UPDATE_USER_SUCCESS: {
      const updatedUser = (action as ActionWithPayload<UserRequest>).payload;
      const updatedList = state.users.entities.map(
        usr => (usr.id === updatedUser.userId) ? {...usr, ...updatedUser} as UserDetail : usr
      );
      return {
        ...state,
        users: {
          ...state.users,
          entities: updatedList
        }
      };
    }
    case AdminActionTypes.CREATE_NEW_USER_SUCCESS: {
      let createdUser = {...(action as ActionWithPayload<any>).payload};
      createdUser.id = createdUser.userId;
      let updatedList = [...state.users.entities, createdUser];
      updatedList.sort((u1, u2) =>
        (u1.firstName + ' ' + u1.lastName < u2.firstName + ' ' + u2.lastName) ? 1 : -1);
      return {
        ...state,
        users: {
          ...state.users,
          entities: updatedList
        }
      };
    }
    case AdminActionTypes.DELETE_USER_SUCCESS: {
      const deletedUserId = (action as ActionWithPayload<number>).payload;
      return {
        ...state,
        users: {
          ...state.users,
          entities: state.users.entities.filter(usr => +usr.id !== deletedUserId)
        }
      };
    }
    case AdminActionTypes.LOAD_ACTIONS_SUCCESS:
      const actions = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        actions: actions
      };
    case AdminActionTypes.LOAD_SYSFUNCTIONS_SUCCESS:
      const sysfuncions = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        sysfunctions: sysfuncions
      };
    case AdminActionTypes.SELECT_ROLE_ENRICHED:
      const rolesArray = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        selectedRole: rolesArray[0]
      };
    case AdminActionTypes.LOAD_ASSETS_OF_SELECTED_USER_SUCCESS:
      const assets = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        assetsOfSelectedUser: assets
      };
    case AdminActionTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        selectedRole: null
      };
    case AdminActionTypes.LOAD_ROLES_SUCCESS:
      const roles = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        roles: roles
      };
    case AdminActionTypes.SELECT_USER_ENRICHED:
      const user = (action as ActionWithPayload<any>).payload;
      return {
        ...state,
        selectedUser: user
      };
    case AdminActionTypes.RESET_SELECTION:
      return {
        ...state,
        selectedUser: null,
        selectedRole: null
      };
    case AdminActionTypes.SET_USER_SEARCH_STRING:
      return {
        ...state,
        usersSearchString: (action as ActionWithPayload<any>).payload,
        users: {
          ...adminInitialState.users
        }
      };
    case AdminActionTypes.LOAD_RANCHES_OF_SELECTED_USER_SUCCESS:
      return {
        ...state,
        ranchesOfSelectedUser: (action as ActionWithPayload<any>).payload
      };
    case AdminActionTypes.LOAD_INTERRUPTION_MESSAGES_SUCCESS:
      let newMessage = (action as ActionWithPayload<any>).payload;
      if (!newMessage) {
        newMessage = {};
      }
      return {
        ...state,
        interruptionMessages: newMessage
      };
    case AdminActionTypes.SELECT_RANCH:
      return {
        ...state,
        selectedRanch: (action as ActionWithPayload<any>).payload[0]
      };
    case AdminActionTypes.LOAD_RANCHES_FOR_ADMIN:
      return {
        ...state,
        userOfSelectedRanch: []
      };
    case AdminActionTypes.LOAD_RANCHES_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        ranches: (action as ActionWithPayload<any>).payload
      };
    case AdminActionTypes.LOAD_USERS_OF_SELECTED_RANCH_SUCCESS:
      const payloadUsers = (action as ActionWithPayload<any>).payload;
      let usersOfSelectedRanch = [];
      if (payloadUsers && payloadUsers.length > 0) {
        usersOfSelectedRanch = payloadUsers[0].users;
      }
      return {
        ...state,
        userOfSelectedRanch: usersOfSelectedRanch
      };
    case equipmentActionTypes.RENAME_CAMERA_SUCCESS_ACTION: {
      const eqId = (action as ActionWithPayload<any>).payload.equipmentId;
      const newTitle = (action as ActionWithPayload<any>).payload.newTitle;
      let updatedAssetsOfSelectedUser = {...state.assetsOfSelectedUser};
      const udpatedEquipments = state.assetsOfSelectedUser.ownedAndSharedEquipments.map(e => {
        if (e.id == eqId) {
          e.cameraTitle = newTitle;
        }
        return e;
      });
      updatedAssetsOfSelectedUser.ownedAndSharedEquipments = udpatedEquipments;
      return {...state, assetsOfSelectedUser: updatedAssetsOfSelectedUser};
    }
    default:
      return state;
  }
}
