import { Action } from '@ngrx/store';
import { type } from "../types";
import { GPSEarTag, GPSTimeFrame } from "../../model";


export const GpsEarTagActionTypes = {
  SET_LATEST_POSITIONS: type('[app] [GpsEarTags] Set latest positions'),
  SET_TIME_FRAMES: type('[app] [GpsEarTags] Load timeframes'),
};

export class SetLatestPositionsAction implements Action {
  type = GpsEarTagActionTypes.SET_LATEST_POSITIONS;

  constructor(public payload: GPSEarTag[]) {
  }
}

export class SetGPSTimeFramesAction implements Action {
  type = GpsEarTagActionTypes.SET_TIME_FRAMES;

  constructor(public payload: GPSTimeFrame[]) {
  }
}

export declare type GpsEarTagActions =
  SetLatestPositionsAction
  | SetGPSTimeFramesAction
  ;
