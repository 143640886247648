import { GpsEarTagActions, GpsEarTagActionTypes } from './gpseartags.actions';
import { ActionWithPayload } from "../types";
import { GPSEarTag, GPSTimeFrame } from "../../model";


export interface GPSEarTagState {
  timeFrames: GPSTimeFrame[];
  latestPositions: GPSEarTag[];
  history: GPSEarTag[];
}


export const gpsEarTagInitialState: GPSEarTagState = {
  timeFrames: [],
  latestPositions: [],
  history: [],
};


export function reducer(state = gpsEarTagInitialState, action: GpsEarTagActions): GPSEarTagState {
  switch (action.type) {
    case GpsEarTagActionTypes.SET_LATEST_POSITIONS:
      return {
        ...state,
        latestPositions: (action as ActionWithPayload<any>).payload,
      };
    case GpsEarTagActionTypes.SET_TIME_FRAMES:
      return {
        ...state,
        timeFrames: (action as ActionWithPayload<any>).payload,
      };
    default:
      return state;
  }
}
