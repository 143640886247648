import { Action } from '@ngrx/store';
import { type } from "../types";
import { NotificationType } from "../../model";


export const NotificationsActionTypes = {
  LOAD_ALL_TYPES: type('[app] [Notifications] Load all types'),
  SET_MESSAGE_TYPES_ACTION: type('[app] [Notifications] Set message types'),
  LOAD_USER_PUSH_SUBSCRIPTIONS: type('[app] [Notifications] LOAD user push subscriptions'),
  SET_USER_PUSH_SUBSCRIPTIONS: type('[app] [Notifications] Set user push subscriptions'),

};

export class LoadAllTypesAction implements Action {
  type = NotificationsActionTypes.LOAD_ALL_TYPES;

  constructor() {
  }
}

export class SetMessageTypesAction implements Action {
  type = NotificationsActionTypes.SET_MESSAGE_TYPES_ACTION;

  constructor(public payload: NotificationType[]) {
  }
}

export class SetUserSubscriptionsAction implements Action {
  type = NotificationsActionTypes.SET_USER_PUSH_SUBSCRIPTIONS;

  constructor(public payload: { any }[]) {
  }
}

export class LoadUserPushSubscriptionsAction implements Action {
  type = NotificationsActionTypes.LOAD_USER_PUSH_SUBSCRIPTIONS;

  constructor(public payload: { token, userId, serial }) {
  }
}


export declare type NotificationsActions =
  LoadAllTypesAction | SetMessageTypesAction | SetUserSubscriptionsAction | LoadUserPushSubscriptionsAction;
