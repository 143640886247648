import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Observable, throwError, TimeoutError } from "rxjs";
import { Store } from "@ngrx/store";
import { ErrorHandler } from "common-lib";
import { CreateErrorMessage, CurrentState } from "common-lib";
import { PortalAppState } from "../store/reducer";

const TAG = 'HttpErrorHandlerInterceptor ';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
  constructor(protected store: Store<PortalAppState>, protected currentState: CurrentState<PortalAppState>,) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpError) => {
          console.error(TAG + '#intercept Error happened during intercept for request: ' + request.url);
          console.error(httpError);
          if (httpError instanceof TimeoutError) {
            console.error(TAG + '#intercept HTTP request timeout.');
            this.createErrorMessage(request, ErrorHandler.getErrorEntryForTimeout(httpError));
          } else if (httpError.status == 404) {
            const errorEntry = {
              errorKey: 'ERROR.GENERAL_404',
              defaultMessage: '',
              statusCode: '404',
              businessCode: 8402,
              rootCause: httpError,
              translatedMessage: null
            };
            this.createErrorMessage(request, errorEntry);
          } else if (httpError.status != 409 || httpError.status > 499 && httpError.status < 600) {
            const errorEntry = ErrorHandler.getErrorEntry(httpError);
            this.createErrorMessage(request, errorEntry);
          } else if (httpError.status == 0 && httpError.error instanceof ProgressEvent) {
            const errorEntryForZeroStatusError = ErrorHandler.getErrorEntryForZeroStatusError(httpError);
            this.createErrorMessage(request, errorEntryForZeroStatusError);
          }
          return throwError(httpError);
        }
      ));
  }

  protected createErrorMessage(request, error) {
    this.store.dispatch(new CreateErrorMessage(error));
  }
}
