import { Action } from '@ngrx/store';
import { type } from "../types";
import { MessageEntity, UserDetail } from "../../model";


export const AdminActionTypes = {
  //TODO cleanup actions
  LOAD_USERS_NEXT_PAGE: type('[net] [Admin] Users Load Next Page'),
  LOAD_USERS_NEXT_PAGE_SUCCESS: type('[net] [Admin] Users Load Next Page Success'),
  LOAD_USERS_NEXT_PAGE_FAIL: type('[net] [Admin] Users Load Next Page Fail'),
  LOAD_FILTERED_USERS_SUCCESS: type('[net] [Admin] Users Load Filtered Success'),
  LOAD_FILTERED_USERS_FAIL: type('[net] [Admin] Users Load Filtered Fail'),
  RESET_USER_LIST: type('[net] [Admin] Users Reset'),
  LOAD_ACTIONS: type('[net] [Admin] Actions Load'),
  LOAD_ACTIONS_SUCCESS: type('[net] [Admin] Actions Load Success'),
  LOAD_ROLES: type('[net] [Admin] Roles Load'),
  LOAD_ROLES_SUCCESS: type('[net] [Admin] Roles Load Success'),
  SELECT_ROLE: type('[net] [Admin] Role Select'),
  SELECT_ROLE_ENRICHED: type('[net] [Admin] Role Select Enriched'),
  SELECT_ROLE_SUCCESS: type('[net] [Admin] Role Select Success'),
  CREATE_NEW_ROLE: type('[net] [Admin] Role Create'),
  CREATE_NEW_ROLE_SUCCESS: type('[net] [Admin] Role Create Success'),
  CREATE_NEW_USER: type('[net] [Admin] User Create'),
  CREATE_NEW_USER_SUCCESS: type('[net] [Admin] User Create Success'),
  CREATE_NEW_USER_FAILED: type('[net] [Admin] User Create Failed'),
  DELETE_ROLE: type('[net] [Admin] Role Delete'),
  DELETE_ROLE_SUCCESS: type('[net] [Admin] Role Delete Success'),
  DELETE_USER: type('[net] [Admin] User Delete'),
  DELETE_USER_SUCCESS: type('[net] [Admin] User Delete Success'),
  DELETE_USER_FAIL: type('[net] [Admin] User Delete Fail'),
  LOAD_SYSFUNCTIONS: type('[net] [Admin] Sysfunctions Load'),
  LOAD_SYSFUNCTIONS_SUCCESS: type('[net] [Admin] Sysfunctions Load Success'),
  SELECT_USER: type('[net] [Admin] User Select'),
  SELECT_USER_LOADED: type('[net] [Admin] User Select Loaded'),
  SELECT_USER_ENRICHED: type('[net] [Admin] User Select Enriched'),
  UPDATE_USER: type('[net] [Admin] User Update'),
  UPDATE_USER_SUCCESS: type('[net] [Admin] User Update Success'),
  UPDATE_USER_FAIL: type('[net] [Admin] User Update Fail'),
  UPDATE_PASSWORD: type('[net] [Admin] Password Update'),
  UPDATE_PASSWORD_SUCCESS: type('[net] [Admin] Password Update Success'),
  UPDATE_PASSWORD_FAILED: type('[net] [Admin] Password Update Failed'),
  RESET_SELECTION: type('[net] [Admin] Reset user and role selection'),
  SAVE_NEW_PRF_ASSOCIATION_SUCCESS: type('[net] [Admin] Save new PRF Association success'),
  SAVE_NEW_PRF_ASSOCIATION_FAIL: type('[net] [Admin] Save new PRF Association fail'),
  SET_USER_SEARCH_STRING: type('[net] [Admin] Set user serach string'),
  LOAD_INTERRUPTION_MESSAGES: type('[net] [Admin] Load interruptions messages'),
  LOAD_INTERRUPTION_MESSAGES_SUCCESS: type('[net] [Admin] Load interruptions message success'),
  LOAD_INTERRUPTION_MESSAGES_FAIL: type('[net] [Admin] Load interruptions message fail'),
  LOAD_RANCHES_OF_SELECTED_USER_SUCCESS: type('[net] [Admin] Load ranches of selected user success'),
  LOAD_RANCHES_OF_SELECTED_USER_FAIL: type('[net] [Admin] Load ranches of selected user failed'),
  SELECT_RANCH: type('[net] [Admin] Select ranch admin'),
  LOAD_RANCHES_FOR_ADMIN: type('[net] [Admin] Load ranches for admin'),
  LOAD_RANCHES_FOR_ADMIN_SUCCESS: type('[net] [Admin] Load ranches for admin success'),
  LOAD_RANCHES_FOR_ADMIN_FAIL: type('[net] [Admin] Load ranches for admin fail'),
  LOAD_USERS_OF_SELECTED_RANCH_SUCCESS: type('[net] [Admin] Load users of selected ranch for admin success'),
  LOAD_USERS_OF_SELECTED_RANCH_FAIL: type('[net] [Admin] Load users of selected ranch for admin fail'),
  LOAD_ASSETS_OF_SELECTED_USER_SUCCESS: type('[net] [Admin] Load assets of selected user success'),
  SUPER_DUPER_LOGIN: type('[net] [Admin] Super-duper login'),
  SUPER_DUPER_LOGOUT: type('[net] [Admin] Super-duper logout'),
  SUPER_DUPER_LOGIN_SUCCESS: type('[net] [Admin] Super-duper login success'),
  SUPER_DUPER_LOGIN_FAIL: type('[net] [Admin] Super-duper login failed'),
};


export class LoadUserNextPageAction implements Action {
  type = AdminActionTypes.LOAD_USERS_NEXT_PAGE;

  constructor(public readonly payload?: { pageSize: number, pageNumber: number }) {}
}

export class LoadUserNextPageSuccessAction implements Action {
  type = AdminActionTypes.LOAD_USERS_NEXT_PAGE_SUCCESS;

  constructor(public readonly payload: UserDetail[]) {
  }
}

export class LoadUserNextPageFailAction implements Action {
  type = AdminActionTypes.LOAD_USERS_NEXT_PAGE_FAIL;

  constructor(public readonly payload: string) {
  }
}

export class LoadFilteredUsersSuccessAction implements Action {
  type = AdminActionTypes.LOAD_FILTERED_USERS_SUCCESS;

  constructor(public readonly payload: UserDetail[]) {
  }
}

export class LoadFilteredUsersFailAction implements Action {
  type = AdminActionTypes.LOAD_FILTERED_USERS_FAIL;

  constructor(public readonly payload: string) {
  }
}

export class ResetUserListAction implements Action {
  type = AdminActionTypes.RESET_USER_LIST;
}

// export class LoadUsersIncementallyAction implements Action {
//   type = AdminActionTypes.LOAD_USERS_ICREMENTALLY;
// }

// export class LoadUsersIncrementallySuccessAction implements Action {
//   type = AdminActionTypes.LOAD_USERS_ICREMENTALLY_SUCCESS;
//   constructor(public readonly payload) {
//   }

//}

// export class CreateAdminUserSuccessAction implements Action {
//   type = AdminActionTypes.CREATE_ADMIN_USER_SUCCESS;
//   constructor(public readonly payload) {
//   }
// }

// export class UpdateAdminUserSuccessAction implements Action {
//   type = AdminActionTypes.UPDATE_ADMIN_USER_SUCCESS;
//   constructor(public readonly payload: UserRequest) {
//   }
// }

export class LoadActionsAction implements Action {
  type = AdminActionTypes.LOAD_ACTIONS;

  constructor(public readonly payload = null) {
  }
}

export class LoadActionsSuccessAction implements Action {
  type = AdminActionTypes.LOAD_ACTIONS_SUCCESS;

  constructor(public readonly payload) {
  }
}

export class LoadSysfunctionsAction implements Action {
  type = AdminActionTypes.LOAD_SYSFUNCTIONS;

  constructor(public readonly payload = null) {
  }
}

export class LoadSysfunctionsSuccessAction implements Action {
  type = AdminActionTypes.LOAD_SYSFUNCTIONS_SUCCESS;

  constructor(public readonly payload) {
  }
}

export class LoadRolesAction implements Action {
  type = AdminActionTypes.LOAD_ROLES;

  constructor(public readonly payload) {
  }
}

export class LoadRolesSuccessAction implements Action {
  type = AdminActionTypes.LOAD_ROLES_SUCCESS;

  constructor(public readonly payload) {
  }
}

export class SelectUserAction implements Action {
  type = AdminActionTypes.SELECT_USER;

  constructor(public readonly payload: any) {
  }
}

export class SelectUserLoadedAction implements Action {
  type = AdminActionTypes.SELECT_USER_LOADED;

  constructor(public readonly payload: any) {
  }
}

export class SelectUserEnrichedAction implements Action {
  type = AdminActionTypes.SELECT_USER_ENRICHED;

  constructor(public readonly payload) {
  }
}

export class SelectRoleAction implements Action {
  type = AdminActionTypes.SELECT_ROLE;

  constructor(public readonly payload) {
  }
}

export class SelectRoleEnrichedAction implements Action {
  type = AdminActionTypes.SELECT_ROLE_ENRICHED;

  constructor(public readonly payload) {
  }
}

export class CreateRoleAction implements Action {
  type = AdminActionTypes.CREATE_NEW_ROLE;

  constructor(public readonly payload: any) {
  }
}

export class CreateRoleSuccessAction implements Action {
  type = AdminActionTypes.CREATE_NEW_ROLE_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class CreateUserAction implements Action {
  type = AdminActionTypes.CREATE_NEW_USER;

  constructor(public readonly payload: any) {
  }
}

export class CreateUserSuccessAction implements Action {
  type = AdminActionTypes.CREATE_NEW_USER_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class CreateUserFailedAction implements Action {
  type = AdminActionTypes.CREATE_NEW_USER_FAILED;

  constructor(public readonly payload: any) {
  }
}

export class UpdateUserAction implements Action {
  type = AdminActionTypes.UPDATE_USER;

  constructor(public readonly payload: any) {
  }
}

export class UpdateUserSuccessAction implements Action {
  type = AdminActionTypes.UPDATE_USER_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class UpdateUserFailAction implements Action {
  type = AdminActionTypes.UPDATE_USER_FAIL;

  constructor(public readonly payload: any) {
  }
}


export class DeleteUserAction implements Action {
  type = AdminActionTypes.DELETE_USER;

  constructor(public readonly payload: any) {
  }
}

export class DeleteUserSuccessAction implements Action {
  type = AdminActionTypes.DELETE_USER_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class DeleteUserFailAction implements Action {
  type = AdminActionTypes.DELETE_USER_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class DeleteRoleAction implements Action {
  type = AdminActionTypes.DELETE_ROLE;

  constructor(public readonly payload: any) {
  }
}

export class DeleteRoleSuccessAction implements Action {
  type = AdminActionTypes.DELETE_ROLE_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class UpdatePasswordSuccessAction implements Action {
  type = AdminActionTypes.UPDATE_PASSWORD_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class UpdatePasswordFailedAction implements Action {
  type = AdminActionTypes.UPDATE_PASSWORD_FAILED;

  constructor(public readonly payload: any) {
  }
}

export class UpdatePasswordAction implements Action {
  type = AdminActionTypes.UPDATE_PASSWORD;

  constructor(public readonly payload: any) {
  }
}

export class ResetSelectionAction implements Action {
  type = AdminActionTypes.RESET_SELECTION;

  constructor() {
  }
}


export class SaveNewPRFAssociationFailAction implements Action {
  type = AdminActionTypes.SAVE_NEW_PRF_ASSOCIATION_FAIL;

  constructor(public readonly payload?: any) {
  }
}

export class SaveNewPRFAssociationSuccessAction implements Action {
  type = AdminActionTypes.SAVE_NEW_PRF_ASSOCIATION_SUCCESS;

  constructor() {
  }
}

export class SetUserSearchString implements Action {
  type = AdminActionTypes.SET_USER_SEARCH_STRING;

  constructor(public readonly payload?: string) {
  }
}


export class LoadInterruptionMessagesAction implements Action {
  type = AdminActionTypes.LOAD_INTERRUPTION_MESSAGES;

  constructor() {
  }
}

export class LoadInterruptionMessagesFailedAction implements Action {
  type = AdminActionTypes.LOAD_INTERRUPTION_MESSAGES_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class LoadInterruptionMessagesSuccessAction implements Action {
  type = AdminActionTypes.LOAD_INTERRUPTION_MESSAGES_SUCCESS;

  constructor(public readonly payload: MessageEntity) {
  }
}

export class LoadRanchesOfSelectedUserActionSuccess implements Action {
  type = AdminActionTypes.LOAD_RANCHES_OF_SELECTED_USER_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class LoadRanchesOfSelectedUserActionFailed implements Action {
  type = AdminActionTypes.LOAD_RANCHES_OF_SELECTED_USER_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class LoadAssetsOfUserActionSuccess implements Action {
  type = AdminActionTypes.LOAD_ASSETS_OF_SELECTED_USER_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class LoadRanchesForAdminAction implements Action {
  type = AdminActionTypes.LOAD_RANCHES_FOR_ADMIN;

  constructor(public readonly payload: any) {
  }
}

export class LoadRanchesForAdminSuccessAction implements Action {
  type = AdminActionTypes.LOAD_RANCHES_FOR_ADMIN_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class LoadRanchesForAdminFailedAction implements Action {
  type = AdminActionTypes.LOAD_RANCHES_FOR_ADMIN_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class LoadUsersOfSelectedRanchesForAdminSuccessAction implements Action {
  type = AdminActionTypes.LOAD_USERS_OF_SELECTED_RANCH_SUCCESS;

  constructor(public readonly payload: any) {
  }
}

export class LoadUsersOfSelectedRanchesForAdminFailedAction implements Action {
  type = AdminActionTypes.LOAD_USERS_OF_SELECTED_RANCH_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class SelectAdminRanchAction implements Action {
  type = AdminActionTypes.SELECT_RANCH;

  constructor(public readonly payload: any) {
  }
}


export class SuperDuperLoginAction implements Action {
  type = AdminActionTypes.SUPER_DUPER_LOGIN;

  constructor(public readonly payload: any) {
  }
}

export class SuperDuperLogoutAction implements Action {
  type = AdminActionTypes.SUPER_DUPER_LOGOUT;

  constructor() {
  }
}

export class SuperDuperLoginFailedAction implements Action {
  type = AdminActionTypes.SUPER_DUPER_LOGIN_FAIL;

  constructor(public readonly payload: any) {
  }
}

export class SuperDuperLoginSuccessAction implements Action {
  type = AdminActionTypes.SUPER_DUPER_LOGIN_SUCCESS;

  constructor(public readonly payload: any) {
  }
}


export type AdminActions =
  LoadRolesSuccessAction
  | DeleteRoleAction
  | DeleteRoleSuccessAction
  | LoadRolesAction
  | LoadSysfunctionsAction
  | LoadSysfunctionsSuccessAction
  | LoadActionsSuccessAction
  | SelectUserAction
  | ResetSelectionAction
  | SelectUserEnrichedAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | SelectRoleAction
  | CreateRoleAction
  | CreateRoleSuccessAction
  | CreateUserAction
  | CreateUserSuccessAction
  | CreateUserFailedAction
  | UpdatePasswordAction
  | UpdatePasswordSuccessAction
  | UpdatePasswordFailedAction
  | UpdateUserFailAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserFailAction
  | SaveNewPRFAssociationFailAction
  | SaveNewPRFAssociationSuccessAction
  | SetUserSearchString
  | LoadActionsAction
  | LoadInterruptionMessagesAction
  | LoadInterruptionMessagesFailedAction
  | LoadInterruptionMessagesSuccessAction
  | LoadRanchesOfSelectedUserActionSuccess
  | LoadRanchesOfSelectedUserActionFailed
  | LoadRanchesForAdminAction | LoadRanchesForAdminSuccessAction | LoadRanchesForAdminFailedAction
  | LoadUsersOfSelectedRanchesForAdminSuccessAction | LoadUsersOfSelectedRanchesForAdminFailedAction
  | SelectAdminRanchAction | LoadAssetsOfUserActionSuccess
  | SuperDuperLoginAction | SuperDuperLoginSuccessAction | SuperDuperLoginFailedAction|SuperDuperLogoutAction;

