import { EquipmentActions, equipmentActionTypes } from './equipments.actions';
import * as eq from '../../model/Equipments';
import { ActionWithPayload } from '../types';


export interface EquipmentState {
  subscriptionTypes: eq.SubscriptionType[];
  subscriptionPackages: eq.SubscriptionPackage[];
  cellPlans: eq.CellPlan[];
  cellProviders: eq.CellProvider[];
  equipmentTypes: eq.EquipmentType[];
  equipments: eq.EquipmentDto[];
  subscriptions: eq.SubscriptionDto[];
  formSuccess: boolean | null;
  selectedSubscription: number | string;
  selectedEquipments: number[] | string[];
};

const equipmentsInitialState: EquipmentState = {
  subscriptionTypes: [],
  subscriptionPackages: [],
  cellPlans: [],
  cellProviders: [],
  equipmentTypes: [],
  equipments: [],
  subscriptions: [],
  formSuccess: null,
  selectedEquipments: [],
  selectedSubscription: null
};


export function reducer(state = equipmentsInitialState, action: EquipmentActions & ActionWithPayload<any>): EquipmentState {
  switch (action.type) {
    case equipmentActionTypes.LOAD_SUBSCRIPTION_TYPES_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, subscriptionTypes: action.payload};
      }
      break;
    }
    case equipmentActionTypes.LOAD_SUBSCRIPTION_PACKAGES_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, subscriptionPackages: action.payload};
      }
      break;
    }
    case equipmentActionTypes.LOAD_CELL_PLANS_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, cellPlans: action.payload};
      }
      break;
    }
    case equipmentActionTypes.LOAD_CELL_PROVIDERS_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, cellProviders: action.payload};
      }
      break;
    }
    case equipmentActionTypes.LOAD_EQUIPMENT_TYPES_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, equipmentTypes: action.payload};
      }
      break;
    }
    case equipmentActionTypes.LOAD_USER_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, subscriptions: action.payload};
      }
      break;
    }
    case equipmentActionTypes.LOAD_USER_EQUIPMENTS_SUCCESS: {
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, equipments: action.payload};
      }
      break;
    }
    case equipmentActionTypes.ADD_SUBSCRIPTION:
    case equipmentActionTypes.ADD_EQUIPMENT: {
      return {...state, formSuccess: null};
    }
    case equipmentActionTypes.ADD_SUBSCRIPTION_SUCCESS: {
      if (action.payload) {
        const subscriptions = [...state.subscriptions, action.payload.subscription];
        return {...state, subscriptions, formSuccess: true};
      }
      break;
    }
    case equipmentActionTypes.ADD_EQUIPMENT_SUCCESS: {
      if (action.payload) {
        const equipments = [...state.equipments, action.payload.equipment];
        return {...state, equipments, formSuccess: true};
      }
      break;
    }
    case equipmentActionTypes.ADD_EQUIPMENT_FAILURE:
    case equipmentActionTypes.ADD_SUBSCRIPTION_FAILURE: {
      return {...state, formSuccess: false};
    }
    case equipmentActionTypes.DELETE_USER_EQUIPMENT_SUCCESS: {
      if (action.payload) {
        const id = +action.payload.equipmentId;
        const equipments = state.equipments.filter(e => e.id !== id);
        return {...state, equipments};
      }
      break;
    }
    case equipmentActionTypes.DELETE_USER_ALL_EQUIPMENTS_SUCCESS: {
      if (action.payload) {
        const equipments = [];
        return {...state, equipments};
      }
      break;
    }
    case equipmentActionTypes.DELETE_USER_SUBSCRIPTION_SUCCESS: {
      if (action.payload) {
        const id = +action.payload.subscriptionId;
        const subscriptions = state.subscriptions.filter(s => s.id !== id);
        return {...state, subscriptions};
      }
      break;
    }
    case equipmentActionTypes.SELECT_ASSET_MONITOR_EQUIPMENTS: {
      const selectedEquipmentIds = (action as ActionWithPayload<any>).payload;
      if (action.payload && Array.isArray(action.payload)) {
        return {...state, selectedEquipments: selectedEquipmentIds};
      }
      break;
    }
    case equipmentActionTypes.SELECT_ASSET_MONITOR_SUBSCRIPTION: {
      const selectedSubscriptionId = (action as ActionWithPayload<any>).payload;
      return {...state, selectedSubscription: selectedSubscriptionId};
      break;
    }
    case equipmentActionTypes.RENAME_CAMERA_SUCCESS_ACTION: {
      const eqId = (action as ActionWithPayload<any>).payload.equipmentId;
      const newTitle = (action as ActionWithPayload<any>).payload.newTitle;
      const udpatedEquipments = state.equipments.map(e => {
        if (e.id == eqId) {
          e.cameraTitle = newTitle;
        }
        return e;
      });
      return {...state, equipments: udpatedEquipments};
      break;
    }
    default:
      return state;
  }

}

