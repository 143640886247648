import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Boundary } from '../../model';
import {LineString} from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { Observable, Subject } from 'rxjs';
import { DrawingService } from './drawing.service';
import { GeometryService } from './geometry.service';
import { MapOperationService } from './map-operation.service';
import { WKTGeometryConverter } from './wkt-converter.service';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import * as martinez from 'martinez-polygon-clipping';

const COLOR = 'rgb(255, 127, 80)';

interface Section {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}


@Injectable({
  providedIn: 'root'
})
export class BoundarySplitService {

  private splitSubject = new Subject<{ added: Boundary[], removed: Boundary }>();
  public split$: Observable<{ added: Boundary[], removed: Boundary }> = this.splitSubject.asObservable();

  constructor(
    private mapOperationsService: MapOperationService,
    private wktGeometryConverter: WKTGeometryConverter,
    private drawingService: DrawingService,
    private geometryService: GeometryService,
    private translateService: TranslateService
  ) {
  }

  readonly parallelDistance = 0.05;

  private getSlopeAndIscAndAngle(
    section: Section
  ): { slope?: number; isc: number; angle: number } {
    const slope = (section.y2 - section.y1) / (section.x2 - section.x1);
    const angle = Math.atan(slope);
    const isc = section.y1 - slope * section.x1;
    return {
      slope,
      angle,
      isc
    };
  }

  private getPerpendicularLineSlopeAndIscAndAngle(
    section: Section,
    x: number,
    y: number
  ): { slope: number; isc: number; angle: number } {
    if (section.y1 === section.y2) {
    }
    const slope = (section.x1 - section.x2) / (section.y2 - section.y1);
    const angle = Math.atan(slope);
    const isc = y - slope * x;
    return {
      slope,
      angle,
      isc
    };
  }

  private calculateParallelY(x: number, section: Section) {
    const {slope, isc, angle} = this.getSlopeAndIscAndAngle(section);
    return (
      slope * x +
      isc +
      (section.x1 < section.x2
        ? this.parallelDistance / Math.cos(angle)
        : -this.parallelDistance / Math.cos(angle))
    );
  }

  private getParallel(section, parallelDistance): Section {
    let x1, x2, y1, y2;
    if (this.isHorizontal(section)) {
      console.log('horz');
      y1 = y2 =
        section.y1 +
        (section.x1 < section.x2 ? parallelDistance : -parallelDistance);
      x1 = section.x1 + (section.x1 < section.x2 ? -100 : 100);
      x2 = section.x2 + (section.x1 < section.x2 ? 100 : -100);
    } else if (this.isVertical(section)) {
      console.log('vert');
      x1 = x2 =
        section.x1 +
        (section.y1 < section.y2 ? parallelDistance : -parallelDistance);
      y1 = section.y1 + (section.y1 < section.y2 ? -100 : 100);
      y2 = section.y2 + (section.y1 < section.y2 ? 100 : -100);
    } else {
      x1 = section.x1 + (section.x1 < section.x2 ? -100 : 100);
      y1 = this.calculateParallelY(x1, section);
      x2 = section.x2 + (section.x1 < section.x2 ? 100 : -100);
      y2 = this.calculateParallelY(x2, section);
    }
    return {
      x1,
      y1,
      x2,
      y2
    };
  }

  private getSections(geometryPoints: any[]): Section[] {
    const sections = [];
    const coordinates = geometryPoints;
    if (coordinates.length < 1) {
      return [];
    }
    for (let i = 1; i < coordinates.length; i++) {
      sections.push({
        x1: coordinates[i - 1][0],
        y1: coordinates[i - 1][1],
        x2: coordinates[i][0],
        y2: coordinates[i][1]
      });
    }
    return sections;
  }

  private isVertical(section: Section) {
    return section.x1 === section.x2;
  }

  private isHorizontal(section: Section) {
    return section.y1 === section.y2;
  }

  private calculateIntersectionWithVertical(
    verticalSection: Section,
    section: Section
  ) {
    if (this.isVertical(section)) {
      return null;
    }
    if (this.isHorizontal(section)) {
      return {x: verticalSection.x1, y: section.y1};
    }
    const line = this.getSlopeAndIscAndAngle(section);
    const x = verticalSection.x1;
    const y = line.slope * x + line.isc;
    return {x, y};
  }

  private getIntersection(
    section1: Section,
    section2: Section
  ): { x: number; y: number } {
    if (this.isVertical(section1)) {
      return this.calculateIntersectionWithVertical(section1, section2);
    }
    if (this.isVertical(section2)) {
      return this.calculateIntersectionWithVertical(section2, section1);
    }
    const line1 = this.getSlopeAndIscAndAngle(section1);
    const line2 = this.getSlopeAndIscAndAngle(section2);
    if (line1.slope === line2.slope) {
      return null; // parallel
    }
    const x = (line2.isc - line1.isc) / (line1.slope - line2.slope);
    const y = line1.slope * x + line1.isc;
    return {x, y};
  }

  private getFinalIntersection(
    section1: Section,
    section2: Section,
    x1: number,
    y1: number
  ): { x: number; y: number } {
    if (this.isHorizontal(section1)) {
      return {x: x1, y: section2.y1};
    }
    if (this.isVertical(section1)) {
      return {x: section2.x1, y: y1};
    }
    const line1 = this.getPerpendicularLineSlopeAndIscAndAngle(section1, x1, y1);
    const line2 = this.getSlopeAndIscAndAngle(section2);
    const x = (line2.isc - line1.isc) / (line1.slope - line2.slope);
    const y = line1.slope * x + line1.isc;
    return {x, y};
  }

  private lengthenSection(section, start) {
    const {angle} = this.getSlopeAndIscAndAngle(section);
    if (start && section.x1 < section.x2) {
      section.x1 = section.x1 - Math.cos(angle) * 10;
      section.y1 = section.y1 - Math.sin(angle) * 10;
    } else if (start && section.x1 > section.x2) {
      section.x1 = section.x1 + Math.cos(angle) * 10;
      section.y1 = section.y1 + Math.sin(angle) * 10;
    } else if (!start && section.x1 < section.x2) {
      section.x2 = section.x2 + Math.cos(angle) * 10;
      section.y2 = section.y2 + Math.sin(angle) * 10;
    } else {
      section.x2 = section.x2 - Math.cos(angle) * 10;
      section.y2 = section.y2 - Math.sin(angle) * 10;
    }
  }

  private getParallelPoly(lineString: any[], distance): any[][] {
    if (lineString[lineString.length - 1][0] === lineString[lineString.length - 2][0] &&
      lineString[lineString.length - 1][1] === lineString[lineString.length - 2][1]) {
      lineString.pop();
    }
    const sections = this.getSections(lineString);
    this.lengthenSection(sections[0], true);
    this.lengthenSection(sections[sections.length - 1], false);
    const parallels = sections.map(s => this.getParallel(s, distance));
    const intersectionPoints = [];
    for (let i = 0; i < parallels.length - 1; i++) {
      const isc = this.getIntersection(parallels[i], parallels[i + 1]);
      intersectionPoints.push(isc);
    }
    let pLine = intersectionPoints.map(({x, y}) => [x, y]);
    const firstPoint = this.getFinalIntersection(
      sections[0],
      parallels[0],
      sections[0].x1,
      sections[0].y1
    );
    pLine.unshift([firstPoint.x, firstPoint.y]);
    const lastPoint = this.getFinalIntersection(
      sections[sections.length - 1],
      parallels[parallels.length - 1],
      sections[sections.length - 1].x2,
      sections[sections.length - 1].y2
    );
    pLine.push([lastPoint.x, lastPoint.y]);
    pLine = pLine.reverse();
    lineString[0] = [sections[0].x1, sections[0].y1];
    lineString[lineString.length - 1] = [sections[sections.length - 1].x2, sections[sections.length - 1].y2];
    // console.log('parallel polygon', [...pLine, ...lineString, pLine[0]]);
    return [[...pLine, ...lineString, pLine[0]]];
  }

  private splitPolygon(
    lineString: any[],
    polygon: Boundary
    ): { splits: any[] } | null {
    try {
      const parallelPoly = this.getParallelPoly(lineString, this.parallelDistance);
      const poly = this.wktGeometryConverter.toGeometry(polygon.geometry);
      const coordinates = (poly as SimpleGeometry).getCoordinates();
      this.geometryService.cleanCoordinates(coordinates);
      if (poly) {
        const diff = martinez.diff(coordinates, parallelPoly);
        const isc = martinez.intersection(coordinates, parallelPoly);
        if (diff && isc) {
          const unionDiff = (diff as []).map(d => {
            const unions = (martinez.union([(d as any[])[0]], isc) as any[])
              .filter(u => {
                const identical = (isc as any[]).filter(i => this.geometryService.equals(u, i));
                return !identical.length;
              })
            return unions;
          }).map(d => d[0]);
          // I would like to keep this comment
          //  const unionDiff = (diff as []).map(d => martinez.union([(d as any[])[0]], isc))
          //     .filter(d => d.length).map(d => d[0]);
          return {
            splits: unionDiff
          };
        }
      }
    } catch (ex) {
      console.error('Error splitting polygons');
    }
    return null;
  }

  public split(source: VectorSource, boundary: Boundary) {
    const options = {
      onDrawFinish: (coordinates) => {  
        this.mapOperationsService.freezeMap(false);
        if (coordinates) {
          const {added, removed} = this.getSplits(coordinates, boundary);
          if (added && added.length > 1) {
            this.drawingService.finishDraw();
          }
          this.splitSubject.next({added, removed});
        }
      },
      lineColor: COLOR,
      pointColor: COLOR,
      splitBoundary: this.wktGeometryConverter.toGeometry(boundary.geometry) as SimpleGeometry,
      removeControls: false,
      finishButtonTitle: 'Split'
    };
    this.drawingService.startDrawMultiLine(options);
    this.mapOperationsService.freezeMap(true);
  }

  findParallels(line: LineString, polygon: any[]) {
    const lineSegments = this.getSections(line.getCoordinates()).map(section =>
      ({...section, slope: this.getSlopeAndIscAndAngle(section).slope}));
    const polygonSegments = this.getSections(polygon).map(section =>
      ({...section, slope: this.getSlopeAndIscAndAngle(section).slope}));
    const pointsToCorrect = [];
    lineSegments.forEach(ls => {
      const parallels = polygonSegments.filter(ps => ps.slope === ls.slope);
      parallels.forEach(pps => {
        if ((this.geometryService.distance([pps.x1, pps.y1], [ls.x1, ls.y1]) < this.parallelDistance * 6) &&
          (this.geometryService.distance([pps.x2, pps.y2], [ls.x2, ls.y2]) < this.parallelDistance * 6)) {
          pointsToCorrect.push({
            from: [pps.x1, pps.y1],
            to: [ls.x1, ls.y1]
          });
          pointsToCorrect.push({
            from: [pps.x2, pps.y2],
            to: [ls.x2, ls.y2]
          });
        } else if ((this.geometryService.distance([pps.x1, pps.y1], [ls.x2, ls.y2]) < this.parallelDistance * 6) &&
          (this.geometryService.distance([pps.x2, pps.y2], [ls.x1, ls.y1]) < this.parallelDistance * 6)) {
          pointsToCorrect.push({
            from: [pps.x1, pps.y1],
            to: [ls.x2, ls.y2]
          });
          pointsToCorrect.push({
            from: [pps.x2, pps.y2],
            to: [ls.x1, ls.y1]
          });
        }
      });
    });
    // console.log(JSON.stringify(pointsToCorrect, null, 3));
    pointsToCorrect.forEach(point => {
      polygon.forEach((coords, i) => {
        if (coords.x === point.from.x && coords.y === point.from.y) {
          polygon[i] = point.to;
        }
      });
    });
    return polygon;
  }

  getPairedVertices(lineString: LineString, parallelPolygon: any[]) {
    try {
      const pairCoords = [];
      const lineCoords = lineString.getCoordinates();
      const parallelPolyCoords = parallelPolygon[0][0];
      parallelPolyCoords.pop();
      if (parallelPolyCoords.length / 2 !== lineCoords.length) {
        console.warn('Invalid parallel polygon');
      }
      parallelPolyCoords.forEach((coords, i) => {
        const closestPoint = lineString.getClosestPoint(coords);
        if (closestPoint) {
          const distance = this.geometryService.distance(coords, closestPoint);
          if (distance < 0.01) {
            const pairPointIdx = i < parallelPolyCoords.length / 2 ?
              i + parallelPolyCoords.length / 2 : i - parallelPolyCoords.length / 2;
            pairCoords.push({
              from: parallelPolyCoords[pairPointIdx],
              to: coords
            })
          }
        }
      });
      return pairCoords;
    } catch (ex) {
      console.error('getPairedVertices Error', ex);
    }

  }

  correctWithPair(coords, pairedVertices) {
    const pair = pairedVertices.find(ver => (ver.from[0] === coords[0] && ver.from[1] === coords[1]));
    // if (pair) {
    //     console.log('pair coordinate found');
    // }
    return pair ? pair.to : coords;
  }

  getSplits(lineString: any[], boundary: Boundary) {
    let added = [];
    let removed = null;
    const {splits} = this.splitPolygon(lineString, boundary);
    if (splits && splits.length > 0) {
      added = splits
        .map((spl, idx) =>
          ({
            geometry:
              this.wktGeometryConverter.polygonCoords2WKT(spl)
          }));
      removed = boundary;
    }
    return {added, removed};
  }

}
