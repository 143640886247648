import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { ENVIRONMENT_TOKEN } from "../../environment/token-variables";
import { RanchRequest, UserRequest } from "../model";

@Injectable()
export class RanchService {
  constructor(private currentState: CurrentState<CommonState>,
              // protected ga: GoogleAnalyticsService,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              protected http: HttpClient) {
  }

  public searchRanches(searchString: string) {
    const path = '/private/ranches/search/';
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    const url = this.environment.backend + path + searchString;
    // this.ga.trackServiceCall('searchRanches', CommonConstants.GA.LABEL.RANCHES);
    return this.http.get(url, {
      headers: headers
    });
  }

  loadUsersForRanch(ranchIds: string[]) {
    let promises = [];
    const path = '/private/ranches/';
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    // this.ga.trackServiceCall('loadUsersForRanch', CommonConstants.GA.LABEL.RANCHES);
    ranchIds.forEach(ranchId => {
      const url = this.environment.backend + path + ranchId + '/users';
      const obs = this.http.get(url, {
        headers: headers
      }).pipe(map(response => {
        return {users: response, ranchId: ranchId};
      }));
      promises.push(obs.toPromise());
    });
    let promiseAll = Promise.all(promises);
    return from(promiseAll);
  }

  loadRanchesForUser(request: RanchRequest) {
    const path = '/private/ranches/';
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    const url = this.environment.backend + path + 'users/' + request.userId;
    // this.ga.trackServiceCall('loadRanchesOfUser', CommonConstants.GA.LABEL.RANCHES);
    return this.http.get(url, {
      headers: headers
    });
  }

  updateUserForRanch(request: UserRequest, ranchId) {
    const path = '/private/ranches/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + ranchId + '/users/' + request.userId;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    // this.ga.trackServiceCall('updateUserForRanch', CommonConstants.GA.LABEL.RANCHES);
    return this.http.put(url, request, options).pipe(map(response => {
      return {success: true};
    }), catchError(error => {
      return of({success: false, error});
    }));
  }

  removeUserFromRanch(userId: any, ranchId: any) {
    const path = '/private/ranches/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + ranchId + '/users/' + userId;
    // this.ga.trackServiceCall('removeUserFromRanch', CommonConstants.GA.LABEL.RANCHES);
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  addUserToRanch(requests: { userId: any, ranchManager: boolean }[], ranchId: any) {
    const path = '/private/ranches/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + ranchId + '/users/';
    //this.ga.trackServiceCall('addUserToRanch', CommonConstants.GA.LABEL.RANCHES);
    return this.http.post(url, requests, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  createNewRanch(request: RanchRequest) {
    const path = '/private/ranches/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    const options = {
      headers: headers
    };
    //this.ga.trackServiceCall('createNewRanch', CommonConstants.GA.LABEL.RANCHES);
    return this.http.post(url, request, options);

  }

  loadRanches(pagingObject: { pageSize: number, pageNumber: number }) {
    const path = '/private/ranches/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;
    let params = new HttpParams();
    if (pagingObject && pagingObject.pageSize && pagingObject.pageNumber) {
      params = new HttpParams().set('pageSize', pagingObject.pageSize + '');
      params = params.set('pageNumber', pagingObject.pageNumber + '');
    }
    //this.ga.trackServiceCall('loadRanches', CommonConstants.GA.LABEL.RANCHES);
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      }), params
    });
  }

  loadCommonUsersForUser(userId: any) {
    const path = '/private/ranches/users/';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + userId + '/members';
    //this.ga.trackServiceCall('loadCommonUsersForUser', CommonConstants.GA.LABEL.RANCHES);
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public shareCameraWithUser(managerId: any, userId: any, cameraIds: any[], ranchId: any) {
    let shareObs = [];
    const path = '/private/ranches/users/';
    const token = this.currentState.snapshot.user.token;
    for (let i = 0; i < cameraIds.length; i++) {
      let url = this.environment.backend + path + managerId + '/equipment/';
      let request = {
        "userId": userId,
        "equipmentId": cameraIds[i],
        ranchId: ranchId
      };
      let obs = this.http.post(url, request, {
        headers: new HttpHeaders({
          'Authorization': token
        })
      }).pipe(map(response => {
        return {success: true};
      }), catchError(error => {
        return of({error, success: false});
      }));
      shareObs.push(obs);
    }
    return combineLatest(shareObs);
  }


  public removeSharedCameraOfUser(userId: any, cameraIds: any[]) {
    let deleteObs = [];
    const path = '/private/ranches/users/';
    const token = this.currentState.snapshot.user.token;
    for (let i = 0; i < cameraIds.length; i++) {
      let url = this.environment.backend + path + userId + '/equipment/' + cameraIds[i];
      let obs = this.http.delete(url, {
        headers: new HttpHeaders({
          'Authorization': token
        })
      }).pipe(map(response => {
        return {success: true};
      }), catchError(error => {
        return of({error, success: false});
      }));
      deleteObs.push(obs);
    }
    return combineLatest(deleteObs);
  }

  public shareBoundariesWithRanch(boundaryIds: [], ranchId: any) {
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + `/private/ranches/${ranchId}/boundaries`;
    const request = {
      userId: this.currentState.snapshot.user.id,
      boundaryIds: boundaryIds,
      ranchId
    }
    return this.http.post(url, request, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }
}
