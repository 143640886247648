import { Injectable } from '@angular/core';
import { State } from '@ngrx/store';

/** Provides a snapshot of the Store at a given moment in time. Can be used if future changes are irrelevant. */
@Injectable()
export class CurrentState<T> {
	/** The current value of the Store. */
	public snapshot: T;

	constructor(state: State<T>) {
		// NOTE: State lives for the entirety of the application's lifetime, no need to unsubscribe
		state.subscribe(current => this.snapshot = current);
	}
}
