import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { UserService } from "../../services/user.service";
import * as userActions from "./user.actions";
import {
  DeleteAccount,
  LoadPRFAssociationsAction,
  LoadUserSuccessAction,
  SetPRFAssociationsAction,
  SetTermsAndConditionsAction,
  UpdateTermsAndConditionsAction,
  UserActionTypes as userActionTypes
} from "./user.actions";
import * as authActions from "../authentication/authentication.actions";
import { CommonState } from "../reducer";
import { CurrentState } from "../current-state";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { UpdateNotificationAction, UpdateNotificationSuccessAction } from '.';
import { Animal, Boundary, TermsAndConditions } from "../../model";
import { GOOGLE_ANALYTICS_TOKEN } from "../../../environment/token-variables";


@Injectable()
export class UserEffects extends CommonFeBaseEffects {

  @Effect()
  updateTermsAndCondition: Observable<Action>;

  @Effect()
  increaseParcelUsage: Observable<Action>;

  @Effect()
  deleteUserAccount: Observable<Action>;

  @Effect()
  updateNotifications: Observable<Action>;

  @Effect()
  loadPrfAssociations: Observable<Action>;

  constructor(
    protected currentState: CurrentState<CommonState>,
    @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
    private actions: Actions, private userService: UserService) {
    super();


    this.updateTermsAndCondition = this.actions.pipe(ofType(userActionTypes.UPDATE_TERMS_AND_CONDITIONS_ACTION)
      , map((action: UpdateTermsAndConditionsAction) => {
        return action.payload;
      }), switchMap((payload) => this.userService.updateTermsAndConditions(
          payload.userId, payload.termsId, payload.accepted
        ).pipe(map((response: TermsAndConditions[]) => new SetTermsAndConditionsAction({termsAndConditions: response}))
          , catchError(err => this.handleHttpError(err))
        )
      ));

    this.increaseParcelUsage = this.actions.pipe(ofType(userActionTypes.INCREASE_PARCEL_LAYER_USAGE_COUNT)
      , switchMap(() => this.userService.increaseParcelUsage()
        .pipe(map(response => new userActions.SetParcelUsage(response))
          , catchError(err => this.handleHttpError(err))
        )));

    this.deleteUserAccount = this.actions.pipe(ofType(userActionTypes.DELETE_ACCOUNT),
      map((deleteAction: DeleteAccount) => {
        return deleteAction.payload;
      }), switchMap((userId) => this.userService.deleteAccount(userId)
        .pipe(map((response: Object) => new authActions.LogoutAction(userId)
        ), catchError(err => this.handleHttpError(err)))));

    this.updateNotifications = this.actions.pipe(ofType(userActionTypes.UPDATE_NOTIFICATION),
      map((action: UpdateNotificationAction) => {
        return action.payload;
      }),
      switchMap((payload) => {
        const userId = this.currentState.snapshot.user.id;
        return this.userService.updateNotification(userId, payload.id, payload.show).pipe(
          map((response: Object) => new UpdateNotificationSuccessAction(payload.id)),
          catchError(err => this.handleHttpError(err))
        )
      })
    );
    this.loadPrfAssociations = this.actions.pipe(
      ofType(
        userActionTypes.LOAD_PRF_ASSOCIATIONS,
        userActionTypes.LOAD_USER_SUCCESS),
      map((action: LoadUserSuccessAction | LoadPRFAssociationsAction) => !!action.payload?.userId ? action.payload?.userId : action.payload),
      switchMap((action: any) => {
        let userId = action;
        if (!!(action as Boundary).id || (action as Animal[]).length) {
          userId = this.currentState.snapshot.user.id;
        }
        return this.userService.loadPrfAssociations(userId).pipe(
          map(response => {
            return new SetPRFAssociationsAction({associations: response, userId});
          }),
          catchError(err => this.handleHttpError(err))
        );
      })
    );
  }

  getUserDetails(userId: number) {
    return this.userService.getUserDetails(userId);
  }

  registerUser(userData) {
    return this.userService.registerUser(userData);
  }
}


