import { Action } from '@ngrx/store';
import { type, ActionWithPayload } from '../types';
import * as img from '../../model/Images';
import { EquipmentDto } from '../../model';


export const ImageActionTypes = {
  LOAD_USER_CAMERAS: type('[app] [Images] Load User Cameras'),
  LOAD_USER_CAMERAS_SUCCESS: type('[app] [Images] Load User Cameras Success'),
  LOAD_USER_CAMERAS_FAILURE: type('[app] [Images] Load User Cameras Failure'),
  LOAD_QUICK_LOOK: type('[app] [Images] Load Quick Look'),
  LOAD_QUICK_LOOK_SUCCESS: type('[app] [Images] Load Quick Look Success'),
  LOAD_QUICK_LOOK_FAILURE: type('[app] [Images] Load Quick Look Failure'),
  LOAD_CAMERA_IMAGES: type('[app] [Images] Load Camera Images'),
  LOAD_CAMERA_IMAGES_SUCCESS: type('[app] [Images] Load Camera Images Success'),
  LOAD_CAMERA_IMAGES_FAILURE: type('[app] [Images] Load Camera Images Failure'),
  RESET_CAMERA_IMAGES: type('[app] [Images] Reset Camera Images'),
  SET_SELECTED_DATE: type('[app] [Images] Set Selected Date'),
  SET_SELECTED_CAMERAS: type('[app] [Images] Set Selected Cameras'),
  LOAD_MULTIPLE_CAMERA_IMAGES: type('[app] [Images] Load Multiple Camera Images'),
  LOAD_MULTIPLE_CAMERA_IMAGES_SUCCESS: type('[app] [Images] Load Multiple Camera Images Success'),
  LOAD_MULTIPLE_CAMERA_IMAGES_FAILURE: type('[app] [Images] Load Multiple Camera Images Failure'),
  LOAD_FAVORITE_IMAGES: type('[app] [Images] Load favorite Images'),
  LOAD_FAVORITE_IMAGES_SUCCESS: type('[app] [Images] Load favorite Images Success'),
  LOAD_FAVORITE_IMAGES_FAILURE: type('[app] [Images] Load favorite Images Failure'),
  REMOVE_FAVORITE_IMAGES_SUCCESS: type('[app] [Images] Remove favorite Images Success'),
  ADD_FAVORITE_IMAGES_SUCCESS: type('[app] [Images] Add favorite Images Success'),
  ADD_FAVORITE_IMAGES_FAILED: type('[app] [Images] Add favorite Images failed'),
  REMOVE_FAVORITE_IMAGES_FAILED: type('[app] [Images] Remove favorite Images failed'),
  ADD_FAVORITE_IMAGE: type('[app] [Images] Add favorite Image'),
  REMOVE_FAVORITE_IMAGE: type('[app] [Images] Remove favorite Image')
};


export class LoadUserCamerasAction implements Action {
  readonly type = ImageActionTypes.LOAD_USER_CAMERAS;

  constructor(public payload: { userId: number, loadSharedCameras: boolean }) {
  }
}

export class LoadUserCamerasSuccessAction implements Action {
  readonly type = ImageActionTypes.LOAD_USER_CAMERAS_SUCCESS;

  constructor(public payload: EquipmentDto[]) {
  }
}

export class LoadUserCamerasFailureAction implements Action {
  readonly type = ImageActionTypes.LOAD_USER_CAMERAS_FAILURE;

  constructor(public payload: any) {
  }
}

export class LoadQuickLookAction implements Action {
  readonly type = ImageActionTypes.LOAD_QUICK_LOOK;

  constructor(public payload: { userId: number }) {
  }
}

export class LoadQuickLookSuccessAction implements Action {
  readonly type = ImageActionTypes.LOAD_QUICK_LOOK_SUCCESS;

  constructor(public payload: img.Image[]) {
  }
}

export class LoadQuickLookFailureAction implements Action {
  readonly type = ImageActionTypes.LOAD_QUICK_LOOK_FAILURE;
}

export class LoadCameraImagesAction implements Action {
  readonly type = ImageActionTypes.LOAD_CAMERA_IMAGES;

  constructor(public payload: { equipmentId: number, date: string }) {
  }
}

export class LoadCameraImagesSuccessAction implements Action {
  readonly type = ImageActionTypes.LOAD_CAMERA_IMAGES_SUCCESS;

  constructor(public payload: img.Image[]) {
  }
}

export class LoadCameraImagesFailureAction implements Action {
  readonly type = ImageActionTypes.LOAD_CAMERA_IMAGES_FAILURE;
}

export class LoadMultipleCameraImagesAction implements Action {
  readonly type = ImageActionTypes.LOAD_MULTIPLE_CAMERA_IMAGES;

  constructor(public payload: { equipmentIds: number[], date: string }) {
  }
}

export class LoadMultipleCameraImagesSuccessAction implements Action {
  readonly type = ImageActionTypes.LOAD_MULTIPLE_CAMERA_IMAGES_SUCCESS;

  constructor(public payload: img.Image[][]) {
  }
}

export class LoadMultipleCameraImagesFailureAction implements Action {
  readonly type = ImageActionTypes.LOAD_MULTIPLE_CAMERA_IMAGES_FAILURE;
}

export class ResetCameraImagesAction implements Action {
  readonly type = ImageActionTypes.RESET_CAMERA_IMAGES;
}

export class SetSelectedCamerasAction implements Action {
  readonly type = ImageActionTypes.SET_SELECTED_CAMERAS;

  constructor(public payload: number[]) {
  }
}

export class SetSelectedDateAction implements Action {
  readonly type = ImageActionTypes.SET_SELECTED_DATE;

  constructor(public payload: string) {
  }
}

export class LoadFavoriteImagesAction implements Action {
  readonly type = ImageActionTypes.LOAD_FAVORITE_IMAGES;

  constructor() {
  }
}

export class LoadFavoriteImagesSuccessAction implements Action {
  readonly type = ImageActionTypes.LOAD_FAVORITE_IMAGES_SUCCESS;

  constructor(public payload: img.Image[]) {
  }
}

export class LoadFavoriteImagesFailedAction implements Action {
  readonly type = ImageActionTypes.LOAD_FAVORITE_IMAGES_FAILURE;

  constructor(public payload: any) {
  }
}

export class RemoveFavoriteImagesSuccessAction implements Action {
  readonly type = ImageActionTypes.REMOVE_FAVORITE_IMAGES_SUCCESS;

  constructor(public payload: any) {
  }
}


export class RemoveFavoriteImageFailedAction implements Action {
  readonly type = ImageActionTypes.REMOVE_FAVORITE_IMAGES_FAILED;

  constructor(public payload: any) {
  }
}

export class AddFavoriteImageFailedAction implements Action {
  readonly type = ImageActionTypes.ADD_FAVORITE_IMAGES_FAILED;

  constructor(public payload: any) {
  }
}

export class AddFavoriteImagesSuccessAction implements Action {
  readonly type = ImageActionTypes.ADD_FAVORITE_IMAGES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class AddFavoriteImageAction implements Action {
  readonly type = ImageActionTypes.ADD_FAVORITE_IMAGE;

  constructor(public payload: any) {
  }
}

export class RemoveFavoriteImageAction implements Action {
  readonly type = ImageActionTypes.REMOVE_FAVORITE_IMAGE;

  constructor(public payload: any) {
  }
}

export type ImageActions =
  LoadUserCamerasAction |
  LoadUserCamerasFailureAction |
  LoadUserCamerasSuccessAction |
  LoadQuickLookAction |
  LoadQuickLookSuccessAction |
  LoadQuickLookFailureAction |
  LoadCameraImagesAction |
  LoadCameraImagesFailureAction |
  LoadCameraImagesSuccessAction |
  LoadMultipleCameraImagesAction |
  LoadMultipleCameraImagesSuccessAction |
  LoadMultipleCameraImagesFailureAction |
  ResetCameraImagesAction |
  SetSelectedCamerasAction |
  LoadFavoriteImagesAction |
  LoadFavoriteImagesSuccessAction |
  LoadFavoriteImagesFailedAction |
  RemoveFavoriteImageFailedAction |
  AddFavoriteImageFailedAction |
  AddFavoriteImagesSuccessAction |
  RemoveFavoriteImagesSuccessAction |
  RemoveFavoriteImageAction |
  AddFavoriteImageAction |
  SetSelectedDateAction;




