import { BoundaryActions, BoundaryActionTypes } from './boundaries.actions';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from '../../types';
import { Boundary } from '../../../model';

export interface BoundariesState {
    boundaries: Boundary[]
}

const boundariesInitialState: BoundariesState = {
    boundaries: []
};

export function boundariesReducer(state = boundariesInitialState, action: BoundaryActions & ActionWithPayload<any>): BoundariesState {
    switch (action.type) {
        case BoundaryActionTypes.LOAD_USER_BOUNDARIES_SUCCESS: {
            if (action.payload && Array.isArray(action.payload)) {
                return { ...state, boundaries: action.payload };
            }
            break;
        }
        case BoundaryActionTypes.UPDATE_BOUNDARY_SUCCESS: {
            const updatedBoundary = (action as ActionWithPayload<any>).payload;
            const boundaries = state.boundaries.filter(b => b.id !== updatedBoundary.id);
            boundaries.push(updatedBoundary);
            return { ...state, boundaries };
        }
        case BoundaryActionTypes.MASS_UPDATE_BOUNDARIES: {
            const payload = (action as ActionWithPayload<any>).payload;
            const newBoundaries = payload.newBoundaries;
            const removedIds = payload.removedBoundaryIds;
            let updatedBoundaries = [...state.boundaries];
            if (!!removedIds && removedIds.length>0) {
                removedIds.forEach(removedId => {
                    updatedBoundaries = updatedBoundaries.filter(boundary => boundary.id != removedId);
                });
            }
            newBoundaries.forEach(newBoundary => {
                updatedBoundaries.push(newBoundary);
            });
            return { ...state, boundaries: updatedBoundaries };
        }
        case BoundaryActionTypes.CREATE_BOUNDARIES_FROM_PARENT_SUCCESS: {
            const { newBoundaries, deletedBoundaryId } = (action as ActionWithPayload<any>).payload;
            let boundaries = state.boundaries.filter(b => b.id !== deletedBoundaryId);
            // const newBoundary = (action as ActionWithPayload<any>).payload;
            return { ...state, boundaries: [...boundaries, ...newBoundaries] };
        }
        case BoundaryActionTypes.CREATE_BOUNDARY_SUCCESS: {
            const newBoundary = (action as ActionWithPayload<any>).payload;
            return { ...state, boundaries: [...state.boundaries, newBoundary] };
        }
        case BoundaryActionTypes.DELETE_BOUNDARY_SUCCESS: {
            const removedBoundary = (action as ActionWithPayload<any>).payload;
            const boundaries = state.boundaries.map(boundary => {
                let b = {...boundary};
                if (b.id == removedBoundary.id) {
                    b.deleted = true;
                }
                return b;
            });
            return { ...state, boundaries };
        }
        default: {
            return state;
        }
    }
}
