import { ActionWithPayload } from '../types';
import * as dev from './dev.actions';
import { DevActions } from './dev.actions';


export interface DevState {
  hasInternetConnection: boolean;
  autoLogin: boolean,
  autoLoginUsername: string,
  autoLoginPassword: string,
  mockFavoriteStations: boolean,
  mockFirstUsage: boolean,
  showErrorCause: boolean,
  mockTexasLocation: boolean,
  forcedParcelTierBuy: boolean,
  unlimitedParcelUsage: boolean,
  withStorageCalculation: boolean,
  positionTracking: boolean,
  quickEnableInternet: boolean,
  showId: boolean,
  disableHXGNLayer: boolean
}


export const initialState: DevState = {
  autoLogin: false,
  autoLoginUsername: null,
  autoLoginPassword: null,
  mockFavoriteStations: false,
  mockFirstUsage: false,
  showErrorCause: false,
  mockTexasLocation: false,
  forcedParcelTierBuy: false,
  unlimitedParcelUsage: false,
  hasInternetConnection: true,
  withStorageCalculation: false,
  positionTracking: false,
  quickEnableInternet: false,
  showId: false,
  disableHXGNLayer: false
};

export function reducer(state = initialState, action: DevActions): DevState {
  switch (action.type) {
    case dev.DevActionTypes.SWITCH_CONNECTION:
      const currentConnection = state.hasInternetConnection;
      return {
        ...state,
        hasInternetConnection: !currentConnection
      };
    case dev.DevActionTypes.SET_DEV_SETTINGS:
      return (action as ActionWithPayload<DevState>).payload;
    default:
      return state;
  }
}
