import { Action } from "@ngrx/store";
import { FinanceCategory, FinanceRequest, ItemEntity, ItemType } from "../../model/FinanceModel";
import { type } from "../types";


export const FinanceActionTypes = {

  LOAD_ITEM_TYPES: type('[app] [FINANCE] Load item types'),
  LOAD_ITEM_TYPES_FAILED: type('[app] [FINANCE] Load item types FAILED'),
  LOAD_ITEM_TYPES_SUCCESS: type('[app] [FINANCE] Load item types SUCCESS'),

  ADD_NEW_EXPENSE: type('[app] [FINANCE] Add new expenses'),
  ADD_NEW_EXPENSE_FAILED: type('[app] [FINANCE] Add new expenses FAILED'),
  ADD_NEW_EXPENSE_SUCCESS: type('[app] [FINANCE] Add new expenses SUCCESS'),

  LOAD_ALL_EXPENSE: type('[app] [FINANCE] Load all expense'),
  LOAD_ALL_EXPENSE_FAILED: type('[app] [FINANCE] Load all expense FAILED'),
  LOAD_ALL_EXPENSE_SUCCESS: type('[app] [FINANCE] Load all expense SUCCESS'),
  LOAD_ALL_EXPENSE_BY_ID: type('[app] [FINANCE] Load expense by ID'),

  LOAD_ALL_ITEM: type('[app] [FINANCE] Load all items'),
  LOAD_ALL_ITEM_FAILED: type('[app] [FINANCE] Load all items FAILED'),
  LOAD_ALL_ITEM_SUCCESS: type('[app] [FINANCE] Load all items SUCCESS'),

  ADD_NEW_ITEM: type('[app] [FINANCE] Add new item'),
  ADD_NEW_ITEM_FAILED: type('[app] [FINANCE] Add new item FAILED'),
  ADD_NEW_ITEM_SUCCESS: type('[app] [FINANCE] Add new item SUCCESS'),

  UPDATE_ITEM: type('[app] [FINANCE] Update item'),
  UPDATE_ITEM_FAILED: type('[app] [FINANCE] Update item FAILED'),
  UPDATE_ITEM_SUCCESS: type('[app] [FINANCE] Update item SUCCESS'),

  DELETE_ITEM: type('[app] [FINANCE] Delete item'),
  DELETE_ITEM_FAILED: type('[app] [FINANCE] Delete item FAILED'),
  DELETE_ITEM_SUCCESS: type('[app] [FINANCE] Delete item SUCCESS'),

  UPDATE_EXPENSE: type('[app] [FINANCE] Update expense'),
  UPDATE_EXPENSE_FAILED: type('[app] [FINANCE] Update expense FAILED'),
  UPDATE_EXPENSE_SUCCESS: type('[app] [FINANCE] Update expense SUCCESS'),

  DELETE_EXPENSE: type('[app] [FINANCE] Delete expense'),
  DELETE_EXPENSE_FAILED: type('[app] [FINANCE] Delete expense FAILED'),
  DELETE_EXPENSE_SUCCESS: type('[app] [FINANCE] Delete expense SUCCESS'),

  LOAD_RECURRING_OPTIONS: type('[app] [FINANCE] Load Recurring Options'),
  LOAD_RECURRING_OPTIONS_FAILED: type('[app] [FINANCE] Load Recurring Options FAILED'),
  LOAD_RECURRING_OPTIONS_SUCCESS: type('[app] [FINANCE] Load Recurring Options SUCCESS'),


  SET_SELECTED_ITEM: type('[app] [FINANCE] Set selected item'),
  SET_SELECTED_EXPENSE: type('[app] [FINANCE] Set selected expense'),

  LOAD_ITEM_BY_ID: type('[app] [FINANCE] Load item by ID'),
  LOAD_ITEM_BY_ID_FAILED: type('[app] [FINANCE] Load item by ID FAILED'),
  LOAD_ITEM_BY_ID_SUCCESS: type('[app] [FINANCE] Load item by ID SUCCESS'),


  LOAD_CATEGORIES: type('[app] [FINANCE] Load categories'),
  LOAD_CATEGORIES_FAILED: type('[app] [FINANCE] Load categories FAILED'),
  LOAD_CATEGORIES_SUCCESS: type('[app] [FINANCE] Load categories SUCCESS'),

};


export class LoadItemTypesAction implements Action {
  type = FinanceActionTypes.LOAD_ITEM_TYPES;

  constructor() {
  }
}

export class LoadItemTypesFailedAction implements Action {
  type = FinanceActionTypes.LOAD_ITEM_TYPES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadItemTypesSuccessAction implements Action {
  type = FinanceActionTypes.LOAD_ITEM_TYPES_SUCCESS;

  constructor(public payload: ItemType[]) {
  }
}


export class AddNewExpenseAction implements Action {
  type = FinanceActionTypes.ADD_NEW_EXPENSE;

  constructor(public payload: FinanceRequest) {
  }
}

export class AddNewExpenseFailedAction implements Action {
  type = FinanceActionTypes.ADD_NEW_EXPENSE_FAILED;

  constructor(public payload: any) {
  }
}

export class AddNewExpenseSuccessAction implements Action {
  type = FinanceActionTypes.ADD_NEW_EXPENSE_SUCCESS;

  constructor(public payload: any) {
  }
}


export class UpdateExpenseAction implements Action {
  type = FinanceActionTypes.UPDATE_EXPENSE;

  constructor(public payload: FinanceRequest) {
  }
}

export class UpdateExpenseFailedAction implements Action {
  type = FinanceActionTypes.UPDATE_EXPENSE_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateExpenseSuccessAction implements Action {
  type = FinanceActionTypes.UPDATE_EXPENSE_SUCCESS;

  constructor(public payload: any) {
  }
}


export class LoadAllExpenseAction implements Action {
  type = FinanceActionTypes.LOAD_ALL_EXPENSE;

  constructor(public payload: any) {
  }
}

export class LoadAllExpenseFailedAction implements Action {
  type = FinanceActionTypes.LOAD_ALL_EXPENSE_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllExpenseSuccessAction implements Action {
  type = FinanceActionTypes.LOAD_ALL_EXPENSE_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class LoadExpenseByIDAction implements Action {
  type = FinanceActionTypes.LOAD_ALL_EXPENSE_BY_ID;

  constructor(public payload: number | string) {
  }
}


export class AddNewItemAction implements Action {
  type = FinanceActionTypes.ADD_NEW_ITEM;

  constructor(public payload: FinanceRequest) {
  }
}

export class AddNewItemFailedAction implements Action {
  type = FinanceActionTypes.ADD_NEW_ITEM_FAILED;

  constructor(public payload: any) {
  }
}

export class AddNewItemSuccessAction implements Action {
  type = FinanceActionTypes.ADD_NEW_ITEM_SUCCESS;

  constructor(public payload: ItemEntity) {
  }
}


export class LoadAllItemAction implements Action {
  type = FinanceActionTypes.LOAD_ALL_ITEM;

  constructor(public payload: any) {
  }
}

export class LoadAllItemFailedAction implements Action {
  type = FinanceActionTypes.LOAD_ALL_ITEM_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllItemSuccessAction implements Action {
  type = FinanceActionTypes.LOAD_ALL_ITEM_SUCCESS;

  constructor(public payload: ItemEntity[]) {
  }
}


export class UpdateItemAction implements Action {
  type = FinanceActionTypes.UPDATE_ITEM;

  constructor(public payload: FinanceRequest) {
  }
}

export class UpdateItemFailedAction implements Action {
  type = FinanceActionTypes.UPDATE_ITEM_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateItemSuccessAction implements Action {
  type = FinanceActionTypes.UPDATE_ITEM_SUCCESS;

  constructor(public payload: ItemEntity) {
  }
}

export class DeleteItemAction implements Action {
  type = FinanceActionTypes.DELETE_ITEM;

  constructor(public payload: number) {
  }
}

export class DeleteItemFailedAction implements Action {
  type = FinanceActionTypes.DELETE_ITEM_FAILED;

  constructor(public payload: any) {
  }
}

export class DeleteItemSuccessAction implements Action {
  type = FinanceActionTypes.DELETE_ITEM_SUCCESS;

  constructor(public payload: any) {
  }
}


export class LoadRecurringOptionsAction implements Action {
  type = FinanceActionTypes.LOAD_RECURRING_OPTIONS;

  constructor() {
  }
}

export class LoadRecurringOptionsFailedAction implements Action {
  type = FinanceActionTypes.LOAD_RECURRING_OPTIONS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadRecurringOptionsSuccessAction implements Action {
  type = FinanceActionTypes.LOAD_RECURRING_OPTIONS_SUCCESS;

  constructor(public payload: any[]) {
  }
}


export class DeleteExpenseAction implements Action {
  type = FinanceActionTypes.DELETE_EXPENSE;

  constructor(public payload: number) {
  }
}

export class DeleteExpenseFailedAction implements Action {
  type = FinanceActionTypes.DELETE_EXPENSE_FAILED;

  constructor(public payload: any) {
  }
}

export class DeleteExpenseSuccessAction implements Action {
  type = FinanceActionTypes.DELETE_EXPENSE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SetSelectedItem implements Action {
  type = FinanceActionTypes.SET_SELECTED_ITEM;

  constructor(public payload: number) {
  }
}


export class SetSelectedExpenseAction implements Action {
  type = FinanceActionTypes.SET_SELECTED_EXPENSE;

  constructor(public payload: number) {
  }
}

export class LoadItemByIdAction implements Action {
  type = FinanceActionTypes.LOAD_ITEM_BY_ID;

  constructor(public payload: string | number) {
  }
}

export class LoadItemByIdSuccessAction implements Action {
  type = FinanceActionTypes.LOAD_ITEM_BY_ID_SUCCESS;

  constructor(public payload: ItemEntity) {
  }
}

export class LoadItemByIdFailedAction implements Action {
  type = FinanceActionTypes.LOAD_ITEM_BY_ID_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadCategoriesAction implements Action {
  type = FinanceActionTypes.LOAD_CATEGORIES;

  constructor() {}
}

export class LoadCategoriesSuccessAction implements Action {
  type = FinanceActionTypes.LOAD_CATEGORIES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadCategoriesFailedAction implements Action {
  type = FinanceActionTypes.LOAD_CATEGORIES_FAILED;

  constructor(public payload: FinanceCategory[]) {}
}


export declare type FinanceActions =
  LoadItemTypesAction
  | LoadItemTypesFailedAction
  | LoadItemTypesSuccessAction
  | LoadAllExpenseAction
  | LoadAllExpenseFailedAction
  | LoadAllExpenseSuccessAction
  | LoadAllItemAction
  | LoadAllItemFailedAction
  | LoadAllItemSuccessAction
  | AddNewExpenseAction
  | AddNewExpenseFailedAction
  | AddNewExpenseSuccessAction
  | AddNewItemAction
  | AddNewItemFailedAction
  | AddNewItemSuccessAction
  | UpdateExpenseAction
  | UpdateExpenseSuccessAction
  | UpdateExpenseFailedAction
  | UpdateItemAction
  | UpdateItemFailedAction
  | UpdateItemSuccessAction
  | DeleteItemAction
  | DeleteItemFailedAction
  | DeleteItemSuccessAction
  | LoadRecurringOptionsAction
  | LoadRecurringOptionsSuccessAction
  | LoadRecurringOptionsFailedAction
  | DeleteExpenseAction
  | DeleteExpenseFailedAction
  | DeleteExpenseSuccessAction
  | SetSelectedItem
  | LoadItemByIdAction
  | LoadItemByIdSuccessAction
  | LoadItemByIdFailedAction
  | LoadExpenseByIDAction
  | SetSelectedExpenseAction
  | LoadCategoriesAction
  | LoadCategoriesSuccessAction
  | LoadCategoriesFailedAction
  ;
