import { Injectable } from '@angular/core';
import { CurrentState } from "common-lib";
import { PortalAppState } from '../store/reducer';

export const LIVESTOCK_FIRST_USE_KEY_NO_SUBSCRIPTION = 'livestock_without_subscription';
export const LIVESTOCK_FIRST_USE_KEY_WITH_SUBSCRIPTION = 'livestock_with_subscription';
export const RAINFALL_FIRST_USE_KEY = 'rainfall';
export const TASKS_FIRST_USE_KEY = 'tasks';
export const PARCEL_LAYER_FIRST_USE_KEY = 'parcel_layer';
export const LRP_FIRST_USE_KEY = "lrp_first_use_key";

@Injectable()
export class FirstUseService {

  constructor(
    private currentState: CurrentState<PortalAppState>,
  ) {
  }

  isFirstUse(key: string) {
    // console.log('FirstUseService.isFirstUse called with: ', key);
    let result = localStorage.getItem(key);

    if (result) {
      if (this.currentState.snapshot.dev.mockFirstUsage) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  setNotFirstUse(pageUrl: string) {
    // console.log('FirstUseService.setNotFirstUse called with: ', pageUrl);
    localStorage.setItem(pageUrl, 'false');
  }

}
