import { OfflineMapActionTypes, OfflineMapActions } from './offline-maps.actions';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from '../../types';
import { OfflineMap } from '../../../model';


export interface OfflineMapsState {
    offlineMaps: OfflineMap[];
    successAction: Action;
}

const offlineMapsInitialState: OfflineMapsState = {
    offlineMaps: [],
    successAction: null
};

export function offlineMapsReducer(state = offlineMapsInitialState, action: OfflineMapActions & ActionWithPayload<any>): OfflineMapsState {
    switch (action.type) {
        case OfflineMapActionTypes.LOAD_USER_OFFLINE_MAPS_SUCCESS: {
            if (action.payload && Array.isArray(action.payload)) {
                return { ...state, offlineMaps: action.payload, successAction: action };
            }
            break;
        }
        case OfflineMapActionTypes.ADD_OFFLINE_MAP_SUCCESS:
            return { ...state, offlineMaps: [...state.offlineMaps, action.payload.offlineMap], successAction: action  };
        case OfflineMapActionTypes.DELETE_OFFLINE_MAP_SUCCESS: {
            const removedOfflineMapId = (action as ActionWithPayload<any>).payload;
            const offlineMaps = state.offlineMaps.filter(map => map.id != removedOfflineMapId);
            return { ...state, offlineMaps, successAction: action };
        }
        default: {
            return state;
        }
    }
}
