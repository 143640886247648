import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  LoadAllCommoditiesFailedAction,
  LoadAllCommoditiesSuccessAction,
  LoadAllCommodityTypesFailedAction,
  LoadAllCommodityTypesSuccessAction,
  LoadAllCountiesFailedAction,
  LoadAllCountiesSuccessAction,
  LoadAllEndorsementLengthsFailedAction,
  LoadAllEndorsementLengthsSuccessAction,
  LoadAllStatesFailedAction,
  LoadAllStatesSuccessAction,
  LoadAllWatchlistItemsFailedAction,
  LoadEndorsementRequestsAction,
  LoadEndorsementRequestsFailedAction,
  LoadLRPPoliciesAction, LoadLRPPoliciesFailedAction,
  LoadLRPProducersForNameAction,
  LRPActionTypes,
  RunQuotesAction,
  RunQuotesFailAction,
  SetAllLRPUsersAction,
  SetEndorsementRequestsAction,
  SetLatestEffectiveDateAction,
  SetLRPPoliciesAction,
  SetLRPProducersAction,
  SetQuoteEndorsementsAction,
  SetWatchListEndorsementsAction,
  SetWatchListEndorsementsFailedAction,
  SetWatchListItemsAction,
} from '.';
import { ENVIRONMENT_TOKEN } from "../../../environment/token-variables";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { CurrentState } from '../current-state';
import { CommonState } from '../reducer';
import { LRPService } from "../../services/lrp.service";
import { LoadPRFPRoducersFailAction, LoadPRFPRoducersSuccessAction } from "../prf";
import { LRPRequest, Producer } from "../../model";
import { ActionEffectFailedAction } from "../errors";


@Injectable()
export class LrpEffects extends CommonFeBaseEffects {

  @Effect()
  loadAllCommodities: Observable<Action>;

  @Effect()
  loadAllEndorsementLengths: Observable<Action>;

  @Effect()
  loadAllCommodityTypes: Observable<Action>;

  @Effect()
  loadAllStates: Observable<Action>;

  @Effect()
  loadAllCounties: Observable<Action>;

  @Effect()
  searchUsers: Observable<Action>

  @Effect()
  runQuotes: Observable<Action>

  @Effect()
  loadLatestEffectiveDate: Observable<Action>


  @Effect()
  loadWachtlistQuoteItemsOfUser: Observable<Action>

  @Effect()
  loadWachtlistQuoteEndorsements: Observable<Action>

  @Effect()
  loadLRPUsers: Observable<Action>

  @Effect()
  loadLRPProducers: Observable<Action>

  @Effect()
  loadEndorsementRequests: Observable<Action>

  constructor(
    private actions: Actions,
    protected currentState: CurrentState<CommonState>,
    private lrpService: LRPService,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment,
  ) {
    super();


    this.loadAllCommodities = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_COMMODITIES),
      switchMap(() => {
        return this.lrpService.loadAllCommodities().pipe(
          map(response => {
            return new LoadAllCommoditiesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAllCommoditiesFailedAction(error));
          })
        );
      })
    );

    this.loadAllEndorsementLengths = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_ENDORSEMENT_LENGTHS),
      switchMap(() => {
        return this.lrpService.loadAllEndorsementLengths().pipe(
          map(response => {
            return new LoadAllEndorsementLengthsSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAllEndorsementLengthsFailedAction(error));
          })
        );
      })
    );
    this.loadAllCommodityTypes = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_COMMODITY_TYPES),
      switchMap(() => {
        return this.lrpService.loadAllCommodityTypes().pipe(
          map(response => {
            return new LoadAllCommodityTypesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAllCommodityTypesFailedAction(error));
          })
        );
      })
    );
    this.loadAllStates = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_STATES),
      switchMap(() => {
        return this.lrpService.loadAllStates().pipe(
          map(response => {
            return new LoadAllStatesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAllStatesFailedAction(error));
          })
        );
      })
    );
    this.loadAllCounties = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_COUNTIES),
      switchMap(() => {
        return this.lrpService.loadAllCounties().pipe(
          map(response => {
            return new LoadAllCountiesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAllCountiesFailedAction(error));
          })
        );
      })
    );
    this.loadLatestEffectiveDate = this.actions.pipe(
      ofType(LRPActionTypes.UPDATE_LATEST_EFFECTIVE_DATE),
      switchMap(() => {
        return this.lrpService.loadLatestEffectiveDate().pipe(
          map(response => {
            return new SetLatestEffectiveDateAction(response.latestEffectiveDate);
          }),
          catchError(error => {
            return of(new LoadAllCountiesFailedAction(error));
          })
        );
      })
    );
    this.loadWachtlistQuoteItemsOfUser = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_WATCHLIST_ITEMS),
      switchMap(() => {
        const userId = this.currentState.snapshot.user.id;
        return this.lrpService.loadWatchlistQuotes(userId).pipe(
          map(response => {
            return new SetWatchListItemsAction(response);
          }),
          catchError(error => {
            return of(new LoadAllWatchlistItemsFailedAction(error));
          })
        );
      })
    );
    this.loadWachtlistQuoteEndorsements = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_WATCHLIST_ITEMS),
      switchMap(() => {
        const userId = this.currentState.snapshot.user.id;
        return this.lrpService.loadWatchlistEndorsements(userId).pipe(
          map(response => {
            return new SetWatchListEndorsementsAction(response);
          }),
          catchError(error => {
            return of(new SetWatchListEndorsementsFailedAction(error));
          })
        );
      })
    );

    this.loadLRPUsers = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ALL_LRP_USERS),
      switchMap(() => {
        return this.lrpService.loadAllLRPUsers().pipe(
          map(response => {
            return new SetAllLRPUsersAction({users: response});
          }),
          catchError(error => {
            return of(new SetWatchListEndorsementsFailedAction(error));
          })
        );
      })
    );
    this.loadLRPProducers = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_LRP_POLICIES),
      map((action: LoadLRPPoliciesAction) => action.payload),
      switchMap((payload) => {
        return this.lrpService.loadLRPPolicies(payload).pipe(
          map(response => {
            return new SetLRPPoliciesAction({policies: response});
          }),
          catchError(error => {
            return of(new LoadLRPPoliciesFailedAction(error));
          })
        );
      })
    );

    this.loadEndorsementRequests = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_ENDORSEMENT_REQUESTS),
      map((action: LoadEndorsementRequestsAction) => action.payload),
      switchMap((payload) => {
        const entityId = !!payload && payload.entityId ? payload.entityId : -1;
        return this.lrpService.loadEndorsementRequests(entityId, payload.startDate, payload.endDate).pipe(
          map(response => {
            return new SetEndorsementRequestsAction({endorsementRequests: response});
          }),
          catchError(error => {
            return of(new LoadEndorsementRequestsFailedAction(error));
          })
        );
      })
    );

    this.runQuotes = this.actions.pipe(
      ofType(LRPActionTypes.RUN_QUOTE),
      map((action: RunQuotesAction) => action.payload),
      switchMap((request: LRPRequest) => {
        return this.lrpService.runQuote(request).pipe(
          map((response: any) => {
            return new SetQuoteEndorsementsAction(response);
          }),
          catchError(error => {
            return of(new RunQuotesFailAction({error, request}));
          })
        );
      })
    );
    this.searchUsers = this.actions.pipe(
      ofType(LRPActionTypes.LOAD_LRP_PRODUCERS_FOR_NAME),
      map((action: LoadLRPProducersForNameAction) => action.payload),
      switchMap(request => {
        return this.getLRPProducers(request)
          .pipe(map((prfProducers: Producer[]) => {
              let mapped = prfProducers.map(p => {
                const taxId = p.taxId;
                const taxIdLength = taxId.length;
                let lastDigitsOfTaxId = taxId.substring(taxIdLength - 4, taxIdLength);
                const nrOfX = taxIdLength - lastDigitsOfTaxId.length;
                let maskedTaxId = "";
                for (let i = 0; i < nrOfX; i++) {
                  maskedTaxId += ("x");
                }
                maskedTaxId += (lastDigitsOfTaxId);
                return {...p, taxId: maskedTaxId}
              })
              return new SetLRPProducersAction(mapped);
            })
            , catchError(err => {
              return new Observable<Action>((observer) => {
                observer.next(new LoadPRFPRoducersFailAction(err));
                observer.complete()
              });
            }));
      }));
  }

  private getLRPProducers(request: {
    company: 'base',
    primaryName: string
  }) {
    return this.lrpService.getLRPProducers(request);
  }
}
