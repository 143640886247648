/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */
import { Action } from "@ngrx/store";

const types: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
  if (types[<string>label]) {
    throw new Error(`Action type "${label}" is not unqiue"`);
  }

  types[<string>label] = true;

  return <T>label;
}

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}
