import { EmailRequest, EmailType } from '../model';
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from '../../environment/token-variables';
import { Injectable, Inject } from '@angular/core';
import { CurrentState } from '../store/current-state';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonConstants } from '../app.constants';
import { CommonState } from '../store/reducer';

@Injectable()
export class EmailService {

  constructor(private currentState: CurrentState<CommonState>, protected http: HttpClient,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              @Inject(GOOGLE_ANALYTICS_TOKEN) protected ga
  ) {
  }

  public sendEmail(request: EmailRequest) {
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', token);
    headers = headers.append('Auth-server', this.environment.auth_server);

    const options = {
      headers: headers,
      params: request.params
    };
    this.ga.trackServiceCall(this.getEmailStatisticsLabel(request.type), CommonConstants.GA.LABEL.PRF);
    return this.requestEmail(request, options);
  }

  private requestEmail(request: EmailRequest, headers) {
    const req = {...request, ...request.data};
    const company = req.company;
    delete req.type;
    delete req.data;
    switch (request.type) {
      case 'PolicySummary':
        return this.http.post(this.environment.prf_backend + `/private/prf/${company}/premiums/summary/email/`, req, headers);
      case 'RainfallSummary':
        return this.http.post(this.environment.prf_backend + `/private/prf/${company}/rainfall/summary/email/`, req, headers);
      case 'LRPApplication':
        return this.http.post(this.environment.prf_backend + `/private/lrp/${company}/application/email/`, req, headers);
      case 'AssetsInvoice':
        return this.http.get(this.environment.backend + `/private/admin/assetmonitor/user/${request.data.userId}/invoice`,
          headers);
    }
    throw new Error('Unknown email type');
  }

  private getMethod(request: EmailRequest) {
    switch (request.type) {
      case 'PolicySummary':
      case 'RainfallSummary':
        return 'post';
      case 'AssetsInvoice':
        return 'get';
    }
    throw new Error('Unknown email type');
  }


  private getEmailStatisticsLabel(type: EmailType) {
    switch (type) {
      case 'PolicySummary':
        return 'sendSummaryEmail';
      case 'RainfallSummary':
        return 'sendRainfallSummaryEmail';
      case 'AssetsInvoice':
        return 'assetsInvoice';
      case 'LRPApplication':
        return 'lrpApplication';
    }
    throw new Error('Unknown email type');
  }
}
