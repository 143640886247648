import { Injectable, NgZone } from "@angular/core";
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { HttpClient } from "@angular/common/http";
import {
  LRPFinalizedEndorsementRequest, LRPPolicy, LRPPolicyRequest,
  LRPQuoteEndorsement,
  LRPWatchListEndorsement,
  LRPWatchListQuote,
  UserDetail
} from "../model";
import { County, LRPCommodity, LRPCommodityType, LRPEndorsementLength, LRPRequest, State } from "../model/LRPModel";
import { ServicesPRFCommon } from "./services-prf-common.service";
import { Observable } from "rxjs";
import { Action } from "@ngrx/store";

@Injectable()
export class LRPService {
  private readonly LRP_URL = `/private/lrp/base/`;

  private readonly TARGET_WEIGHT_2_STEER = "810";
  private readonly TARGET_WEIGHT_2_HEIFER = "812";
  private readonly TARGET_WEIGHT_2_BRAHMAN = "814";
  private readonly TARGET_WEIGHT_2_DAIRY = "816";


  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesPRFCommon,
  ) {
  }

  public loadAllCommodities(): Observable<LRPCommodity[]> {
    return this.servicesCommon.getAll<LRPCommodity>(this.LRP_URL + 'commodities')
  }

  public loadAllEndorsementLengths(): Observable<LRPEndorsementLength[]> {
    return this.servicesCommon.getAll<LRPEndorsementLength>(this.LRP_URL + 'endorsements/lengths')
  }

  public loadAllCommodityTypes(): Observable<LRPCommodityType[]> {
    return this.servicesCommon.getAll<LRPCommodityType>(this.LRP_URL + 'commodities/types')
  }

  public loadAllStates(): Observable<State[]> {
    return this.servicesCommon.getAll<State>(this.LRP_URL + 'states')
  }

  getLRPProducers(request: { company: "base"; primaryName: string }) {
    return this.servicesCommon.getAll<any>(`/private/lrp/${request.company}/producers/${request.primaryName}`);
  }

  loadAllCounties() {
    return this.servicesCommon.getAll<County>(this.LRP_URL + 'counties')
  }


  loadAllLRPUsers() {
    return this.servicesCommon.getAll<UserDetail>(this.LRP_URL + 'users')
  }

  loadLRPPolicies(request: LRPPolicyRequest): Observable<LRPPolicy[]> {

    let queryParams = '?';

    let ids = this.getCommaSeparatedStringFromArray(request.associationIds);
    if (!!ids) {
      queryParams += 'associationIds=' + ids + '&';
    }

    let commCodes = this.getCommaSeparatedStringFromArray(request.commodityCodes);
    if (!!commCodes) {
      queryParams += 'commodityCodes=' + commCodes + '&';
    }

    let tc = this.getCommaSeparatedStringFromArray(request.typeCodes);
    if (!!tc) {
      queryParams += 'typeCodes=' + tc + '&';
    }

    let reinsuranceYears = this.getCommaSeparatedStringFromArray(request.years);
    if (!!reinsuranceYears) {
      queryParams += 'reinsuranceYears=' + reinsuranceYears + '&';
    }
    return this.servicesCommon.getAll<LRPPolicy>(`/private/lrp/${request.company}/endorsements/policies${queryParams}`);
  }

  private getCommaSeparatedStringFromArray(array: number[] | string[]) {
    if (!array) {
      return null;
    }
    let result = '';
    array.forEach(ai => {
      result += ai + ',';
    })
    if (result.length > 0) {
      result = result.substring(0, result.length - 1);
      return result;
    }
    return null;
  }

  finalizeQuote(request: LRPRequest) {
    let userId = request.userId;
    if (!request.userId) {
      userId = this.currentState.snapshot.user.id;
    }
    let url = this.servicesCommon.getUrl(`/private/lrp/base/endorsements/finalize/user/${userId}`);
    return this.http.post<number>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }

  submitFinalizedEndorsementRequest(request: LRPRequest) {
    let url = this.servicesCommon.getUrl(`/private/lrp/base/endorsements/requests/submit`);
    return this.http.post<number>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }


  loadLatestEffectiveDate() {
    return this.servicesCommon.get<{ latestEffectiveDate: string }>(this.LRP_URL + 'effectivedate/latest')
  }

  runQuote(request: LRPRequest) {
    let endorsementLengthCount = '';
    if (!!request?.endorsementLengthCounts) {
      request?.endorsementLengthCounts.forEach(l => {
        endorsementLengthCount += l + ',';
      })
      if (endorsementLengthCount.length > 0) {
        endorsementLengthCount = endorsementLengthCount.substring(0, endorsementLengthCount.length - 1);
      }
    }
    let weight2 = [this.TARGET_WEIGHT_2_STEER, this.TARGET_WEIGHT_2_HEIFER, this.TARGET_WEIGHT_2_BRAHMAN, this.TARGET_WEIGHT_2_DAIRY];
    let targetWeight = 'targetWeight=';
    let commodityTypes = '';
    if (!!request?.typeCodes) {
      request?.typeCodes.forEach(l => {
        commodityTypes += l + ',';

        if (weight2.includes(l) && !!request?.targetWeight2) {
          targetWeight += l + '_' + request.targetWeight2 + ',';
        } else if ((!weight2.includes(l) && !!request?.targetWeight1) || (request?.typeCodes.length == 1)) {
          targetWeight += l + '_' + request.targetWeight1 + ',';
        }

      })
      if (commodityTypes.length > 0) {
        commodityTypes = commodityTypes.substring(0, commodityTypes.length - 1);
      }
    }
    if (!request?.targetWeight1 && !request?.targetWeight2) {
      targetWeight += -1;
    }
    let effDate = 'effectiveDate=' + (!!request.effectiveDate ? request.effectiveDate : '');
    let stateFipsCode = 'stateFipsCode=' + (!!request?.stateFipsCode ? request?.stateFipsCode : '');
    let countyFipsCode = 'countyFipsCode=' + (!!request?.countyFipsCode ? request?.countyFipsCode : '');
    let commodityCode = 'commodityCode=' + (!!request?.commodityCode ? request?.commodityCode : '');
    let typeCode = 'typeCode=' + commodityTypes;
    let numberOfHead = 'numberOfHead=' + (!!request?.numberOfHead ? request?.numberOfHead : -1);
    let length = 'length=' + endorsementLengthCount;
    let insuredShare = 'insuredShare=' + (!!request?.insuredShare ? request?.insuredShare : -1);

    let queryParams = '?'
      + effDate
      + '&' + stateFipsCode
      + '&' + countyFipsCode
      + '&' + commodityCode
      + '&' + typeCode
      + '&' + numberOfHead
      + '&' + targetWeight
      + '&' + length
      + '&' + insuredShare
    return this.servicesCommon.getAll<any>(this.LRP_URL + 'endorsements' + queryParams);

  }


  createNewWatchList(request: LRPRequest) {
    let userId = request.userId;
    if (!request.userId) {
      userId = this.currentState.snapshot.user.id;
    }
    let url = this.servicesCommon.getUrl(`/private/lrp/base/watchlist/user/${userId}`);
    return this.http.post<LRPWatchListQuote>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }

  deleteWatchList(id: number) {
    let url = this.servicesCommon.getUrl(`/private/lrp/base/watchlist/${id}`);
    return this.http.delete<LRPWatchListQuote>(url, {headers: this.servicesCommon.getAuthHeaders()});
  }

  updateWatchList(request: LRPRequest) {
    let url = this.servicesCommon.getUrl(`/private/lrp/base/watchlist`);
    return this.http.put<LRPWatchListQuote>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }

  loadWatchlistEndorsements(userId: number) {
    return this.servicesCommon.getAll<{
      watchListItem: number,
      endorsements: Map<number, LRPWatchListEndorsement[]>
    }>(`/private/lrp/base/watchlist/endorsements/user/${userId}`);
  }

  loadWatchlistQuotes(userId: number) {
    return this.servicesCommon.getAll<LRPWatchListQuote>(`/private/lrp/base/watchlist/user/${userId}`);
  }

  updateDailyReportSubscription(request: LRPRequest[]) {
    let url = this.servicesCommon.getUrl(`/private/lrp/base/reports/daily/subscribe`);
    return this.http.post<UserDetail[]>(url, request, {headers: this.servicesCommon.getAuthHeaders()});
  }

  loadEndorsementRequests(entityId: number, startDate: string, endDate: string) {
    return this.servicesCommon.getAll<LRPFinalizedEndorsementRequest>(`/private/lrp/base/endorsements/requests/associations/${entityId}?startDate=${startDate}&endDate=${endDate}`);
  }

  getType(t) {
    let type = '';
    // type += t.abbrTitle;
    // type += ': ';
    switch (t.minWeight) {
      case 100:
        type += '1.00 – 5.99 cwt';
        break;
      case 600:
        type += '6.00 – 10.00 cwt';
        break;
      case 140:
        type += '1.40 – 2.60 cwt';
        break;
      case 1000:
        type += '10.00 – 16.00 cwt';
        break;
    }
    return type;
  }
}
