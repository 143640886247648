import WKT from 'ol/format/WKT';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import LineString from 'ol/geom/LineString';
import { Injectable } from '@angular/core';
import { Boundary } from '../../model';
import SimpleGeometry from 'ol/geom/SimpleGeometry';


@Injectable({
  providedIn: 'root'
})
export class WKTGeometryConverter {

  public featureToWKT(f: any) {
    const wkt = new WKT();
    const wktString = wkt.writeFeature(f)
    return wktString;
  }

  removePolygonZCoord(geometry: SimpleGeometry | any[]) {
    let coords = [];
    if (Array.isArray(geometry)) {
      coords = geometry.map(ring => ring.map(coord => {
        return [coord[0], coord[1]]
      }));
      return coords;
    }
    coords = geometry.getCoordinates().map(ring => ring.map(coord => [coord[0], coord[1]]));
    return coords;
  }

  lineStringToWKT(coordinates: any[]): string {
    const wkt = new WKT();
    try {
      return wkt.writeGeometry(new LineString(coordinates));
    } catch (err) {
      console.warn(err);
      return null;
    }
  }


  polygonToWKT(geometry: SimpleGeometry): string {
    const wkt = new WKT();
    try {
      return wkt.writeGeometry(new Polygon(this.removePolygonZCoord(geometry)));
    } catch (err) {
      console.warn(err);
      return null;
    }
  }

  toGeometry(wktString: string, typeCheck?: string): Geometry {
    const wkt = new WKT();
    try {
      const geom = wkt.readGeometry(wktString);
      return typeCheck ? (geom.getType() === typeCheck ? geom : null) : geom;
    } catch (err) {
      console.warn(err);
      return null;
    }
  }


  getGeometry(wktString: string) {
    const wkt = new WKT();
    try {
      return wkt.readGeometry(wktString);
    } catch (err) {
      console.warn(err);
      return null;
    }
  }


  polygonCoords2WKT(coords, type = 'Polygon') {
    const wkt = new WKT();
    let geometry = null;
    const coordsXY = this.removePolygonZCoord(coords);
    if (type.toUpperCase() === 'POLYGON') {
      geometry = new Polygon(coordsXY);
    } else if (type.toUpperCase() === 'MULTIPOLYGON') {
      geometry = new MultiPolygon(coordsXY);
    }
    try {
      const wktString = wkt.writeGeometry(geometry);
      return wktString;
    } catch (err) {
      console.warn(err);
      return null;
    }
  }

  getType(wktString: string) {
    const wkt = new WKT();
    try {
      const geom = wkt.readGeometry(wktString);
      return geom.getType();
    } catch (err) {
      console.warn(err);
      return null;
    }
  }

  filterByGeometry(geometryName: string, geometries: Boundary[]) {
    return this.filterByGeometries([geometryName], geometries);
  }

  filterByGeometries(geometryNames: string[], geometries: Boundary[]) {
    const filtered = geometries.filter(geom => {
      for (let geometryName of geometryNames) {
        const name = geometryName.trim();
        if (geom.geometry.startsWith(name)) {
          return true;
        }
      }
      return false;
    });
    return filtered;
  }
}
