import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { CommonState } from '../store/reducer'
import { CurrentState } from "../store/current-state";
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";
import { TaskEntity, TaskRequest, TaskReportRequest } from "../model";
import { CommonConstants } from "../app.constants";

@Injectable()
export class TasksService {

  readonly path = '/private/tasks/';

  constructor(private currentState: CurrentState<CommonState>,
              @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              protected http: HttpClient,
  ) {

  }

  public addNewTask(taskData: TaskRequest) {
    const path = this.path;
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    let copyTaskData = JSON.parse(JSON.stringify(taskData));
    const url = this.environment.backend + path;
    const options = {
      headers: headers
    };
    this.ga.trackServiceCall('addNewTask', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.post(url, copyTaskData, options);
  }

  public getTasks() {
    // const path = '/private/tasks/list';
    const path = this.path;

    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;
    const filter = this.currentState.snapshot.tasks.filter;
    let pagingObject = {
      pageNumber: this.currentState.snapshot.tasks.allTasks.currentPage,
      pageSize: this.currentState.snapshot.tasks.allTasks.pageSize
    };
    const params = this.getParamsForRequest(filter, pagingObject);
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', token);

    console.info('getTasks filter: ' + filter);
    const options = {
      headers: headers,
      params: params
    };
    this.ga.trackServiceCall('getTasks', CommonConstants.GA.LABEL.USER_CRUD);
    return this.http.get<TaskEntity[]>(url, options);
  }

  public getUpcomingTasks() {
    const path = `${this.path}upcoming/`;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;
    let headers = new HttpHeaders();
    const pageNumber = this.currentState.snapshot.tasks.upcomingTasks.currentPage;
    const pageSize = this.currentState.snapshot.tasks.upcomingTasks.pageSize;
    let params = new HttpParams();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', token);
    params = params.append('ordering', 'due_date_asc');
    params = params.append('pageNumber', pageNumber + '');
    params = params.append('pageSize', pageSize + '');
    const options = {
      headers: headers,
      params: params
    };
    this.ga.trackServiceCall('getUpcomingTasks', CommonConstants.GA.LABEL.TASKS);
    return this.http.get(url, options);
  }

  public getTaskById(taskId) {
    const path = this.path;
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();

    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', token);
    const url = this.environment.backend + path + taskId;
    this.ga.trackServiceCall('getTaskById', CommonConstants.GA.LABEL.TASKS);
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public deleteAttachment(attachmentId) {
    const path = `${this.path}attachment/`;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + attachmentId;
    this.ga.trackServiceCall('deleteAttachment', CommonConstants.GA.LABEL.TASKS);
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  getAllCategories() {
    const path = `${this.path}categories/`;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;

    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  getAllActions() {
    const path = `${this.path}actions/`;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;

    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  getAllStatuses() {
    const path = `${this.path}statuses/`;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;

    return this.http.get(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public deleteTask(taskId) {
    const path = this.path;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + taskId;
    this.ga.trackServiceCall('deleteTask', CommonConstants.GA.LABEL.TASKS);
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }

  public updateTask(taskData: TaskRequest) {
    const path = this.path;
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path + taskData.id;
    const options = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };
    let copyTaskData = {...taskData};
    this.ga.trackServiceCall('udpateTask', CommonConstants.GA.LABEL.TASKS);
    return this.http.put(url, copyTaskData, options);
  }

  public createTaskRequest(task: TaskEntity,
                           selectedCategory,
                           selectedAction,
                           selectedDueDate,
                           selectedStatus,
                           selectedAssignees,
                           selectedLocationId,
                           selectedRanchId,
                           actionDetailValues = null): TaskRequest {
    let request = new TaskRequest();
    request.id = task.id;
    if (task.assignees) {
      request.assigneeIds = task.assignees.map(assignee => {
        return (!!assignee && !!assignee.id) ? assignee.id : null;
      }) as any;
    }
    if (selectedCategory != null) {
      request.categoryIds = selectedCategory;
    } else {
      const categIds = task.categories.map(categ => {
        return categ.id;
      });
      request.categoryIds = categIds;
    }
    if (selectedAction != null) {
      request.actionIds = selectedAction;
    } else {
      const aIds = task.actions.map(action => {
        return action.id;
      });
      request.actionIds = aIds;
    }
    if (selectedAssignees != null) {
      request.assigneeIds = selectedAssignees as any;
    } else {
      const assigneeIds = task.assignees.map(assignee => {
        return assignee.id;
      });
      request.assigneeIds = assigneeIds;
    }

    if (task.createdBy) {
      request.createdBy = +task.createdBy.id;
    }
    // request.dueDate = task.dueDate;
    if (selectedDueDate) {
      request.dueDate = selectedDueDate;
    } else {
      request.dueDate = task.dueDate;
    }
    request.locationLat = task.locationLat;
    request.locationLng = task.locationLng;
    request.notes = task.notes;
    // request.statusId = task.status.id;
    if (selectedStatus) {
      request.statusId = selectedStatus as any;
    } else {
      request.statusId = task.status.id;
    }
    request.title = task.title;
    request.urgent = task.urgent;
    if (task.attachments) {
      request.attachmentIds = task.attachments.filter(att => {
        return !!att;
      }).map(att => {
        return att.id;
      }) as any;
    }
    request.boundaryId = selectedLocationId ? selectedLocationId : task.boundaryId;
    request.lotId = task.lotId;
    request.animalIds = task.animalIds;
    request.ranchId = selectedRanchId;

    if (!!actionDetailValues) {
      request.actionDetails = actionDetailValues;
    }

    if (task.itemId) {
      request.itemId = task.itemId;
    }

    return request;
  }

  private getParamsForRequest(filter: {
    categories: any[],
    assignees: any[],
    statuses: any[],
    locations: any[],
    ranches: any[],
    ordering: any
  }, pagingObject) {

    let paramsObject = {
      categories: !!filter.categories && filter.categories.length > 0 ? filter.categories : null,
      statuses: !!filter.statuses && filter.statuses.length > 0 ? filter.statuses : null,
      pageNumber: !!pagingObject && !!pagingObject.pageNumber ? pagingObject.pageNumber : null,
      pageSize: !!pagingObject && !!pagingObject.pageSize ? pagingObject.pageSize : null,
      ordering: filter.ordering,
      assignees: !!filter.assignees && filter.assignees.length > 0 ? filter.assignees : null,
      locations: !!filter.locations && filter.locations.length > 0 ? filter.locations : null,
      ranches: !!filter.ranches?.length ? filter.ranches : null,
    };

    let params = new HttpParams();
    if (paramsObject.pageNumber) {
      params = params.append('pageNumber', paramsObject.pageNumber)
    }
    if (paramsObject.pageSize) {
      params = params.append('pageSize', paramsObject.pageSize)
    }
    if (paramsObject.statuses) {
      let statuses = paramsObject.statuses.map(c => +c);
      let statusString = statuses.join(',');
      params = params.append('statuses', statusString)
    }
    if (paramsObject.categories) {
      let categories = paramsObject.categories.map(c => +c);
      let categoryString = categories.join(',');
      params = params.append('categories', categoryString)
    }
    if (paramsObject.assignees) {
      let assignees = paramsObject.assignees.map(c => +c);
      let assigneesString = assignees.join(',');
      params = params.append('assignees', assigneesString)
    }
    if (paramsObject.locations) {
      let locations = paramsObject.locations.map(c => +c);
      let locationString = locations.join(',');
      params = params.append('locations', locationString)
    }
    if (paramsObject.ranches) {
      let ranches = paramsObject.ranches.map(c => +c);
      let ranchesString = ranches.join(',');
      params = params.append('ranches', ranchesString)
    }
    if (paramsObject.ordering) {
      params = params.append('ordering', paramsObject.ordering.id ? paramsObject.ordering.id : paramsObject.ordering);
    }
    return params;
  }

  public createTaskReport(request: TaskReportRequest) {
    const path = `/private/reports/task/user/${this.currentState.snapshot.user.id}`;
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', token);
    const url = this.environment.backend + path;
    const options = {
      headers: headers
    };
    this.ga.trackServiceCall('createTaskReport', CommonConstants.GA.LABEL.TASKS);
    return this.http.post(url, request, options);
  }
}
