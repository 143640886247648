import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from "@angular/common/http";
import { DevState } from 'common-lib';


export const environment = {
  name: 'Production',
  backend: 'https://pastureproapp.com:20043/pasturePro/api',
  prf_backend: 'https://pastureproapp.com:20043/pastureProPrf/api',
  auth_server: 'https://pastureproapp.com:20043/pasturePro/api',
  parcel_data_server: 'https://map.myaginnovations.com/cgi-bin/mapserv',
  rainfall_map_server: 'https://map.myaginnovations.com/cgi-bin/mapserv',
  production: true,
  showEnvInfo: false,
  analyticsEnabled: true,
  firebaseConfig: {
    apiKey: "AIzaSyAmjn33AwG_4IpLRcA9Ty9vJL0oLVvDvbI",
    authDomain: "pature-portal.firebaseapp.com",
    projectId: "pature-portal",
    storageBucket: "pature-portal.appspot.com",
    messagingSenderId: "1061373518007",
    appId: "1:1061373518007:web:4916f2c775fdf0671dad32",
    measurementId: "G-1PFSKPB2QT"
  }
};

export const devSettings: DevState = {
  autoLogin: false,
  autoLoginUsername: '',
  autoLoginPassword: '',
  mockFavoriteStations: false,
  mockFirstUsage: false,
  showErrorCause: false,
  mockTexasLocation: false,
  forcedParcelTierBuy: false,
  unlimitedParcelUsage: false,
  hasInternetConnection: true,
  withStorageCalculation: false,
  positionTracking: false,
  quickEnableInternet: false,
  showId: false,
  disableHXGNLayer: false
};

// export const devSettings: DevState = {
//   autoLogin: false,
//   autoLoginUsername: '',
//   autoLoginPassword: '',
//   mockFavoriteStations: false,
//   mockFirstUsage: false,
//   showErrorCause: false
// };
export const PACKAGE_NAME = 'com.myaginnovations.pasturepro';
export const GOOGLE_ANALYTICS_KEY = 'UA-133248593-1';
export const ENV_INFO = 'TEST\n' + environment.backend;

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
