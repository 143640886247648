import { Injectable } from '@angular/core';

@Injectable()
  export class FeService {

  constructor() { }

  public sharedMethod() {
    console.log('sharedMethod called!')
  }
}
