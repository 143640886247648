import { HttpErrorResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  AdminService, AssignNewUserToRanchFailed, AssignNewUserToRanchSuccess,
  CreateErrorMessage, CurrentState, ErrorHandler,
  DeleteUserFailAction, LoadCommonUsersForUserAction, LoadRanchUsersAction, RanchService,
  UserRequest,
  CreateUserSuccessAction,
  UserService
} from "common-lib";
import { PortalAppState } from '../../store/reducer';
import { MatDialog } from '@angular/material/dialog';
import { UserFormPage } from '../user/user-form/user-form.page';
import { ExistingUsersSearchPage } from '../user/existing-users-search/existing-users-search.page';

export abstract class RanchBasePage {
  public ranches: Observable<any[] | null | any>;
  public usersOfSelectedRanchObs: Observable<any[] | null | any>;

  constructor(public store: Store<PortalAppState>,
    protected router: Router,
    protected ngZone: NgZone,
    protected ranchService: RanchService,
    protected currentState: CurrentState<PortalAppState>,
    protected translateService: TranslateService,
    protected adminService: AdminService,
    public dialog: MatDialog,
    protected userService: UserService,
  ) {

  }

  public async presentNewRanchAlert(errorMsg = '', successCallback: (data) => void = null) {
    // this.mobileRanchService.presentNewRanchAlert(errorMsg, true, successCallback);
  }

  protected async presentExistingSearchDialog(ranch) {
    console.log('presentNewUserDialog');

    const dialogRef = this.dialog.open(ExistingUsersSearchPage, {
      width: '400px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {

        this.addUserToRanch(result.userId, result.ranchManager, ranch.ranch.id);
      }
    });
  }

  protected async presentNewUserDialog(ranch) {
    const dialogRef = this.dialog.open(UserFormPage, {
      width: '740px',
      autoFocus: false,
      data: {
        isNew: true,
        showPasswordCheckbox: true,
        showRanchManager: true,
        canEdit: true,
        ranchOwner: (ranch.ranch.owner && (ranch.ranch.owner.id == this.currentState.snapshot.user.id)),
        moduleSubscriptions: this.currentState.snapshot.user.userDetails.moduleSubscriptions[ranch.ranch.id],
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          let formValue = result.data;
          const request = <UserRequest>{
            userName: formValue.userName,
            password: formValue.password,
            email: formValue.email,
            phoneNumber: formValue.phoneNumber,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            canSeeProfitloss: formValue.canSeeProfitloss,
            ranchManager: formValue.ranchManager
          };
    
          this.adminService.createNewUser(request).subscribe((response: number) => {
              this.userService.getUserDetails(response).subscribe(user => {
                  console.log(user)
                  this.store.dispatch(new CreateUserSuccessAction(user));
                  this.addUserToRanch(user['id'], formValue.ranchManager, ranch.ranch.id, true);
              },
              error => console.error('error retreiving user data', error));
          });


        }
    });

  }

  protected addUserToRanch(userId: any, ranchManager: boolean, ranch: any, deleteOnError: boolean = false) {
    this.ngZone.run(() => {

      const request = {
        userId: userId,
        ranchManager: ranchManager
      };
      let ranchId = ranch;
      if (ranch && ranch.id) {
        ranchId = ranch.id
      }
      this.ranchService.addUserToRanch([request], ranchId).pipe(catchError(error => {
        return new Observable<Action>((observer) => {
          observer.next(new AssignNewUserToRanchFailed(ErrorHandler.getErrorEntry(error)));
          observer.complete()
        });
      })).subscribe(response => {
        if (response instanceof AssignNewUserToRanchFailed) {
          console.error('new user added to ranch FAILED');
          console.error(response);
          this.handleUserAddedToRanchFailed(deleteOnError, userId, ranchId);
        } else {
          this.handleUserAddedToRanchSuccess(ranch, response, userId);
        }
      });
    });
  }

  protected handleUserAddedToRanchFailed(deleteOnError, userId, ranchId) {
    if (deleteOnError) {
      console.error('should delete user!!!');
      this.deleteUser(userId);
    }
  }

  protected handleUserAddedToRanchSuccess(ranch, response, userId) {
    let ranchId = ranch;
    if (ranch && ranch.id) {
      ranchId = ranch.id;
    }
    this.store.dispatch(new AssignNewUserToRanchSuccess([ranchId]));
    this.store.dispatch(new LoadCommonUsersForUserAction(userId));
    console.log('new user added to ranch');
    console.log(response);
  }

  protected deleteUser(userId) {
    this.ngZone.run(() => {
      this.adminService.deleteUser(userId).pipe(catchError(error => {
        return new Observable<Action>((observer) => {
          observer.next(new DeleteUserFailAction(ErrorHandler.getErrorEntry(error)));
          observer.complete()
        });
      })).subscribe(response => {
        if (response instanceof HttpErrorResponse || response instanceof DeleteUserFailAction) {
          console.error("Error during user delete");
          console.error(response);
          if (response instanceof DeleteUserFailAction) {
            this.store.dispatch(new CreateErrorMessage(response.payload));
          }
        } else {
          this.addNewUserToRanchFailed(userId);
        }
      });
    });
  }

  protected addNewUserToRanchFailed(userId: any) {
    console.log('addNewUserToRanchFailed');
  }


}

