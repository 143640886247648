import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorService {

    readonly Colors = [
        '#1abc9c',
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#f1c40f',
        '#ff7f50',
        '#e74c3c',
        '#bdc3c7'
    ];

    readonly ColorNames = [
        "LightSeaGreen",
        "MediumSeaGreen",
        "SummerSky",
        "DeepLilac",
        "MoonYellow",
        "Coral",
        "Cinnabar",
        "Heather"
    ];

    readonly DeafultBoundaryColor = this.Colors[5];

    readonly DeafultMarkerColor = this.Colors[7];

    getRgb(hexColor: string) {
        hexColor = hexColor.replace('#', '');
        if (hexColor.length === 6) {
            return `${parseInt(hexColor.slice(0, 2), 16)}, ${parseInt(hexColor.slice(2, 4), 16)}, ${parseInt(hexColor.slice(4), 16)}`;
        }
        return '0,0,0';
    }

    hex2RGB(hexColor: string) {
        return `rgb(${this.getRgb(hexColor)})`;
    }

    hex2RGBA(hexColor: string, opacity) {
        return `rgba(${this.getRgb(hexColor)}, ${opacity})`
    }


}