import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { LoginPage } from './pages/login/login.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalEffectsModule } from "./portal-effects.module";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { environment } from '../environments/environment';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { getReducers, metaReducers, REDUCERS } from "./app.reducer";
import {
  CommonFeModule,
  CurrentState,
  ENVIRONMENT_TOKEN,
  GOOGLE_ANALYTICS_TOKEN,
  OFFLINE_CACHE_SERVICE,
} from "common-lib";
import { GoogleAnalyticsPortalService } from "./services/google-analytics-portal.service";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PortalUserService } from "./services/portal-user.service";
import { HomePage } from './pages/home/home.page';
import { DateService } from './services/date.service';
import { PortalCacheService } from './services/portal-cache.service';
import { RouterModule } from '@angular/router';
import { DialogComponent } from './components/dialog/dialog.component';

import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { AttachmentsPageModule } from "./pages/attachments/attachment.module";
import { HttpErrorHandlerInterceptor } from "./services/http-error-handler-interceptor.service";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { FirstUseService } from './services/first_use.service';
import { RanchHomePage, RanchNewUserBottomSheetComponent } from './pages/ranches/ranch-home/ranch-home.page';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { UserFormPage } from './pages/user/user-form/user-form.page';
import { ExistingUsersSearchPage } from './pages/user/existing-users-search/existing-users-search.page';
import { HttpTokenEnrichInterceptor } from "./services/http-token-enrich-interceptor";
import { ContactPage } from './pages/support/contact/contact.page';
import { SideMenuComponent } from './components/side-menu/side-menu.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPage,
    HomePage,
    TopMenuComponent,
    SideMenuComponent,
    DialogComponent,
    UserFormPage,
    RanchHomePage,
    RanchNewUserBottomSheetComponent,
    ExistingUsersSearchPage,
    ContactPage,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PortalEffectsModule,
    CommonFeModule,
    HttpClientModule,
    AttachmentsPageModule,
    StoreModule.forRoot(REDUCERS, {metaReducers}),
    EffectsModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    MatToolbarModule,
    MatMenuModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTooltipModule,
    MatRadioModule,
    MatBottomSheetModule,
  ],
  providers: [
    {provide: ENVIRONMENT_TOKEN, useValue: environment},
    {provide: GOOGLE_ANALYTICS_TOKEN, useClass: GoogleAnalyticsPortalService},
    {provide: OFFLINE_CACHE_SERVICE, useClass: PortalCacheService},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerInterceptor, multi: true},
    HttpClient,
    {provide: REDUCERS, useFactory: getReducers},
    CurrentState,
    PortalUserService,
    PortalCacheService,
    DateService,
    FirstUseService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
