import { ActionWithPayload } from "../types";
import { Action } from "@ngrx/store";
import {
  County,
  LRPCommodity,
  LRPCommodityType,
  LRPEndorsementLength, LRPFinalizedEndorsementRequest, LRPPolicy,
  LRPQuoteEndorsement,
  LRPRequest, LRPWatchListEndorsement, LRPWatchListQuote,
  State
} from "../../model/LRPModel";
import { LRPActionTypes } from "./lrp.actions";
import { UserDetail } from "../../model";
import { UserActionTypes } from "../user";
import { getYears } from "../prf";


export interface LRPState {
  commodities: LRPCommodity[],
  commodityTypes: LRPCommodityType[],
  endorsementLengths: LRPEndorsementLength[],
  states: State[];
  counties: County[];
  lrpProducers: any[];
  quoteEndorsements: Map<string, Map<number, LRPQuoteEndorsement[]>>;
  endorsementRequests: LRPFinalizedEndorsementRequest[];
  request: LRPRequest;
  selectedEndorsement: LRPQuoteEndorsement;
  selectedPolicy: LRPPolicy;
  latestEffectiveDate: string;
  users: UserDetail[];
  policies: LRPPolicy[]
  watchList: {
    watchListItems: LRPWatchListQuote[]
    endorsements: Array<{
      watchListQuteItemId: number,
      endorsements: Map<number, LRPWatchListEndorsement[]>
    }>
  },
  availableYears: string []
}

export const LRPInitialState: LRPState = {
  commodities: [],
  commodityTypes: [],
  endorsementLengths: [],
  endorsementRequests: [],
  states: [],
  counties: [],
  policies: null,
  lrpProducers: null,
  quoteEndorsements: null,
  request: null,
  selectedEndorsement: null,
  selectedPolicy: null,
  latestEffectiveDate: null,
  users: [],
  watchList: {
    watchListItems: null,
    endorsements: null
  },
  availableYears: []
}

export function reducer(state = LRPInitialState, action: Action): LRPState {
  const payload = (action as ActionWithPayload<any>).payload;
  switch (action.type) {
    case LRPActionTypes.LOAD_ALL_COMMODITIES_SUCCESS:
      return {
        ...state,
        commodities: payload
      };
    case LRPActionTypes.LOAD_ALL_COMMODITY_TYPES_SUCCESS:
      return {
        ...state,
        commodityTypes: payload
      };
    case LRPActionTypes.SET_WATCHLIST_ITEMS:
      return {
        ...state,
        watchList: {...state.watchList, watchListItems: payload}
      };
    case LRPActionTypes.SET_WATCHLIST_ENDORSEMENTS:
      return {
        ...state,
        watchList: {...state.watchList, endorsements: payload}
      };
    case LRPActionTypes.LOAD_ALL_ENDORSEMENT_LENGTHS_SUCCESS:
      return {
        ...state,
        endorsementLengths: payload
      };
    case LRPActionTypes.LOAD_ALL_STATES_SUCCESS:
      return {
        ...state,
        states: payload
      };
    case LRPActionTypes.LOAD_ALL_COUNTIES_SUCCESS:
      return {
        ...state,
        counties: payload
      };
    case LRPActionTypes.RESET_LRP_PRODUCERS:
      return {
        ...state,
        lrpProducers: null
      };
    case LRPActionTypes.SET_LRP_PRODUCERS:
      return {
        ...state,
        lrpProducers: payload
      };
    case LRPActionTypes.SET_LRP_POLICIES:
      return {
        ...state,
        policies: payload.policies
      };
    case LRPActionTypes.SET_ENDORSEMENT_QUOTES:
      return {
        ...state,
        quoteEndorsements: payload ? payload.quotes : null,
        request: payload ? payload.request : null,
      };
    case LRPActionTypes.RUN_QUOTE_FAILED:
      return {
        ...state,
        quoteEndorsements: null,
        request: payload ? payload.request : null,
      };
    case LRPActionTypes.CLEAR_ENDORSEMENT_QUOTES:
      return {
        ...state,
        quoteEndorsements: null
      };
    case LRPActionTypes.SET_SELECTED_ENDORSEMENT:
      return {
        ...state,
        selectedEndorsement: payload
      };
    case LRPActionTypes.SET_LATEST_EFFECTIVE_DATE:
      return {
        ...state,
        latestEffectiveDate: payload
      };
    case LRPActionTypes.SET_LRP_USERS:
      return {
        ...state,
        users: payload.users
      };
    case LRPActionTypes.SET_ENDORSEMENT_REQUESTS:
      return {
        ...state,
        endorsementRequests: payload.endorsementRequests
      };
    case LRPActionTypes.SET_SELECTED_LRP_POLICY:
      return {
        ...state,
        selectedPolicy: payload?.policy
      };
    case UserActionTypes.LOAD_USER_SUCCESS:
      const userDetails = (action as ActionWithPayload<any>).payload;
      let yearsOfLRP = state.availableYears;
      if (userDetails.latestLRPYear?.length > 0) {
        const latestLRPYear = userDetails.latestLRPYear;
        yearsOfLRP = getYears(latestLRPYear);
      }
      return {
        ...state,
        availableYears: yearsOfLRP
      };
    default:
      return state;
  }
}
