import { Action } from '@ngrx/store';
import { type } from "../types";
import {
  AFRequest,
  AnnualForageResponse,
  GridDetailsForClaims,
  PremiumAndCoverageResponse,
  PremiumSummary,
  PRFRequest
} from "../../model";


export const PRFActionTypes = {
  LOAD_CLAIMS: type('[app] [PRF] Load claims'),
  LOAD_CLAIMS_FAIL: type('[app] [PRF] Load claims failed'),
  LOAD_CLAIMS_SUCCESS: type('[app] [PRF] Load claims success'),
  LOAD_CLAIMS_FOR_PRODUCER_YEAR: type('[app] [PRF] Load claims for producer and year '),
  LOAD_CLAIMS_FOR_PRODUCER_YEAR_FAIL: type('[app] [PRF] Load claims for producer and year failed'),
  LOAD_CLAIMS_FOR_PRODUCER_YEAR_SUCCESS: type('[app] [PRF] Load claims for producer and year success'),
  LOAD_CLAIMS_FOR_COUNTIES: type('[app] [PRF] Load claims for counties'),
  LOAD_CLAIMS_FOR_COUNTIES_FAIL: type('[app] [PRF] Load claims for counties fail.'),
  LOAD_CLAIMS_FOR_COUNTIES_SUCCESS: type('[app] [PRF] Load claims for counties success.'),

  LOAD_COVERAGE_PREMIUMS: type('[app] [PRF] Load  coverage premium'),
  LOAD_COVERAGE_PREMIUMS_FAIL: type('[app] [PRF] Load coverage premium failed'),
  LOAD_COVERAGE_PREMIUMS_SUCCESS: type('[app] [PRF] Load coverage premium success'),

  // LOAD_CLAIMS_AND_PREMIUMS: type('[app] [PRF] Load coverage premium and claims'),
  // LOAD_CLAIMS_AND_PREMIUMS_SUCCESS: type('[app] [PRF] Load coverage premium and claims success'),
  // LOAD_CLAIMS_AND_PREMIUMS_FAIL: type('[app] [PRF] Load coverage premium and claims failed'),

  GENERIC_PRF_SERVICE_SUCCESS: type('[app] [PRF] Service call success.'),

  LOAD_COVERAGE_PREMIUMS_SUMMARY_SUCCESS: type('[app] [PRF] Load coverage premium summary success'),
  LOAD_PRF_PRODUCERS: type('[app] [PRF] Load PRF producers'),
  LOAD_PRF_PRODUCERS_SUCCESS: type('[app] [PRF] Load PRF producers success'),
  LOAD_PRF_PRODUCERS_FAIL: type('[app] [PRF] Load PRF producers failed'),
  RESET_PRF_PRODUCERS: type('[app] [PRF] Reset PRF producers'),
  // SET_DASHBOARD_SELECTED_TAX_IDS: type('[app] [PRF] Set selected dashboard PRF associations'),
  // SET_DASHBOARD_SELECTED_YEAR: type('[app] [PRF] Set selected dashboard PRF year'),
  // SET_COVERAGE_SELECTED_TAX_IDS: type('[app] [PRF] Set selected coverage PRF associations'),
  // SET_COVERAGE_SELECTED_YEAR: type('[app] [PRF] Set selected coverage PRF year'),
  SET_COVERAGE_SELECTED_COUNTIES: type('[app] [PRF] Set selected coverage PRF counties'),
  SET_COVERAGE_AVAILABLE_COUNTIES: type('[app] [PRF] Set available coverage PRF counties'),
  SET_SELECTED_ASSOCIATION_IDS: type('[app] [PRF] Set selected PRF association Ids'),
  SET_SELECTED_YEAR: type('[app] [PRF] Set selected PRF year'),
  SET_CLAIMS_SELECTED_COUNTIES: type('[app] [PRF] Set selected claims PRF counties'),
  SET_CLAIMS_AVAILABLE_COUNTIES: type('[app] [PRF] Set available claims PRF counties'),
  SET_RAINFALL_SELECTED_INTERVAL: type('[app] [PRF] Set selected rainfall interval'),

  // LOAD_DASHBOARD_PREMIUMS: type('[app] [PRF] Load  dashboard premium'),
  // LOAD_DASHBOARD_PREMIUMS_FAIL: type('[app] [PRF] Load dashboard premium failed'),
  LOAD_DASHBOARD_PREMIUMS_SUCCESS: type('[app] [PRF] Load dashboard premium success'),
  // LOAD_DASHBOARD_CLAIMS: type('[app] [PRF] Load  dashboard claims'),
  // LOAD_DASHBOARD_CLAIMS_SUCCESS: type('[app] [PRF] Load  dashboard claims successed'),
  // LOAD_DASHBOARD_CLAIMS_FAIL: type('[app] [PRF] Load  dashboard claims failed'),
  LOAD_DASHBOARD_PREMIUMS_SUMMARY_SUCCESS: type('[app] [PRF] Load dashboard premium summary success'),
  // LOAD_DASHBOARD_CLAIMS_SUMMARY_FAIL: type('[app] [PRF] Load PRF dashboard claims summary failed'),
  LOAD_DASHBOARD_CLAIMS_SUMMARY_SUCCESS: type('[app] [PRF] Load PRF dashboard claims summary success'),

  LOAD_DASHBOARD_DATA: type('[app] [PRF] Load  dashboard data'),
  LOAD_DASHBOARD_DATA_FAILED: type('[app] [PRF] Load  dashboard data failed'),
  LOAD_DASHBOARD_DATA_SUCCESS: type('[app] [PRF] Load  dashboard data success'),
  SET_NO_POLICY_FOR_YEAR: type('[app] [PRF] Set no policy for year'),

  SET_LATEST_PRF_YEAR: type('[app] [PRF] Set latest policy year'),
  LOAD_ALL_YEARS_DASHBOARD_DATA: type('[app] [PRF] Load all years dashboard data'),
  LOAD_ALL_YEARS_DASHBOARD_DATA_SUCCESS: type('[app] [PRF] Load all years dashboard data success'),
  LOAD_ALL_YEARS_DASHBOARD_DATA_FAILED: type('[app] [PRF] Load all years dashboard data failed'),
  SET_DEAFULT_SELECTED_YEAR: type('[app] [PRF] Set default selected year'),

  SET_LATEST_NOAAA_UPDATE_DATE: type('[app] [PRF] Set latest noaaa update date'),
  DELETE_PREMIUM_CACHE_ENTRY: type('[app] [PRF] Delete premium cache entry'),
  DELETE_CLAIMS_CACHE_ENTRY: type('[app] [PRF] Delete claims cache entry'),
};

export const AnnualForageActionTypes = {
  LOAD_AF_DATA: type('[app] [AF] Load data'),
  LOAD_ALL_YEAR_AF_DATA: type('[app] [AF] Load all year data'),
  AF_LOAD_DATA_SUCCESS: type('[app] [AF] Load data success'),
  AF_LOAD_DATA_FAILED: type('[app] [AF] Load data failed'),

  SET_SELECTED_ASSOCIATION_IDS: type('[app] [AF] Set selected association ids'),
  SET_SELECTED_YEAR: type('[app] [AF] Set selected year'),

  SET_AVAILABLE_GROWING_SEASON: type('[app] [AF] Set available Growing Seasons'),
  SET_SELECTED_GROWING_SEASON: type('[app] [AF] Set selected Growing Season'),
  SET_AVAILABLE_COUNTIES: type('[app] [AF] Set available Counties'),
  SET_SELECTED_COUNTIES: type('[app] [AF] Set selected Counties'),
  SET_AVAILABLE_INTERVALS: type('[app] [AF] Set available Rainfall Intervals'),
  SET_SELECTED_INTERVAL: type('[app] [AF] Set selected Rainfall Interval'),
}

export class LoadClaimsAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS;

  constructor(public payload: PRFRequest) {
  }
}

export class LoadClaimsFailedAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadClaimsSuccessAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_SUCCESS;

  constructor(public payload: { company: 'hargrove' | 'aap', claimsResponse: any }) {
  }
}

// export class LoadClaimsAndPremiumsAction implements Action {
//   type = PRFActionTypes.LOAD_CLAIMS_AND_PREMIUMS;
//
//   constructor(public payload: PRFRequest) {
//   }
// }
//
// export class LoadClaimsAndPremiumsFailedAction implements Action {
//   type = PRFActionTypes.LOAD_CLAIMS_AND_PREMIUMS_FAIL;
//
//   constructor(public payload: any) {
//   }
// }
//
// export class LoadClaimsAndPremiumsSuccessAction implements Action {
//   type = PRFActionTypes.LOAD_CLAIMS_AND_PREMIUMS_SUCCESS;
//
//   constructor(public payload: any) {
//   }
// }


export class LoadClaimsForProducerAndYearAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_FOR_PRODUCER_YEAR;

  constructor(public payload: PRFRequest) {
  }
}

export class LoadClaimsForProducerAndYearFailedAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_FOR_PRODUCER_YEAR_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadClaimsForProducerAndYearSuccessAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_FOR_PRODUCER_YEAR_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadClaimsForCountiesAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_FOR_COUNTIES;

  constructor(public payload: any) {
  }
}

export class LoadClaimsForCountiesFailedAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_FOR_COUNTIES_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadClaimsForCountiesSuccessAction implements Action {
  type = PRFActionTypes.LOAD_CLAIMS_FOR_COUNTIES_SUCCESS;

  constructor(public payload: Map<string, GridDetailsForClaims[]>) {
  }
}

//
// export class LoadDashboardPremiumsAction implements Action {
//   type = MobileAuthPRFActionTypes.LOAD_DASHBOARD_PREMIUMS;
//
//   constructor(public payload: PRFRequest) {
//   }
// }
//
// export class LoadDashboardPremiumsFailedAction implements Action {
//   type = MobileAuthPRFActionTypes.LOAD_DASHBOARD_PREMIUMS_FAIL;
//
//   constructor(public payload: any) {
//   }
// }
//
export class LoadDashboardPremiumsSuccessAction implements Action {
  type = PRFActionTypes.LOAD_DASHBOARD_PREMIUMS_SUCCESS;

  constructor(public payload: {
    company: 'hargrove' | 'aap',
    selectedYear: any,
    premiums: Map<string, PremiumAndCoverageResponse[]>
  }) {
  }
}


//
//
// export class LoadDashboardClaimsAction implements Action {
//   type = MobileAuthPRFActionTypes.LOAD_DASHBOARD_CLAIMS;
//
//   constructor(public payload: PRFRequest) {
//   }
// }
//
// export class LoadDashboardClaimsFailedAction implements Action {
//   type = MobileAuthPRFActionTypes.LOAD_DASHBOARD_CLAIMS_FAIL;
//
//   constructor(public payload: any) {
//   }
// }
//
// export class LoadDashboardClaimsSuccessAction implements Action {
//   type = MobileAuthPRFActionTypes.LOAD_DASHBOARD_CLAIMS_SUCCESS;
//
//   constructor(public payload: any) {
//   }
// }
//
export class LoadDashboardClaimsSummarySuccessAction implements Action {
  type = PRFActionTypes.LOAD_DASHBOARD_CLAIMS_SUMMARY_SUCCESS;

  constructor(public payload: { data: GridDetailsForClaims[], company: string }) {
  }
}

//
// export class LoadDashboardClaimsSummaryFailedAction implements Action {
//   type = MobileAuthPRFActionTypes.LOAD_DASHBOARD_CLAIMS_SUMMARY_FAIL;
//
//   constructor(public payload: any) {
//   }
// }

export class LoadDashboardPremiumsSummarySuccessAction implements Action {
  type = PRFActionTypes.LOAD_DASHBOARD_PREMIUMS_SUMMARY_SUCCESS;

  constructor(public payload: { company: 'hargrove' | 'aap', selectedYear: any, premiumSummaries: PremiumSummary[] }) {
  }
}

export class LoadMappedCoveragePremiumsAction implements Action {
  type = PRFActionTypes.LOAD_COVERAGE_PREMIUMS;

  constructor(public payload: PRFRequest) {
  }
}

export class LoadMappedCoveragePremiumsFailedAction implements Action {
  type = PRFActionTypes.LOAD_COVERAGE_PREMIUMS_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadMappedCoveragePremiumsSuccessAction implements Action {
  type = PRFActionTypes.LOAD_COVERAGE_PREMIUMS_SUCCESS;

  constructor(public payload: {
    company: 'hargrove' | 'aap',
    selectedYear: any,
    premiums: Map<string, PremiumAndCoverageResponse[]>
  }) {
  }
}

export class LoadCoveragePremiumsSummarySuccessAction implements Action {
  type = PRFActionTypes.LOAD_COVERAGE_PREMIUMS_SUMMARY_SUCCESS;

  constructor(public payload: { selectedYear: any, premiumSummaries: Map<String, PremiumAndCoverageResponse[]> }) {
  }
}

export class GenericPRFServiceCallSuccess implements Action {
  type = PRFActionTypes.GENERIC_PRF_SERVICE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadPRFPRoducersForNameAction implements Action {
  type = PRFActionTypes.LOAD_PRF_PRODUCERS;

  constructor(public payload: PRFRequest) {
  }
}

export class LoadPRFPRoducersSuccessAction implements Action {
  type = PRFActionTypes.LOAD_PRF_PRODUCERS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadPRFPRoducersFailAction implements Action {
  type = PRFActionTypes.LOAD_PRF_PRODUCERS_FAIL;

  constructor(public payload: { selectedYear: any, premiums: Map<string, PremiumAndCoverageResponse[]> }) {
  }
}

export class ResetPRFPRoducersForNameAction implements Action {
  type = PRFActionTypes.RESET_PRF_PRODUCERS;
}

export class SetLatestPRFYearAction implements Action {
  type = PRFActionTypes.SET_LATEST_PRF_YEAR;

  constructor(public readonly payload: string) {
  }
}

// export class SetSelectedDashboardYearAction implements Action {
//   type = MobileAuthPRFActionTypes.SET_DASHBOARD_SELECTED_YEAR;
//
//   constructor(public payload: any) {
//
//   }
// }
//
// export class SetSelectedDashboardTaxIdsAction implements Action {
//   type = MobileAuthPRFActionTypes.SET_DASHBOARD_SELECTED_TAX_IDS;
//
//   constructor(public payload: any) {
//
//   }
// }
//
// export class SetSelectedCoverageYearAction implements Action {
//   type = MobileAuthPRFActionTypes.SET_COVERAGE_SELECTED_YEAR;
//
//   constructor(public payload: any) {
//
//   }
// }
//
// export class SetSelectedCoverageTaxIdsAction implements Action {
//   type = MobileAuthPRFActionTypes.SET_COVERAGE_SELECTED_TAX_IDS;
//
//   constructor(public payload: any) {
//
//   }
// }

export class SetSelectedCoverageCountyAction implements Action {
  type = PRFActionTypes.SET_COVERAGE_SELECTED_COUNTIES;

  constructor(public payload: any) {
  }
}

export class SetAvailableCoverageCountyAction implements Action {
  type = PRFActionTypes.SET_COVERAGE_AVAILABLE_COUNTIES;

  constructor(public payload: any) {
  }
}

export class SetSelectedYearAction implements Action {
  type = PRFActionTypes.SET_SELECTED_YEAR;

  constructor(public payload: any) {
  }
}

export class SetSelectedAssociationIdsAction implements Action {
  type = PRFActionTypes.SET_SELECTED_ASSOCIATION_IDS;

  constructor(public payload: any) {
  }
}

export class SetSelectedClaimsCountyAction implements Action {
  type = PRFActionTypes.SET_CLAIMS_SELECTED_COUNTIES;

  constructor(public payload: any) {
  }
}

export class SetAvailableClaimsCountyAction implements Action {
  type = PRFActionTypes.SET_CLAIMS_AVAILABLE_COUNTIES;

  constructor(public payload: any) {
  }
}

export class SetSelectedRainfallIntervalAction implements Action {
  type = PRFActionTypes.SET_RAINFALL_SELECTED_INTERVAL;

  constructor(public payload: { id: number, label: string }) {

  }
}

export class SetNoPolicyForYearAction implements Action {
  type = PRFActionTypes.SET_NO_POLICY_FOR_YEAR;

  constructor(public payload: boolean) {

  }
}

export class LoadPRFDashboardDataAction implements Action {
  type = PRFActionTypes.LOAD_DASHBOARD_DATA;

  constructor(public payload: { premiumRequest: PRFRequest, claimsRequest: PRFRequest }) {
  }
}

export class LoadPRFDashboardDataSuccessAction implements Action {
  type = PRFActionTypes.LOAD_DASHBOARD_DATA_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadPRFDashboardDataFailedAction implements Action {
  type = PRFActionTypes.LOAD_DASHBOARD_DATA_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllYearsDashboardDataAction implements Action {
  type = PRFActionTypes.LOAD_ALL_YEARS_DASHBOARD_DATA;

  constructor(public payload: { company: 'hargrove' | 'aap', selectedAssociationIds: string[] }) {
  }
}

export class LoadAllYearsDashboardDataSuccessAction implements Action {
  type = PRFActionTypes.LOAD_ALL_YEARS_DASHBOARD_DATA_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadAllYearsDashboardDataFailedAction implements Action {
  type = PRFActionTypes.LOAD_ALL_YEARS_DASHBOARD_DATA_FAILED;

  constructor(public payload: { selectedAssociationIds: string[] }) {
  }
}


export class SetDeafultSelectedYearAction implements Action {
  type = PRFActionTypes.SET_DEAFULT_SELECTED_YEAR;
}


/**Annual Forage Actions */

export class LoadAFDataAction implements Action {
  type = AnnualForageActionTypes.LOAD_AF_DATA;

  constructor(public payload: AFRequest) {
  }
}

export class LoadAllYearAFDataAction implements Action {
  type = AnnualForageActionTypes.LOAD_ALL_YEAR_AF_DATA;

  constructor(public payload: string[]) {
  }
}

export class AFLoadDataSuccessAction implements Action {
  type = AnnualForageActionTypes.AF_LOAD_DATA_SUCCESS;

  constructor(public payload: AnnualForageResponse[]) {
  }
}

export class AFLoadDataFailedAction implements Action {
  type = AnnualForageActionTypes.AF_LOAD_DATA_FAILED;

  constructor(public payload: any) {
  }
}

export class SetSelectedAFYearAction implements Action {
  type = AnnualForageActionTypes.SET_SELECTED_YEAR;

  constructor(public payload: string) {
  }
}

export class SetSelectedAFAssociationIdsAction implements Action {
  type = AnnualForageActionTypes.SET_SELECTED_ASSOCIATION_IDS;

  constructor(public payload: any) {
  }
}

export class SetAvailableGrowingSeasonsAction implements Action {
  type = AnnualForageActionTypes.SET_AVAILABLE_GROWING_SEASON;
}

export class SetSelectedGrowingSeasonAction implements Action {
  type = AnnualForageActionTypes.SET_SELECTED_GROWING_SEASON;

  constructor(public payload: number) {
  }
}

export class SetAvailableCountyAction implements Action {
  type = AnnualForageActionTypes.SET_AVAILABLE_COUNTIES;

  constructor(public payload: any[]) {
  }
}

export class SetSelectedCountyAction implements Action {
  type = AnnualForageActionTypes.SET_SELECTED_COUNTIES;

  constructor(public payload: any[]) {
  }
}

export class SetAvailableAFRainfallIntervalsAction implements Action {
  type = AnnualForageActionTypes.SET_AVAILABLE_INTERVALS;

  constructor(public payload: Array<{ id: number, label: string }>) {
  }
}

export class SetSelectedAFRainfallIntervalAction implements Action {
  type = AnnualForageActionTypes.SET_SELECTED_INTERVAL;

  constructor(public payload: { id: number, label: string }) {
  }
}

export class SetLatestNOAAAUpdateDate implements Action {
  type = PRFActionTypes.SET_LATEST_NOAAA_UPDATE_DATE;

  constructor(public payload: string) {
  }
}

export class DeletePremiumsCacheEntryAction implements Action {
  type = PRFActionTypes.DELETE_PREMIUM_CACHE_ENTRY;

  constructor(public payload: { key: string, company: string }) {
  }
}

export class DeleteClaimsCacheEntryAction implements Action {
  type = PRFActionTypes.DELETE_CLAIMS_CACHE_ENTRY;

  constructor(public payload: { key: string, company: string }) {
  }
}


export declare type PrfActions =
  LoadClaimsAction
  | ResetPRFPRoducersForNameAction
  | LoadClaimsFailedAction
  | LoadClaimsSuccessAction
  | SetNoPolicyForYearAction
  | LoadClaimsForProducerAndYearAction
  | LoadClaimsForProducerAndYearFailedAction
  | LoadClaimsForProducerAndYearSuccessAction
  | LoadClaimsForCountiesAction
  | LoadClaimsForCountiesSuccessAction
  | LoadClaimsForCountiesFailedAction
  | LoadPRFPRoducersForNameAction
  | LoadPRFPRoducersSuccessAction
  | LoadPRFPRoducersFailAction
  | LoadCoveragePremiumsSummarySuccessAction
  | SetSelectedCoverageCountyAction
  | LoadMappedCoveragePremiumsAction
  | LoadMappedCoveragePremiumsFailedAction
  | SetAvailableCoverageCountyAction
  | GenericPRFServiceCallSuccess
  | LoadMappedCoveragePremiumsSuccessAction
  | SetSelectedYearAction
  | SetSelectedAssociationIdsAction
  | SetAvailableClaimsCountyAction
  | SetSelectedRainfallIntervalAction
  | SetSelectedClaimsCountyAction
  | LoadDashboardPremiumsSummarySuccessAction
  | LoadDashboardPremiumsSuccessAction
  | LoadDashboardClaimsSummarySuccessAction
  | LoadPRFDashboardDataAction
  | LoadPRFDashboardDataFailedAction
  | LoadPRFDashboardDataSuccessAction
  | SetLatestPRFYearAction
  | SetDeafultSelectedYearAction
  | LoadAllYearsDashboardDataAction
  | LoadAllYearsDashboardDataFailedAction
  | LoadAllYearsDashboardDataSuccessAction
  | SetLatestNOAAAUpdateDate
  | DeletePremiumsCacheEntryAction
  | DeleteClaimsCacheEntryAction;

export declare type AnnualForageActions =
  LoadAFDataAction |
  LoadAllYearAFDataAction |
  AFLoadDataSuccessAction |
  AFLoadDataFailedAction |
  SetSelectedAFYearAction |
  SetSelectedAFAssociationIdsAction |
  SetAvailableGrowingSeasonsAction |
  SetSelectedGrowingSeasonAction |
  SetAvailableCountyAction |
  SetSelectedCountyAction |
  SetAvailableAFRainfallIntervalsAction |
  SetSelectedAFRainfallIntervalAction;
