<ng-container [formGroup]="userForm">

    <h3>{{data.isNew ? 'New User registration' : 'Edit User'}}</h3>

    <mat-grid-list cols="2" rowHeight="70px">

        <mat-grid-tile>
            <mat-form-field color="primary" appearance="standard">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" [readonly]="!data.canEdit">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-form-field color="primary" appearance="standard">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" [readonly]="!data.canEdit">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-form-field color="primary" appearance="standard">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-form-field color="primary" appearance="standard">
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="phoneNumber">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-form-field color="primary" appearance="standard">
                <mat-label>Username</mat-label>
                <input matInput formControlName="userName" [readonly]="!data.canEdit">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile>
            <mat-checkbox *ngIf="data.showPasswordCheckbox" color="primary" formControlName="showPassword">Enter Password</mat-checkbox>
            <mat-form-field color="primary" appearance="standard" *ngIf="userForm.get('showPassword').value">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="password">
            </mat-form-field>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="70px">
        <mat-grid-tile *ngIf="data.showRanchManager" class="chkbx">
            <mat-checkbox color="primary" formControlName="ranchManager">Foreman</mat-checkbox>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="data.ranchOwner && showlivestockPermission" class="chkbx">
            <mat-checkbox color="primary" formControlName="canSeeProfitloss">Can see Profit/Loss</mat-checkbox>
        </mat-grid-tile>
    </mat-grid-list>


    <!-- TODO affiliates -->

    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="null">Cancel</button>
        <button mat-flat-button color="warn" (click)="showDeleteConfirmationAlert()">Delete</button>
        <button mat-flat-button color="primary" (click)="save()" [disabled]="!userForm.valid">Save</button>
    </div>

</ng-container>
