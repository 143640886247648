import { Injectable } from '@angular/core';
import { Boundary } from '../../model';
import {Feature} from 'ol';
import { WKTGeometryConverter } from './wkt-converter.service';
import { GeometryService } from './geometry.service';
import { Polygon } from 'ol/geom';


@Injectable({
  providedIn: 'root'
})
export class BoundaryAddService {

  constructor(
    private geometryService: GeometryService,
    private wtkConverterService: WKTGeometryConverter
  ) {}

  /**
   * Creates a boundary with the geometry of a parcel,
   * calculates it's the differces and itersections with existing geometries
   * @param parcelFeature - The parcel feature
   * @param boundaries - Array of data of existing boundaries
   * @returns an object with the resulting polygons and the polygons to remove
   */
  public createBoundaryFromParcel(parcelFeature: Feature, boundaries: Boundary[]): {
    newPolygons: string[],
    modifiedPolygons: {
      geometry: string,
      parentId: number
    }[]
  } {
    let results: {
      newPolygons: string[],
      modifiedPolygons: {
        geometry: string,
        parentId: number
      }[]
    };
    const newWKTPolygon = this.wtkConverterService.featureToWKT(parcelFeature);

    results = {
      newPolygons: [newWKTPolygon],
      modifiedPolygons: []
    };
    return results;
  }

  /**
   * Adds a boundary geometry in WKT and returns an object with the new polygons
   * @param  coords - The coords of boundary polygonparcel feature
   * @returns an object with the resulting new polygons and the polygons to modify
   */
  public addBoundary(coords: any): {
    newPolygons: string[]
  } {
    if (this.geometryService.isValid(coords)) {
      const geom = new Polygon([coords]);
      const newWKTPolygon = this.wtkConverterService.polygonToWKT(geom);
      return {newPolygons: [newWKTPolygon]};
    }
  }
}


