import { HttpErrorResponse } from "@angular/common/http";
import { Action } from "@ngrx/store";
import { Observable, TimeoutError } from "rxjs";
import { ErrorsState } from "./store/errors";


export class ErrorHandler {

  public static handleHttpErrorSilently(err: HttpErrorResponse) {
    return new Observable<Action>((observer) => {
      // observer.next(new HTTPFailedAction(err));
      observer.complete()
    });
  }

  public static getNoInternetErrorEntry(ignorable = true, cause = null) {
    const errorEntry = <ErrorsState>{
      key: 'ERROR.NO_INTERNET',
      defaultMessage: null,
      messageTitle: 'ERROR.NO_INTERNET_TITLE',
      statusCode: 0,
      businessCode: 0,
      ignorable: ignorable,
      rootCause: cause
    };
    return errorEntry;
  }


  public static getNoConnectionErrorEntry(ignorable = true, cause = null) {
    const errorEntry = <ErrorsState>{
      key: 'ERROR.NO_CONNECTION',
      defaultMessage: null,
      messageTitle: 'ERROR.NO_CONNECTION_TITLE',
      statusCode: 0,
      businessCode: 0,
      ignorable: ignorable,
      rootCause: cause
    };
    return errorEntry;
  }

  public static getErrorEntry(err) {
    let statuscCode = err.status;
    let defMsg;
    let errorKey;
    let error;
    let rootCause = '';
    let businessCode;
    let additionalInfo;
    if (err.error) {
      error = err.error;
      errorKey = error.key;
      rootCause = error.rootCause;
      businessCode = error.businessCode;
      defMsg = error.defaultMessage;
      additionalInfo = error.additionalInfo;
    }

    if (statuscCode == 0 || statuscCode == 404 || statuscCode == 500) {
      rootCause = err.message + '\n' + rootCause;
    }
    if (errorKey) {
      errorKey = errorKey.toUpperCase();
    }
    const errorEntry = {
      key: errorKey,
      defaultMessage: defMsg,
      statusCode: statuscCode,
      businessCode: businessCode,
      rootCause: rootCause,
      additionalInfo: !!additionalInfo ? additionalInfo : error,
      translatedMessage: null,
      ignorable: error?.ignorable
    };
    if (!(errorEntry.businessCode || errorEntry.statusCode)) {
      console.error('Unknown Error');
      errorEntry.businessCode = 999;
      errorEntry.key = 'ERROR.HTTP.UNKOWN_ERROR';
    }
    return errorEntry;
  }


  public static getErrorEntryForZeroStatusError(error) {
    const errorEntry = {
      key: 'ERROR.HTTP.BACKEND_NOT_AVAILABLE',
      statusCode: 0,
      businessCode: 999
    };
    return errorEntry;
  }

  public static get404ErrorEntry(error: any) {
    const errorEntry = {
      key: 'ERROR.HTTP.RESOURCE_NOT_FOUND',
    };
    return errorEntry;
  }

  public static getErrorEntryForTimeout(error: TimeoutError, rootCause = null) {
    const errorEntry = {
      key: 'ERROR.HTTP.TIMEOUT_ERROR',
      statusCode: 0,
      businessCode: 998,
      rootCause
    };
    return errorEntry;

  }

  public static getUnAuthorizedErrorEntry(error) {
    const errorEntry = {
      key: 'ERROR.HTTP.401',
      statusCode: 401,
      businessCode: 9401
    };
    return errorEntry;
  }
}
