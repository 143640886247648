import { ActionTypes as uiActionTypes, UiActions } from './ui.actions';
import { PageItem } from "common-lib";
import { ActionWithPayload } from "../../types";

export interface UIState {
  showLoading: boolean;
  availableEntryPages: PageItem[],
}


export const initialState: UIState = {
  showLoading: false,
  availableEntryPages: [],

};


export function reducer(state = initialState, action: UiActions): UIState {
  switch (action.type) {
    case uiActionTypes.SET_ENABLED_ENTRY_PAGES:
      return {
        ...state,
        availableEntryPages: (action as ActionWithPayload<any>).payload,
      };
    case uiActionTypes.SHOW_LOADING:
      return {
        ...state,
        showLoading: true,
      };
    case uiActionTypes.HIDE_LOADING:
      return {
        ...state,
        showLoading: false,
      };

    default:
      return state;
  }
}
