import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { concat, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  LoadAllAnimalsAction,
  LoadAnimalByIdFailedAction,
  LoadAnimalSpeciesFailedAction,
  LoadAnimalSpeciesSuccessAction
} from '.';
import { ENVIRONMENT_TOKEN } from "../../../environment/token-variables";
import { Animal } from '../../model/LivestockModel';
import { LivestockService } from '../../services/livestock.service';
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { CurrentState } from '../current-state';
import {
  BoundaryActionTypes,
  CreateBoundarySuccessAction,
  UpdateBoundarySuccessAction
} from '../mappings/boundaries/boundaries.actions';
import { CommonState } from '../reducer';
import { SaveNewTaskSuccessAction, } from '../tasks/tasks.actions';
import { LoadUserAction } from '../user/user.actions';
import {
  AddLivestockSubscriptionAction,
  AddLivestockSubscriptionFailedAction,
  AddLivestockSubscriptionSuccessAction,
  AddNewAnimalAction,
  AddNewAnimalFailedAction,
  AddNewAnimalSuccessAction,
  CreateNewBirthRecordAction,
  CreateNewBirthRecordFailedAction,
  CreateNewBirthRecordSuccessAction,
  CreateNewLotAction,
  CreateNewLotFailedAction,
  CreateNewLotSuccessAction,
  DeleteAnimalAction,
  DeleteAnimalFailedAction,
  DeleteAnimalSuccessAction,
  DeleteLotAction,
  DeleteLotFailedAction,
  DeleteLotSuccessAction,
  LivestockActionTypes,
  LoadAllAnimalsFailedAction,
  LoadAllAnimalsSuccessAction,
  LoadAllLotsFailedAction,
  LoadAllLotsSuccessAction,
  LoadAnimalBreedsFailedAction,
  LoadAnimalBreedsSuccessAction,
  LoadAnimalByIdAction,
  LoadAnimalByIdSuccessAction,
  LoadAnimalStatusesFailedAction,
  LoadAnimalStatusesSuccessAction,
  LoadAnimalTypesFailedAction,
  LoadAnimalTypesSuccessAction,
  LoadAvailableMoveBoundariesAction,
  LoadAvailableMoveBoundariesFailedAction,
  LoadAvailableMoveBoundariesSuccessAction,
  LoadBirthMethodsFailedAction,
  LoadBirthMethodsSuccessAction,
  LoadBirthResultsFailedAction,
  LoadBirthResultsSuccessAction,
  LoadCalvingEasesFailedAction,
  LoadCalvingEasesSuccessAction,
  // LoadExpenseCategoriesFailedAction,
  // LoadExpenseCategoriesSuccessAction,
  LoadTagColorsFailedAction,
  LoadTagColorsSuccessAction,
  SetSelectedAnimal,
  SetSelectedPasture,
  UpdateAnimalAction,
  UpdateAnimalFailedAction,
  UpdateAnimalSuccessAction,
  UpdateBirthRecordAction,
  UpdateBirthRecordFailedAction,
  UpdateBirthRecordSuccessAction,
  UpdateLotAction,
  UpdateLotFailedAction,
  UpdateLotSuccessAction,
  UpdateSelectedPasture
} from './livestock.actions';
import { GenericSuccessAction } from "../base.actions";
import { RanchesActionTypes, SetSelectedRanchIdAction } from "../ranches";


@Injectable()
export class LivestockEffects extends CommonFeBaseEffects {

  @Effect()
  loadAllAnimals: Observable<Action>;

  @Effect()
  loadAllLots: Observable<Action>;

  @Effect()
  loadAnimalTypes: Observable<Action>;

  @Effect()
  loadAnimalSpecies: Observable<Action>;

  @Effect()
  loadAnimalBreeds: Observable<Action>;

  @Effect()
  loadAnimalStatuses: Observable<Action>;

  @Effect()
  loadTagColors: Observable<Action>;

  @Effect()
  loadBirthMethods: Observable<Action>;

  @Effect()
  loadBirthResults: Observable<Action>;

  @Effect()
  loadCalvingEases: Observable<Action>;

  @Effect()
  addNewAnimal: Observable<Action>;

  @Effect()
  updateAnimal: Observable<Action>;

  @Effect()
  createLot: Observable<Action>;

  @Effect()
  updateLot: Observable<Action>;

  @Effect()
  createBirthRecord: Observable<Action>;

  @Effect()
  updateBirthRecord: Observable<Action>;

  @Effect()
  deleteAnimal: Observable<Action>;

  @Effect()
  deleteLot: Observable<Action>;

  @Effect()
  addSubscription: Observable<Action>;

  @Effect()
  loadMoveBoundaries: Observable<Action>;

  @Effect()
  loadAnimalById: Observable<Action>;

  // @Effect()
  // reloadAnimalById: Observable<Action>;

  @Effect()
  reloadSelectedAnimal: Observable<Action>;

  @Effect()
  loadPastureTaskHistory: Observable<Action>;

  @Effect()
  reloadSelectedPastureTaskHistory: Observable<Action>;

  @Effect()
  animalDeleted: Observable<Action>;

  constructor(
    private actions: Actions,
    protected currentState: CurrentState<CommonState>,
    private livestockService: LivestockService,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment,
  ) {
    super();

    this.loadAllAnimals = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_ALL_ANIMALS,
        RanchesActionTypes.SET_SELECTED_RANCH_ID),
      map((action: LoadAllAnimalsAction | SaveNewTaskSuccessAction | SetSelectedRanchIdAction) => {
        if ((!!action.payload && !!(action.payload as any).id)
          || (action instanceof SetSelectedRanchIdAction || action instanceof SaveNewTaskSuccessAction)) {
          return null;
        }
        return action.payload
      }),
      switchMap((userId) => {
        return this.livestockService.getAllAnimalsForUserId(userId).pipe(
          map(animals => {
            return new LoadAllAnimalsSuccessAction(animals);
          }),
          catchError(error => {
            return of(new LoadAllAnimalsFailedAction(error));
          })
        );
      })
    );

    this.animalDeleted = this.actions.pipe(
      ofType(LivestockActionTypes.DELETE_ANIMAL_SUCCESS),
      map((action: DeleteAnimalSuccessAction) => this.currentState.snapshot.ranches.selectedRanchId),
      switchMap((ranchId) => {
        return this.livestockService.getAllAnimalsByRanch(ranchId).pipe(
          map(animals => {
            animals.map(a => {
              return a;
            })
            return new LoadAllAnimalsSuccessAction(animals);
          }),
          catchError(error => {
            return of(new LoadAllAnimalsFailedAction(error));
          })
        );
      })
    );

    this.loadAllLots = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_ALL_LOTS,
        RanchesActionTypes.SET_SELECTED_RANCH_ID),
      switchMap(() => {
        return this.livestockService.getAllLotsByRanch().pipe(
          map(response => {
            return new LoadAllLotsSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAllLotsFailedAction(error));
          })
        );
      })
    );

    this.loadAnimalTypes = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_ANIMAL_TYPES),
      switchMap(() => {
        return this.livestockService.getAnimalTypes().pipe(
          map(response => {
            return new LoadAnimalTypesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAnimalTypesFailedAction(error));
          })
        );
      })
    );

    this.loadAnimalSpecies = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_ANIMAL_SPECIES),
      switchMap(() => {
        return this.livestockService.getAnimalSpecies().pipe(
          map(response => {
            return new LoadAnimalSpeciesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAnimalSpeciesFailedAction(error));
          })
        );
      })
    );

    this.loadAnimalBreeds = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_ANIMAL_BREEDS),
      switchMap(() => {
        return this.livestockService.getAnimalBreeds().pipe(
          map(response => {
            return new LoadAnimalBreedsSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAnimalBreedsFailedAction(error));
          })
        );
      })
    );

    this.loadTagColors = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_TAG_COLORS),
      switchMap(() => {
        return this.livestockService.getTagColors().pipe(
          map(response => {
            return new LoadTagColorsSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadTagColorsFailedAction(error));
          })
        );
      })
    );

    this.loadAnimalStatuses = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_ANIMAL_STATUSES),
      switchMap(() => {
        return this.livestockService.getAnimalStatuses().pipe(
          map(response => {
            return new LoadAnimalStatusesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAnimalStatusesFailedAction(error));
          })
        );
      })
    );

    this.loadBirthResults = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_BIRTH_RESULTS),
      switchMap(() => {
        return this.livestockService.getBirthResults().pipe(
          map(response => {
            return new LoadBirthResultsSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadBirthResultsFailedAction(error));
          })
        );
      })
    );

    this.loadBirthMethods = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_BIRTH_METHODS),
      switchMap(() => {
        return this.livestockService.getBirthMethods().pipe(
          map(response => {
            return new LoadBirthMethodsSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadBirthMethodsFailedAction(error));
          })
        );
      })
    );

    this.loadCalvingEases = this.actions.pipe(
      ofType(LivestockActionTypes.LOAD_CALVING_EASE),
      switchMap(() => {
        return this.livestockService.getCalvingEases().pipe(
          map(response => {
            return new LoadCalvingEasesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadCalvingEasesFailedAction(error));
          })
        );
      })
    );

    this.addNewAnimal = this.actions.pipe(
      ofType(LivestockActionTypes.ADD_NEW_ANIMAL),
      map((action: AddNewAnimalAction) => action.payload),
      switchMap((request) => {
        return this.livestockService.createAnimal(request).pipe(
          map(animals => {
            return new AddNewAnimalSuccessAction(animals);
          }),
          catchError(error => {
            return of(new AddNewAnimalFailedAction(error));
          })
        );
      })
    );

    this.updateAnimal = this.actions.pipe(
      ofType(LivestockActionTypes.UPDATE_ANIMAL),
      map((action: UpdateAnimalAction) => action.payload),
      mergeMap((request) => {
        return this.livestockService.updateAnimal(request).pipe(
          map(animals => {
            return new UpdateAnimalSuccessAction(animals);
          }),
          catchError(error => {
            return of(new UpdateAnimalFailedAction(error));
          })
        );
      })
    );


    this.deleteAnimal = this.actions.pipe(
      ofType(LivestockActionTypes.DELETE_ANIMAL),
      map((action: DeleteAnimalAction) => action.payload),
      mergeMap((request) => {
        return this.livestockService.deleteAnimal(request).pipe(
          map(animalIds => {
            return new DeleteAnimalSuccessAction(animalIds);
          }),
          catchError(error => {
            return of(new DeleteAnimalFailedAction(error));
          })
        );
      })
    );

    this.createLot = this.actions.pipe(
      ofType(LivestockActionTypes.CREATE_NEW_LOT),
      map((action: CreateNewLotAction) => action.payload),
      switchMap((request) => {
        return this.livestockService.createLot(request).pipe(
          map(lot => {
            return new CreateNewLotSuccessAction(lot);
          }),
          catchError(error => {
            return of(new CreateNewLotFailedAction(error));
          })
        );
      })
    );

    this.updateLot = this.actions.pipe(
      ofType(LivestockActionTypes.UPDATE_LOT),
      map((action: UpdateLotAction) => action.payload),
      switchMap((request) => {
        return this.livestockService.updateLot(request).pipe(
          map(lot => {
            return new UpdateLotSuccessAction(lot);
          }),
          catchError(error => {
            return of(new UpdateLotFailedAction(error));
          })
        );
      })
    );

    this.deleteLot = this.actions.pipe(
      ofType(LivestockActionTypes.DELETE_LOT),
      map((action: DeleteLotAction) => action.payload),
      mergeMap((request) => {
        return this.livestockService.deleteLot(request).pipe(
          map(lotId => {
            return new DeleteLotSuccessAction(lotId);
          }),
          catchError(error => {
            return of(new DeleteLotFailedAction(error));
          })
        );
      })
    );

    this.createBirthRecord = this.actions.pipe(
      ofType(LivestockActionTypes.CREATE_NEW_BIRTHRECORD),
      map((action: CreateNewBirthRecordAction) => action.payload),
      switchMap((request) => {
        const br = this.livestockService.createBirthRecord(request).pipe(
          map((animal) => {
            return new CreateNewBirthRecordSuccessAction(animal);
          }),
          catchError(error => {
            if (error.key == 'ERROR.OFFLINE_CUD_GENERAL') {
              const offlineEntity = !!error.additionalInfo ? error.additionalInfo : null;
              if (!!offlineEntity) {
                return of(new LoadAnimalByIdSuccessAction({
                  animal: (offlineEntity as any),
                  setSelected: true
                }));
              } else {
                return of(new CreateNewBirthRecordFailedAction(error));
              }
            } else {
              return of(new CreateNewBirthRecordFailedAction(error));
            }
          })
        );
        const mom = this.livestockService.getAnimalById(request.damId).pipe(
          map((animal) => {
            return new LoadAnimalByIdSuccessAction({animal, setSelected: false});
          }), catchError(error => {
            return of(new LoadAnimalByIdFailedAction(error));
          })
        );

        return concat(br, mom).pipe(
          map((animal) => {
            return animal;
          })
        );
      })
    );

    this.updateBirthRecord = this.actions.pipe(
      ofType(LivestockActionTypes.UPDATE_BIRTHRECORD),
      map((action: UpdateBirthRecordAction) => action.payload),
      switchMap((request) => {
        const br = this.livestockService.updateBirthRecord(request).pipe(
          map((animal) => {
            return new UpdateBirthRecordSuccessAction(animal);
          }),
          catchError(error => {
            return of(new UpdateBirthRecordFailedAction(error));
          })
        );
        const mom = this.livestockService.getAnimalById(request.damId).pipe(
          map((animal) => {
            return new LoadAnimalByIdSuccessAction({animal, setSelected: true});
          }), catchError(error => {
            return of(new LoadAnimalByIdFailedAction(error));
          })
        );
        return concat(br, mom).pipe(
          map((animal) => {
            return animal;
          })
        );
      })
    );

    this.addSubscription = this.actions.pipe(
      ofType(LivestockActionTypes.ADD_SUBSCRIPTION),
      map((action: AddLivestockSubscriptionAction) => action.payload),
      switchMap((request) => {
        return this.livestockService.addNewSubscription(request).pipe(
          mergeMap(() => {
            return [
              new LoadUserAction(request.userId),
              new AddLivestockSubscriptionSuccessAction()
            ]
          }),
          catchError(error => {
            return of(new AddLivestockSubscriptionFailedAction(error));
          })
        );
      })
    );

    this.loadMoveBoundaries = this.actions.pipe(
      ofType(
        LivestockActionTypes.LOAD_AVAILABLE_MOVE_BOUNDARIES,
        BoundaryActionTypes.CREATE_BOUNDARY_SUCCESS,
        BoundaryActionTypes.UPDATE_BOUNDARY_SUCCESS,
        LivestockActionTypes.ADD_SUBSCRIPTION_SUCCESS
      ),
      map((action: LoadAvailableMoveBoundariesAction | CreateBoundarySuccessAction | UpdateBoundarySuccessAction) => {
        if (action instanceof CreateBoundarySuccessAction || action instanceof UpdateBoundarySuccessAction) {
          return null;
        }
        return action.payload;
      }),
      switchMap((ranchIds) => {
        return this.livestockService.getAvaialableBondariesToMove(ranchIds).pipe(
          map(response => {
            if (!response) {
              return new LoadAvailableMoveBoundariesSuccessAction(new Map());
            }
            return new LoadAvailableMoveBoundariesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadAvailableMoveBoundariesFailedAction(error));
          })
        );
      })
    );

    this.loadAnimalById = this.actions.pipe(
      ofType(
        LivestockActionTypes.LOAD_ANIMAL_BY_ID,
        LivestockActionTypes.SET_SELECTED_ANIMAL,
        LivestockActionTypes.ADD_NEW_ANIMAL_SUCCESS,
      ),
      map((action: LoadAnimalByIdAction | SetSelectedAnimal | AddNewAnimalSuccessAction) => {
        if (!!action.payload && (action.payload as []).length > 0 && !!action.payload[0].id) {
          return action.payload[0].id;
        }
        if (!!action.payload && (action instanceof LoadAnimalByIdSuccessAction)) {
          return (action as LoadAnimalByIdSuccessAction).payload.animal.id;
        }
        return action.payload;
      }),
      mergeMap((payload) => {
        if (!payload) {
          const animal = this.createEmptyAnimal();
          return of(new LoadAnimalByIdSuccessAction({animal, setSelected: true}));
        } else if ((payload) < 0) {
          return of(new GenericSuccessAction(null));
        }
        return this.livestockService.getAnimalById(+payload).pipe(
          map((animal) => {
            // console.log('Animal loaded by id. Return update animal success action');
            return new LoadAnimalByIdSuccessAction({
              animal: animal,
              setSelected: true
            });
          }), catchError(error => {
            return of(new LoadAnimalByIdFailedAction(error));
          })
        );
      })
    );

    // this.reloadAnimalById = this.actions.pipe(
    //   ofType(
    //     LivestockActionTypes.UPDATE_ANIMAL_SUCCESS,
    //   ),
    //   filter((action: UpdateAnimalSuccessAction) => {
    //     return !!action.payload.animal && !!action.payload.animal.id && !action.payload.animal.offlineCreated && action.payload.animal.id >= 0;
    //   }),
    //   map((action: UpdateAnimalSuccessAction) => {
    //     return action.payload.animal.id;
    //   }),
    //   mergeMap((id) => {
    //     return this.livestockService.getAnimalById(+id).pipe(
    //       map((animal) => {
    //         return new GenericSuccessAction(animal);
    //         // return {type: '[app] [General] Generic Success action'};
    //       }), catchError(error => {
    //         return of(new LoadAnimalByIdFailedAction(error));
    //       })
    //     );
    //   })
    // );

    this.reloadSelectedAnimal = this.actions.pipe(
      ofType(
        LivestockActionTypes.RELOAD_SELECTED_ANIMAL,
        LivestockActionTypes.LOAD_ALL_ANIMALS_SUCCESS,
        //   TasksActionTypes.SAVE_NEW_TASK_SUCCESS,
        // TasksActionTypes.LOAD_TASK_DETAIL_SUCCESS,
        // TasksActionTypes.DELETE_TASK_SUCCESS
      ),
      // filter((action: ReloadSelectedAnimalAction) => {
      //   const isThereSelectedAnimal = !!this.currentState.snapshot.livestock.selectedAnimal && +(this.currentState.snapshot.livestock.selectedAnimal) > -1;
      //   return isThereSelectedAnimal;
      // }),
      mergeMap((action) => {
        let animalId = +this.currentState.snapshot.livestock.selectedAnimal;
        if (!animalId || isNaN(animalId)) {
          return of(new GenericSuccessAction("No animal selected"));
        }
        return this.livestockService.getAnimalById(animalId).pipe(
          map((animal) => {
            return new LoadAnimalByIdSuccessAction({
              animal: animal,
              setSelected: true
            });
            // return {type: '[app] [General] Generic Success action'};
          }), catchError(error => {
            return of(new LoadAnimalByIdFailedAction(error));
          })
        );
      })
    );

    this.loadPastureTaskHistory = this.actions.pipe(
      ofType(LivestockActionTypes.SET_SELECTED_PASTURE),
      map((action: SetSelectedPasture) => action.payload),
      switchMap((pastureId: number) => {
        // console.log(pastureId)
        if (!!pastureId) {
          return this.livestockService.getPastureTaskHistory(pastureId).pipe(
            map(response => {
              return new UpdateSelectedPasture({id: pastureId, tasks: response});
            }),
            catchError(error => {
              return of(new UpdateSelectedPasture({id: pastureId, tasks: null}));
            })
          );
        } else {
          return of(new UpdateSelectedPasture(null));
        }
      })
    );

    this.reloadSelectedPastureTaskHistory = this.actions.pipe(
      ofType(
        // TasksActionTypes.SAVE_NEW_TASK_SUCCESS,
        // TasksActionTypes.LOAD_TASK_DETAIL_SUCCESS,
        // LivestockActionTypes.LOAD_ALL_ANIMALS_SUCCESS,
        // TasksActionTypes.DELETE_TASK_SUCCESS
        LivestockActionTypes.RELOAD_PASTURE_TASK_HISTORY
      ),
      // filter((action: UpdateTaskSuccessAction | SaveNewTaskSuccessAction | LoadAllAnimalsSuccessAction | LoadTaskDetailSuccessAction) => {
      //   const isSelectedExists = !!this.currentState.snapshot.livestock.selectedPasture;
      //   return isSelectedExists;
      // }),
      switchMap((payload) => {
        // console.log(pastureId)
        let selectedPastureId = +this.currentState.snapshot.livestock.selectedPasture;
        if (!!selectedPastureId || isNaN(selectedPastureId)) {
          return this.livestockService.getPastureTaskHistory(selectedPastureId).pipe(
            map(response => {
              return new UpdateSelectedPasture({id: selectedPastureId, tasks: response});
            }),
            catchError(error => {
              return of(new UpdateSelectedPasture({id: selectedPastureId, tasks: null}));
            })
          );
        } else {
          return of(new UpdateSelectedPasture(null));
        }
      })
    );
  }


  private createEmptyAnimal(): Animal {
    const selectedLotId = this.currentState.snapshot.livestock.selectedLot;
    const selectedLot = this.currentState.snapshot.livestock.lots.find(lot => lot.id == selectedLotId);
    return {
      id: null,
      typeId: null,
      speciesId: null,
      lotId: (selectedLot ? selectedLot.id : null),
    };
  }
}
