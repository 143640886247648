import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonState } from '../store/reducer';
import { CurrentState } from '../store/current-state';
import { ENVIRONMENT_TOKEN } from '../../environment/token-variables';
import { ServicesCommonBase } from "./services-common-base.service";


@Injectable()
export class ServicesPRFCommon extends ServicesCommonBase {

  constructor(
    protected currentState: CurrentState<CommonState>,
    @Inject(ENVIRONMENT_TOKEN) protected readonly environment,
    protected http: HttpClient) {
    super(currentState, environment, http);
  }

  getBaseUrl() {
    const url = this.environment.prf_backend;
    return url;
  }
}
