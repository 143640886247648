import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { from, of } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { CommonConstants } from '../app.constants';
import { CommonState } from "../store/reducer";
import { CurrentState } from "../store/current-state";
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";


@Injectable()
export class GaugesService {

  constructor(private currentState: CurrentState<CommonState>,
    @Inject(GOOGLE_ANALYTICS_TOKEN) private readonly ga,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment,
    protected http: HttpClient) {

  }

  public addGauges(gauges: Object[]) {
    const path = '/public/users/login';
    let headers = new HttpHeaders();
    const token = this.currentState.snapshot.user.token;

    headers.append('Content-Type', 'application/json');

    const url = this.environment.backend + path;
    // const url = '';

    return this.http.post(url, gauges, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    });
  }


  /**
   * Method for loading all the gauges.
   */
  public getAllGauges() {
    const path = '/private/gauges/';
    const token = this.currentState.snapshot.user.token;

    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    const url = this.environment.backend + path;

    if (token) {
      return this.http.get(url, {
        headers: new HttpHeaders({
          'Authorization': token
        })
      });
    } else {
      return this.http.get(url);
    }

  }

  private getStationMapId(stationId: string) {
    const station = this.currentState.snapshot.gauges.favouriteStations.find(st => st.stationId === stationId);
    return station ? station.name : null;
  }


  /**
   * Method for fetching weather info from DTN
   */
  public getWeatherInfo() {
    let promises: Promise<any>[] = [];
    const path = '/private/gauges/';
    const url = 'https://weather.dtn.com/basic/rest-3.4/obsfcst.wsgi';
    const stationIds = [this.currentState.snapshot.gauges.currentStationId];
    //this.currentState.snapshot.gauges.selectedStations.map(station => station.stationId);
    const retreivedData: Map<string, any> =
      this.currentState.snapshot.gauges.dataType.type === CommonConstants.GAUGE_CONSTANTS.dailyObservationDataType ?
        this.currentState.snapshot.gauges.dailyWeatherInfo :
        this.currentState.snapshot.gauges.hourlyWeatherInfo;
    for (let i = 0; i < stationIds.length; i++) {
      let params = this.getParams();
      const id = stationIds[i];
      const stationMapId = this.getStationMapId(id);
      params = params.append('stationId', id);
      // if (retreivedData && retreivedData.get(stationMapId)) {
      //   promises.push(Promise.resolve({ ...retreivedData.get(stationMapId), cached: true }));
      // } else {
      const obs = this.http.get(url, {
        responseType: 'text',
        params,
        headers: new HttpHeaders(
          {
            'Authorization': 'Basic ' + btoa("hargrove2517489:bXoj89Xtv2FWMXaa"),
            'Accept': 'application/json'
          }
        )
      }).pipe(map(response => {
        const filteredStations = this.currentState.snapshot.gauges.stations.filter(station => station.stationId + '' == stationIds[i]);
        return { response: response, station: filteredStations[0] };
      }));
      promises.push(obs.toPromise());
    }

    this.ga.trackServiceCall('getWeatherInfo', CommonConstants.GA.LABEL.GAUGE);
    let promiseAll = Promise.all(promises);
    return from(promiseAll);
  }


  public getCurrentWeatherInfo() {
    let promises: Promise<any>[] = [];
    const path = '/private/gauges/';
    const url = 'https://weather.dtn.com/basic/rest-3.4/obsfcst.wsgi';
    const stationIds = [this.currentState.snapshot.gauges.currentStationId];
    //this.currentState.snapshot.gauges.selectedStations.map(station => station.stationId);
    const retreivedData: Map<string, any> = this.currentState.snapshot.gauges.currentWeatherInfo;
    for (let i = 0; i < stationIds.length; i++) {
      let params = this.getCurrentWeatherParams();
      const id = stationIds[i];
      const stationMapId = this.getStationMapId(id);
      params = params.append('stationId', id);
      if (retreivedData && retreivedData.get(stationMapId)) {
        promises.push(Promise.resolve({ ...retreivedData.get(stationMapId), cached: true }));
      } else {
        const obs = this.http.get(url, {
          responseType: 'text',
          params,
          headers: new HttpHeaders(
            {
              'Authorization': 'Basic ' + btoa("hargrove2517489:bXoj89Xtv2FWMXaa"),
              'Accept': 'application/json'
            }
          )
        }).pipe(map(response => {
          const filteredStations = this.currentState.snapshot.gauges.stations.filter(station => station.stationId + '' == stationIds[i]);
          return { response: response, station: filteredStations[0] };
        }));
        promises.push(obs.toPromise());
      }
    }

    this.ga.trackServiceCall('getCurrentWeatherInfo', CommonConstants.GA.LABEL.GAUGE);
    let promiseAll = Promise.all(promises);

    return from(promiseAll);
  }

  public getWeatherInfoQuickLook(dates: { startDate; endDate }[], observationType: string) {
    let promises: Promise<any>[] = [];
    //TODO move this to env. property
    const url = 'https://weather.dtn.com/basic/rest-3.4/obsfcst.wsgi';

    //TODO handle the params from proper constants/factory instead of hard code it here

    const stationIds = this.currentState.snapshot.gauges.favouriteStations;
    for (let date of dates) {
      for (let i = 0; i < stationIds.length; i++) {

        let params = this.getWeatherInfoQuickLookParams(date.startDate, date.endDate, observationType);
        const id = stationIds[i].stationId;
        params = params.append('stationId', id);
        let obs = this.http.get(url, {
          responseType: 'text',
          params: params,
          headers: new HttpHeaders(
            {
              'Authorization': 'Basic ' + btoa("hargrove2517489:bXoj89Xtv2FWMXaa"),
              'Accept': 'application/json'
            }
          )
        }).pipe(map(response => {
          return { response: response, station: stationIds[i] };
        }), catchError(error => {
          // console.error(error);
          return of({ error: error, station: stationIds[i] });
        }));
        // const pushSubscriptionPromise = obs.subscribe();
        // for (let i = 0; i < 10000; i++) {
        //
        // }
        // pushSubscriptionPromise.unsubscribe();
        // pushSubscriptionPromise.unsubscribe();
        promises.push(obs.toPromise());
      }
    }
    this.ga.trackServiceCall('getWeatherInfoQuickLook', CommonConstants.GA.LABEL.GAUGE);
    let promiseAll = Promise.all(promises);

    return from(promiseAll);
  }

  private getParams() {
    const dataType = this.currentState.snapshot.gauges.dataType;
    if (dataType.type == CommonConstants.GAUGE_CONSTANTS.dailyObservationDataType) {
      return this.getMonthlyParams();
    }

    if (dataType.type == CommonConstants.GAUGE_CONSTANTS.hourlyObservationDataType) {
      return this.getDailyParams();
    }
  }

  /**
   * Return the params needed for only monthly which is dailyObservationDataType mode.
   */
  private getMonthlyParams() {
    let params = this.getBasicParams();
    params = params.append('avgTemperature', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    params = params.append('maxWindSpeed', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    params = params.append('avgWindSpeed', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    params = params.append('minTemperature', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    params = params.append('maxTemperature', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    // params = params.append('avgLeafTemperature', '1'); //Available in DataType DailyObservation Mode:0001, 0002
    params = params.append('avgSoilMoisture', '1'); //Available in  DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    params = params.append('avgSoilTemperature', 'F'); //Available in  DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    params = params.append('maxSoilTemperature', 'F'); //Available in  DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001
    params = params.append('minSoilTemperature', 'F'); //Available in  DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001


    return params;
  }

  /**
   * Return the params needed for only daily which is hourlyObservationDataType mode.
   */
  private getDailyParams() {
    let params = this.getBasicParams();
    params = params.append('relativeHumidity', '1');//Available in HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('windSpeed', '1');//Available in DataType HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('windDirection', '1');//Available in DataType HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('soilMoisture', '1');//Available in DataType HourlyObservation Mode:0001, 0002 DailyObservation Mode:0001, 0002
    params = params.append('soilTemperatures', '1');//Available in DataType --------------------- HourlyObservation Mode:0001, 0002
    params = params.append('temperature', '1'); //Available in DataType HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    // params = params.append('leafTemperature', '1'); //Available in DataType HourlyObservation Mode:0001, 0002

    return params;
  }

  /**
   * Return the params needed for both montly and daily observations
   */
  private getBasicParams() {
    const dataType = this.currentState.snapshot.gauges.dataType;
    const startDate = this.currentState.snapshot.gauges.starDate.toISOString();
    // const startDate = "2017-12-28T23:05:00.000Z"; //for testing no data
    const endDate = this.currentState.snapshot.gauges.endDate.toISOString();
    let params = new HttpParams().set('dataType', dataType.type);
    params = params.append('dataTypeMode', dataType.mode);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('precipitationAmount', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001 DailyInterpolatedObservation Mode:0001,0002,0003,0004,0005,0006 HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('solarRadiation', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001 HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('leafWetnesses', '1'); //Available in DataType HourlyObservation Mode:0001, 0002 DailyObservation Mode:0001, 0002

    return params;
  }

  private getCurrentWeatherParams() {
    let params = new HttpParams().set('dataType', CommonConstants.GAUGE_CONSTANTS.currentWeatherDataType);
    params = params.append('dataTypeMode', '0001');
    params = params.append('precipitationAmount', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001 DailyInterpolatedObservation Mode:0001,0002,0003,0004,0005,0006 HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('solarRadiation', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001 HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('temperature', '1'); //Available in DataType HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('windSpeed', '1');//Available in DataType HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('windDirection', '1');//Available in DataType HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    params = params.append('relativeHumidity', '1');//Available in HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004

    return params;
  }

  /**
   * Returns the params for quick look weather infos.
   * The params depends on the input parameters as different types should be used for the differetn quick looks.
   * @param startDate
   * @param endDate
   * @param observationDataType
   */
  private getWeatherInfoQuickLookParams(startDate: Date, endDate: Date, observationDataType: string) {
    let params = new HttpParams().set('dataType', observationDataType);
    params = params.append('dataTypeMode', '0001');
    params = params.append('startDate', startDate.toISOString());
    params = params.append('endDate', endDate.toISOString());
    params = params.append('dataTypeMode', '0001');
    params = params.append('precipitationAmount', '1'); //Available in DataType DailyForecast Mode:0001 DailyObservation Mode:0001,0002 DailyObservationExtended Mode:0001 DailyInterpolatedObservation Mode:0001,0002,0003,0004,0005,0006 HourlyForecast Mode:0001 HourlyLatestObservation Mode:0001,0002 HourlyObservations Mode:0001,0002,0003,0004
    return params;
  }

  private getDetailsParams() {

  }
}
