import { NgModule } from "@angular/core";
import { USDCurrencyPipe } from "./usd-currency.pipe";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        USDCurrencyPipe
    ],
    exports: [
        USDCurrencyPipe
    ]

  })
  export class PipesModule {
  }
  