import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from "rxjs";
import { Action, Store } from "@ngrx/store";
import * as gaugesActions from "./gauges.actions";
import {
  GaugesActionTypes,
  LoadAllGaugesSuccessAction,
  LoadWeatherInfoQuickLook7DaysFail,
  LoadWeatherInfoQuickLook7DaysSuccess,
  LoadWeatherInfoQuickLookMonth,
  LoadWeatherInfoQuickLookMonthFail,
  LoadWeatherInfoQuickLookMonthSuccess,
  LoadWeatherInfoQuickLookToday,
  LoadWeatherInfoQuickLookTodayFail,
  LoadWeatherInfoQuickLookTodaySuccess,
  LoadWeatherInfoQuickLookYear,
  LoadWeatherInfoQuickLookYearFail,
  LoadWeatherInfoQuickLookYearSuccess,
  LoadWeatherInfoSuccess,
  SelectGaugeStations
} from "./gauges.actions";
import * as xml2js from "xml2js";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CommonState } from "../reducer";
import { CommonConstants } from "../../app.constants";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { TranslateService } from "@ngx-translate/core";
import * as errorActions from "../errors/errors.actions";
import { UOMValueItem, WeatherDataResponse } from "../../model/GaugeModel";
import { ErrorsState } from "../errors";
import { CurrentState } from "../current-state";
import { GaugesService } from "../../services/gauges.service";

@Injectable()
export class GaugesEffects extends CommonFeBaseEffects {

  @Effect()
  loadAllGauges: Observable<Action>;

  @Effect()
  loadWeatherInfo: Observable<Action>;

  @Effect()
  loadWeatherInfoQuickLookYear: Observable<Action>;

  @Effect()
  loadWeatherInfoQuickLookMonth: Observable<Action>;

  @Effect()
  loadWeatherInfoQuickLookToday: Observable<Action>;

  @Effect()
  loadWeatherInfoQuickLook7Days: Observable<Action>;

  @Effect()
  setSelectedStationByDefault: Observable<Action>;

  constructor(
    private translateService: TranslateService,
    private store: Store<CommonState>,
    protected currentState: CurrentState<CommonState>,
    private actions: Actions, private gaugesService: GaugesService) {
    super();

    this.loadAllGauges = this.actions.pipe(ofType(GaugesActionTypes.LOAD_ALL_GAUGES)
      , switchMap(() => {
        return this.loadGauges().pipe(map(gauges => {
          // console.log('Loading gauges done: ' + gauges);
          return new LoadAllGaugesSuccessAction(gauges);
        }), catchError(error => {
          console.error('Error during loading gauges: ' + error);
          return this.handleHttpError(error)
        }));
      }));


    this.setSelectedStationByDefault = this.actions.pipe(ofType(gaugesActions.GaugesActionTypes.SELECT_FAVOURITE_GAUGE_STATIONS)
      , map((stations: any) => {
        const stationIds: { stationId: string }[] =
          this.currentState.snapshot.gauges.selectedStations ? this.currentState.snapshot.gauges.selectedStations : [];
        if (stations.payload && stations.payload.length === 1) {
          stationIds.push({stationId: stations.payload[0].stationId});
        }
        return new SelectGaugeStations(stationIds);
      }));

    this.loadWeatherInfoQuickLookYear = this.actions.pipe(ofType(gaugesActions.GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR)
      , map((action: LoadWeatherInfoQuickLookYear) => {
        return action.payload;
      })
      , switchMap((payload: { observationDataType: string, dates: { startDate; endDate }[] }) => {
        return this.loadQuickLookWeatherData(payload.dates, payload.observationDataType)
          .pipe(map((response: any) => {
            let convertedResponseFlat = this.convertResponse(response);
            if (convertedResponseFlat) {
              let weatherInfoMap = this.getWeatherInfoMapFromResponse(convertedResponseFlat);
              return new LoadWeatherInfoQuickLookYearSuccess({
                weatherInfo: weatherInfoMap,
                startDate: payload.dates[0].startDate,
                endDate: payload.dates[payload.dates.length - 1].endDate,
              });
            } else {

              return new LoadWeatherInfoQuickLookYearFail({
                startDate: null,
                endDate: null,
                weatherInfo: new Map(),
                loading: false,
                errorKey: "ERROR.DTN_SERVICE_ERROR"
              });
            }
          }), catchError(error => {
            console.error('loadWeatherInfoQuickLookYear failed:');
            console.error(error);
            return of(new LoadWeatherInfoQuickLookYearFail({
              startDate: null,
              endDate: null,
              weatherInfo: new Map(),
              loading: false,
              errorKey: "ERROR.DTN_SERVICE_ERROR"

            }));
          }));

      }));

    this.loadWeatherInfoQuickLookMonth = this.actions.pipe(ofType(gaugesActions.GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH)
      , map((action: LoadWeatherInfoQuickLookMonth) => {
        return action.payload;
      }), switchMap((payload: { observationDataType: string, dates: { startDate; endDate }[] }) => {
        return this.loadQuickLookWeatherData(payload.dates, payload.observationDataType)
          .pipe(map((response: any) => {
            let convertedResponseFlat = this.convertResponse(response);
            if (convertedResponseFlat) {
              let weatherInfoMap = this.getWeatherInfoMapFromResponse(convertedResponseFlat);
              return new LoadWeatherInfoQuickLookMonthSuccess({
                weatherInfo: weatherInfoMap,
                startDate: payload.dates[0].startDate,
                endDate: payload.dates[payload.dates.length - 1].endDate,
              });
            } else {
              return new LoadWeatherInfoQuickLookMonthFail({
                startDate: null,
                endDate: null,
                weatherInfo: new Map(),
                loading: false,
                errorKey: "ERROR.DTN_SERVICE_ERROR"
              });
            }
            // return new LoadWeatherInfoQuickLookYearSuccess(null);
          }), catchError(error => {
            console.error('loadWeatherInfoQuickLookMonth failed:');
            console.error(error);
            return of(new LoadWeatherInfoQuickLookMonthFail({
              startDate: null,
              endDate: null,
              weatherInfo: new Map(),
              loading: false,
              errorKey: "ERROR.DTN_SERVICE_ERROR"

            }));
          }));

      })
    );

    this.loadWeatherInfoQuickLookToday = this.actions.pipe(ofType(gaugesActions.GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY)
      , map((action: LoadWeatherInfoQuickLookToday) => {
        return action.payload;
      }), switchMap((payload: { observationDataType: string, dates: { startDate; endDate }[] }) => {
        return this.loadQuickLookWeatherData(payload.dates, payload.observationDataType)
          .pipe(map((response: any) => {
            let convertedResponseFlat;
            convertedResponseFlat = this.convertResponse(response);
            if (convertedResponseFlat) {
              let weatherInfoMap = this.getWeatherInfoMapFromResponse(convertedResponseFlat);
              return new LoadWeatherInfoQuickLookTodaySuccess({
                weatherInfo: weatherInfoMap,
                startDate: payload.dates[0].startDate,
                endDate: payload.dates[payload.dates.length - 1].endDate,
              });
            } else {
              return new LoadWeatherInfoQuickLookTodayFail({
                startDate: null,
                endDate: null,
                weatherInfo: new Map(),
                loading: false,
                errorKey: "ERROR.DTN_SERVICE_ERROR"

              });
            }
          }), catchError(error => {
            console.error('loadWeatherInfoQuickLookToday failed:');
            console.error(error);
            return of(new LoadWeatherInfoQuickLookMonthFail({
              startDate: null,
              endDate: null,
              weatherInfo: new Map(),
              loading: false,
              errorKey: "ERROR.DTN_SERVICE_ERROR"
            }));
            // return Observable.of(new errorActions.CreateErrorMessage(this.getErrorEntry(error)));
          }));
      })
    );

    this.loadWeatherInfoQuickLook7Days = this.actions.pipe(ofType(gaugesActions.GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS)
      , map((action: LoadWeatherInfoQuickLookToday) => {
        return action.payload;
      }), switchMap((payload: { observationDataType: string, dates: { startDate; endDate }[] }) => {
        return this.loadQuickLookWeatherData(payload.dates, payload.observationDataType)
          .pipe(map((response: any) => {
            let convertedResponseFlat;
            convertedResponseFlat = this.convertResponse(response);
            if (convertedResponseFlat) {
              let weatherInfoMap = this.getWeatherInfoMapFromResponse(convertedResponseFlat);
              return new LoadWeatherInfoQuickLook7DaysSuccess({
                weatherInfo: weatherInfoMap,
                startDate: payload.dates[0].startDate,
                endDate: payload.dates[payload.dates.length - 1].endDate,
              });
            } else {
              return new LoadWeatherInfoQuickLook7DaysFail({
                startDate: null,
                endDate: null,
                weatherInfo: new Map(),
                loading: false,
                errorKey: "ERROR.DTN_SERVICE_ERROR"

              });
            }
          }), catchError(error => {
            console.error('loadWeatherInfoQuickLookToday failed:');
            console.error(error);
            return of(new LoadWeatherInfoQuickLook7DaysFail({
              startDate: null,
              endDate: null,
              weatherInfo: new Map(),
              loading: false,
              errorKey: "ERROR.DTN_SERVICE_ERROR"
            }));
            // return Observable.of(new errorActions.CreateErrorMessage(this.getErrorEntry(error)));
          }));
      })
    );

    this.loadWeatherInfo = this.actions.pipe(ofType(gaugesActions.GaugesActionTypes.LOAD_WEATHHER_INFO)
      , switchMap(() => {
        const weatherInfoObs = this.loadWeatherData().pipe(map(response => {
          const dataType = this.currentState.snapshot.gauges.dataType;
          const monthly = (dataType.type === CommonConstants.GAUGE_CONSTANTS.dailyObservationDataType);
          let convertedResponseFlat = this.convertResponse(response, monthly);
          if (convertedResponseFlat) {
            let weatherInfoMap = new Map(dataType.type === CommonConstants.GAUGE_CONSTANTS.dailyObservationDataType ?
              this.currentState.snapshot.gauges.dailyWeatherInfo :
              this.currentState.snapshot.gauges.hourlyWeatherInfo);
            convertedResponseFlat.forEach((locationResponse: any) => {
              if (!locationResponse.error) {
                const placeKey = locationResponse.locationResponse[0].stationID[0];
                weatherInfoMap.set(placeKey, {
                  locationResponse: locationResponse.locationResponse,
                  retreiveTime: Date.now()
                });
              } else {
                weatherInfoMap.set(locationResponse.station.stationId, {
                  ...locationResponse.error,
                  retreiveTime: Date.now()
                });
              }
            });
            return weatherInfoMap;
          } else {
            return null;
          }
        }));

        const currentWeatherInfoObs = this.loadCurrentWeatherData().pipe(map(response => {
          const convertedResponseFlat = this.convertResponse(response);
          if (convertedResponseFlat) {
            const currentWeatherInfoMap =
              new Map<string, any>(this.currentState.snapshot.gauges.currentWeatherInfo);
            convertedResponseFlat.forEach((locationResponse: any) => {
              if (!locationResponse.error) {
                const placeKey = locationResponse.locationResponse[0].stationID[0];
                currentWeatherInfoMap.set(placeKey, locationResponse);
              } else {
                currentWeatherInfoMap.set(locationResponse.station.stationId, locationResponse.error);
              }
            });
            return currentWeatherInfoMap;
          } else {
            return null;
          }
        }));

        const combinedWeatherInfo = combineLatest([currentWeatherInfoObs, weatherInfoObs]).pipe(
          map(([currentWeather, weatherInfo]) => ({
            errorState: null,
            weatherInfo,
            currentWeather
          })),
          mergeMap((data) => {
            const returnArray = [];
            const success = new LoadWeatherInfoSuccess({
              weatherInfo: data.weatherInfo, currentWeather: data.currentWeather
            });
            returnArray.push(success);
            if (data.errorState) {
              const error = new errorActions.CreateErrorMessage(data.errorState);
              returnArray.push(error);
            }
            return returnArray;
          }));
        return combinedWeatherInfo;
      })
    );
  }

  private getWeatherInfoMapFromResponse(convertedResponseFlat) {
    let weatherInfoMap = new Map<string, any>();
    convertedResponseFlat.forEach((locationResponse: any) => {
      if (!locationResponse.error) {
        const placeKey = locationResponse.locationResponse[0].stationID[0];
        let newValue = locationResponse.locationResponse;
        newValue = newValue.map(value => {
          return value.precipitationAmount;
        });
        let weatherInfosForKey = weatherInfoMap.get(placeKey);
        if (weatherInfosForKey) {
          //it means we had error for this station
          if (!weatherInfosForKey.errorKey && Array.isArray(weatherInfosForKey)) {
            newValue = weatherInfosForKey.concat(newValue);
          }
        }
        weatherInfoMap.set(placeKey, newValue);

      } else {
        weatherInfoMap.set(locationResponse.stationId, locationResponse.error);
      }
    });
    Array.from(weatherInfoMap.keys()).forEach(key => {
      const arrayForKey = weatherInfoMap.get(key);
      let precipitationAmountMap = new Map<string, UOMValueItem>();
      if (!arrayForKey.errorKey) {
        arrayForKey.map(valueArray => {
          valueArray.forEach(amount => {
            let available = true;
            if (amount.notAvailable && amount.notAvailable[0] && amount.notAvailable[0].toLowerCase() == "true") {
              available = false
            }
            if (available) {
              let type = amount.type[0];
              let value = +amount.value[0];
              let uom = amount.uom[0];
              if (precipitationAmountMap.has(type)) {
                let soFarAmountUOM = precipitationAmountMap.get(type);
                const newAmount = +soFarAmountUOM.value + value;
                soFarAmountUOM.value = newAmount.toFixed(2) + '';
                precipitationAmountMap.set(type, soFarAmountUOM);
              } else {
                precipitationAmountMap.set(type, {type: type, value: value.toFixed(2) + '', uom: uom});
              }
            }
          });
        });
        weatherInfoMap.set(key, Array.from(precipitationAmountMap.values()));
      }
    });

    return weatherInfoMap;
  }

  private loadGauges() {
    if (this.currentState.snapshot.gauges.stations
      && this.currentState.snapshot.gauges.stations.length > 0) {
      return new Observable<any>((observer) => {
        observer.next(this.currentState.snapshot.gauges.stations);
        observer.complete()
      });
    } else {
      return this.gaugesService.getAllGauges();
    }
  }

  loadWeatherData() {
    return this.gaugesService.getWeatherInfo();
  }

  loadQuickLookWeatherData(dates, observationDataType) {
    return this.gaugesService.getWeatherInfoQuickLook(dates, observationDataType)
  }

  loadCurrentWeatherData() {
    if (this.currentState.snapshot.gauges.todaySelected) {
      return this.gaugesService.getCurrentWeatherInfo();
    }
    return new Observable<any>((observer) => {
      observer.next(null);
      observer.complete()
    });
  }

  handleDTNServiceError(error) {
    let errorEntry = this.getDTNErrorEntry(error);
    return this.getCreateErrorMessageObservable(errorEntry)
  }

  private getCreateErrorMessageObservable(errorEntry: ErrorsState) {
    return new Observable<Action>((observer) => {
      observer.next(new errorActions.CreateErrorMessage(errorEntry));
      observer.complete()
    });
  }

  private getDTNErrorEntry(error) {
    let errorEntry;
    if (error instanceof HttpErrorResponse) {
      errorEntry = this.getDTNErrorEntryForHttp(error);
    } else {
      errorEntry = this.getDTNErrorEntryForBusinessError(error)
    }
    return errorEntry;
  }

  private getDTNErrorEntryForBusinessError(error) {
    let errorXML = error;
    if (error.message) {
      errorXML = error.message;
    }
    let convertedError = this.getFaultCondition(errorXML);
    let detailElement = convertedError.faultCondition.detail[0];
    let businessCode = '8' + convertedError.faultCondition.code[0];
    let key = CommonConstants.DTN_SERVICE_ERROR_KEYS.businessError + "_" + businessCode;
    const msg = this.translateService.instant(key);
    if (msg == key) {
      key = CommonConstants.DTN_SERVICE_ERROR_KEYS.businessError;
    }
    const errorEntry = {
      errorKey: key,
      defaultMessage: '',
      statusCode: '',
      businessCode: businessCode,
      rootCause: detailElement,
      translatedMessage: null
    };
    return errorEntry;
  }

  private getDTNErrorEntryForHttp(error) {
    let errorXML = error.error;
    let convertedError = this.getFaultCondition(errorXML);
    let detailElement = convertedError.faultCondition.detail[0];
    let businessCode = '8' + convertedError.faultCondition.code[0];
    const errorEntry = {
      key: CommonConstants.DTN_SERVICE_ERROR_KEYS.httpError,
      defaultMessage: error.message,
      statusCode: error.status,
      businessCode: businessCode,
      rootCause: detailElement,
      translatedMessage: null
    };
    return errorEntry;
  }

  private getFaultCondition(errorXML: any) {
    let convertedError;
    xml2js.parseString(errorXML, function (err, result) {
      convertedError = result;
    });
    return convertedError;
  }

  private convertResponse(response, withSortingDESC: boolean = false) {
    if (!response) {
      return null;
    }
    let that = this;
    let convertedResponseFlat: WeatherDataResponse[] = [];

    for (let i = 0; i < response.length; i++) {
      let convertedJson;
      if (response[i].error) {
        let oneResponse = new WeatherDataResponse();
        oneResponse.error = response[i].error;
        oneResponse.station = response[i].station;
        convertedResponseFlat.push(oneResponse);
      } else {
        if (response[i].cached) {
          convertedResponseFlat.push(response[i]);
        } else {
          xml2js.parseString(response[i].response, function (err, result) {
            convertedJson = result;
            let oneResponse;
            if (convertedJson.faultCondition && !convertedJson.weatherDataResponse) {
              oneResponse = new WeatherDataResponse();
              let errorEntry = that.getDTNErrorEntry(response[i].response);
              oneResponse.error = errorEntry;
              oneResponse.station = response[i].station;
              // }
            } else {
              oneResponse = convertedJson.weatherDataResponse.locationResponseList[0];
            }
            oneResponse.retreiveTime = Date.now();
            convertedResponseFlat.push(oneResponse);
          });
        }
      }
    }

    if (convertedResponseFlat.length > 0) {
      if (withSortingDESC) {
        convertedResponseFlat.forEach((resps: any) => {
          if (resps.locationResponse && resps.locationResponse.length > 0) {
            resps.locationResponse.sort((a: any, b: any) => {
              if (a.validDateTime && a.validDateTime[0] && b.validDateTime && b.validDateTime[0]) {
                const aDateString = a.validDateTime[0];
                let aDate = new Date(aDateString).toISOString();
                const bDateString = b.validDateTime[0];
                let bDate = new Date(bDateString).toISOString();
                if (aDate < bDate) {
                  return 1;
                } else if (aDate) {
                  return -1;
                }
                return 1;
              }
              return 0;
            });
          }
        });
      }
      return convertedResponseFlat;
    }
    return null;
  }
}
