import { Action } from '@ngrx/store';
import { type } from "../types";
import { GaugeItem, WeatherInfoQuickLookSummary } from "../../model/GaugeModel";


export const GaugesActionTypes = {
  LOAD_ALL_GAUGES: type('[app] [Gauges] Load all gauges'),
  LOAD_ALL_GAUGES_SUCCESS: type('[app] [Gauges] Load all gauges success'),
  LOAD_ALL_GAUGES_FAIL: type('[app] [Gauges] Load all gauges fail'),
  ADD_NEW_GAUGES: type('[app] [Gauges] Add new gauges'),
  ADD_NEW_GAUGES_SUCCESS: type('[app] [Gauges] Add new gauges success'),
  ADD_NEW_GAUGES_FAIL: type('[app] [Gauges] Add new gauges fail'),
  SELECT_GAUGE_STATIONS: type('[app] [Gauges] Gauges selected'),
  SELECT_GAUGE_DATES: type('[app] [Gauges] Gauges dates selected'),
  LOAD_WEATHHER_INFO: type('[app] [Gauges] Gauges load weather info'),
  LOAD_WEATHHER_INFO_SUCCESS: type('[app] [Gauges] Gauges load weather info successed'),
  LOAD_WEATHHER_INFO_FAIL: type('[app] [Gauges] Gauges load weather info failed'),
  SELECT_GAUGE_DATA_TYPE: type('[app] [Gauges] Gauges select observe data type'),
  SELECT_FAVOURITE_GAUGE_STATIONS: type('[app] [Gauges] Gauges select favourite stations'),
  SELECT_CURRENT_GAUGE_STATION: type('[app] [Gauges] Gauges select current station'),
  ADD_FAVOURITE_GAUGE_STATIONS: type('[app] [Gauges] Gauges add favourite stations'),
  ADD_NON_FAVOURITE_GAUGE_STATION: type('[app] [Gauges] Gauges add non-favourite station'),
  PERSIST_FAVOURITE_STATIONS_FAIL: type('[app] [Gauges] Gauges persist favourite stations fail'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS: type('[app] [Gauges] Gauges load quick look 7 days weather info'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS_SUCCESS: type('[app] [Gauges] Gauges load quick look 7 days weather info successed'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS_FAIL: type('[app] [Gauges] Gauges load quick look 7 days weather info failed'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR: type('[app] [Gauges] Gauges load quick look year weather info'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR_SUCCESS: type('[app] [Gauges] Gauges load quick look year weather info successed'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR_FAIL: type('[app] [Gauges] Gauges load quick look year weather info failed'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH: type('[app] [Gauges] Gauges load quick look month weather info'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH_SUCCESS: type('[app] [Gauges] Gauges load quick look month weather info successed'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH_FAIL: type('[app] [Gauges] Gauges load quick look month weather info failed'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY: type('[app] [Gauges] Gauges load quick look today  weather info'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY_SUCCESS: type('[app] [Gauges] Gauges load quick look today weather info successed'),
  LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY_FAIL: type('[app] [Gauges] Gauges load quick look today weather info failed'),
};

export class LoadAllGaugesAction implements Action {
  type = GaugesActionTypes.LOAD_ALL_GAUGES;

  constructor() {
  }
}

export class LoadAllGaugesSuccessAction implements Action {
  type = GaugesActionTypes.LOAD_ALL_GAUGES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadAllGaugesFailAction implements Action {
  type = GaugesActionTypes.LOAD_ALL_GAUGES_FAIL;

  constructor() {
  }
}

export class AddNewGaugesAction implements Action {
  type = GaugesActionTypes.ADD_NEW_GAUGES;

  constructor(public payload: any) {
  }
}

export class AddNewGaugesSuccessAction implements Action {
  type = GaugesActionTypes.ADD_NEW_GAUGES_SUCCESS;

  constructor() {
  }
}

export class AddNewGaugesFailAction implements Action {
  type = GaugesActionTypes.ADD_NEW_GAUGES_FAIL;

  constructor() {
  }
}

export class SelectGaugeStations implements Action {
  type = GaugesActionTypes.SELECT_GAUGE_STATIONS;

  constructor(public payload: { name?: string, stationId: string }[]) {
  }
}

export class SelectFavouriteStations implements Action {
  type = GaugesActionTypes.SELECT_FAVOURITE_GAUGE_STATIONS;

  constructor(public payload: { stations: GaugeItem[], entry?: 'map' | 'detail' }) {
  }
}

export class SelectCurrentStation implements Action {
  type = GaugesActionTypes.SELECT_CURRENT_GAUGE_STATION;

  constructor(public payload: string) {
  }
}

export class AddFavouriteStation implements Action {
  type = GaugesActionTypes.ADD_FAVOURITE_GAUGE_STATIONS;

  constructor(public payload: GaugeItem) {
  }
}

export class AddNonFavouriteStation implements Action {
  type = GaugesActionTypes.ADD_NON_FAVOURITE_GAUGE_STATION;

  constructor(public payload: GaugeItem) {
  }
}


export class PersistFavouriteStationsFail implements Action {
  type = GaugesActionTypes.PERSIST_FAVOURITE_STATIONS_FAIL;

  constructor(public payload: any) {
  }
}

export class SelectGaugesDates implements Action {
  type = GaugesActionTypes.SELECT_GAUGE_DATES;

  constructor(public payload: { startDate, endDate, todaySelected }) {
  }
}

export class LoadWeatherInfoQuickLookYear implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR;

  constructor(public payload: { observationDataType: string, dates: { startDate; endDate }[] }) {
  }
}

export class LoadWeatherInfoQuickLookYearFail implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadWeatherInfoQuickLookYearSuccess implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_YEAR_SUCCESS;

  constructor(public payload: WeatherInfoQuickLookSummary) {
  }
}

export class LoadWeatherInfoQuickLookMonth implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH;

  constructor(public payload: { observationDataType: string, dates: { startDate; endDate }[] }) {
  }
}

export class LoadWeatherInfoQuickLookMonthFail implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadWeatherInfoQuickLookMonthSuccess implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_MONTH_SUCCESS;

  constructor(public payload: WeatherInfoQuickLookSummary) {
  }
}

export class LoadWeatherInfoQuickLookToday implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY;

  constructor(public payload: { observationDataType: string, dates: { startDate; endDate }[] }) {
  }
}

export class LoadWeatherInfoQuickLookTodayFail implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadWeatherInfoQuickLookTodaySuccess implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_TODAY_SUCCESS;

  constructor(public payload: WeatherInfoQuickLookSummary) {
  }
}

export class LoadWeatherInfoQuickLook7Days implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS;

  constructor(public payload: { observationDataType: string, dates: { startDate; endDate }[] }) {
  }
}

export class LoadWeatherInfoQuickLook7DaysFail implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadWeatherInfoQuickLook7DaysSuccess implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_QUICK_LOOK_7_DAYS_SUCCESS;

  constructor(public payload: WeatherInfoQuickLookSummary) {
  }
}

export class LoadWeatherInfo implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO;

  constructor() {
  }
}

export class LoadWeatherInfoFail implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadWeatherInfoSuccess implements Action {
  type = GaugesActionTypes.LOAD_WEATHHER_INFO_SUCCESS;

  constructor(public payload: { weatherInfo, currentWeather }) {
  }
}

export class SelectGaugesDataType implements Action {
  type = GaugesActionTypes.SELECT_GAUGE_DATA_TYPE;

  constructor(public payload: { type: string, mode: string }) {
  }
}


export declare type GaugesActions =
  AddNewGaugesAction
  | PersistFavouriteStationsFail
  | AddNewGaugesSuccessAction
  | AddNewGaugesFailAction
  | LoadAllGaugesFailAction
  | LoadAllGaugesSuccessAction
  | SelectGaugesDataType
  | SelectGaugeStations
  | SelectFavouriteStations
  | AddFavouriteStation
  | AddNonFavouriteStation
  | LoadWeatherInfoQuickLookToday
  | LoadWeatherInfoQuickLookTodaySuccess
  | LoadWeatherInfoQuickLookTodayFail
  | LoadWeatherInfoQuickLookMonth
  | LoadWeatherInfoQuickLookMonthSuccess
  | LoadWeatherInfoQuickLookMonthFail
  | LoadWeatherInfoQuickLookYear
  | LoadWeatherInfoQuickLookYearSuccess
  | LoadWeatherInfoQuickLookYearFail
  | LoadWeatherInfoQuickLook7Days
  | LoadWeatherInfoQuickLook7DaysSuccess
  | LoadWeatherInfoQuickLook7DaysFail
  | LoadWeatherInfoSuccess
  | SelectCurrentStation
  | SelectGaugesDates
  | LoadAllGaugesAction;



