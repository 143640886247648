import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PortalBaseEffects } from "../portalBaseEffects";
import { AuthActionTypes, CurrentState } from "common-lib";
import { HideLoadingAction } from "./ui.actions";
import { PortalAppState } from "../reducer";


@Injectable()
export class UiEffects extends PortalBaseEffects {

  // @Effect()
  // updateEntryPages: Observable<Action>;
  @Effect()
  goHome: Observable<Action>;

  constructor(
    protected currentState: CurrentState<PortalAppState>,
    private actions: Actions) {
    super(currentState);

    //TODO proper type for userdetails
    // this.updateEntryPages = this.actions.pipe(ofType(AuthActionTypes.LOGOUT_SUCCESS)
    //   , map((successAction: any) => {
    //     return new HideFullScreenLoading();
    //   }));

    this.goHome = this.actions.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS)
      , map((successAction: any) => {
        // return new SetForcedNavigationPageAction({page: HomePage, root: true});
        return new HideLoadingAction();
      }));
  }
}
