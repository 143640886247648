<div class="content">
    <mat-accordion multi="false">
        <ng-container *ngFor="let ranchWithUser of (usersOfRanches | async)">
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title><h2>{{ranchWithUser.ranch.title }}</h2>
                        <!-- <ng-container *ngIf="showId">({{ ranchWithUser.ranch.id }})</ng-container> -->
                    </mat-panel-title>
                </mat-expansion-panel-header>
    
                <section>
                    <div>
                        <h3>Members</h3>
                        <mat-list>
                            <ng-container *ngFor="let user of ranchWithUser.users">
                                <mat-list-item>
                                        <p mat-line>{{ user.firstName}} {{ user.lastName }} <span class="owner-text" *ngIf="user.id == ranchWithUser.ranch.owner?.id"> - owner</span></p>
                                        <p mat-line>{{ user.userName }}</p>
                                        <p mat-line>{{ user.email }}</p>
                                        <p mat-line *ngIf="user.ranchManager">Foreman</p>
                                  
                                        <button mat-button color="primary" (click)="openUserDetails(user, ranchWithUser)">Edit</button>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </ng-container>
                        </mat-list>
                        <button mat-flat-button color="primary" (click)="addNewUserToRanch(ranchWithUser)">Add User</button>
                    </div>
                    <div>
                        <h3>Boundaries</h3>
                        <mat-list *ngIf="(boundariesObs | async)?.length || ranchWithUser.ranch.sharedBoundaries?.length">
                            <!-- <ng-container *ngFor="let boundary of ranchWithUser.ranch.sharedBoundaries">
                                <ng-container *ngIf="boundary.visible">
                                    <mat-list-item>
                                        {{boundary.visible}}
                                        <mat-checkbox
                                            [disabled]="true"
                                            [checked]="(ranchWithUser.ranch['selectedBoundaries'].includes(boundary.id))"
                                            (change)="selectedBoundaryChanged($event, ranchWithUser)"
                                            [value]="boundary.id">{{boundary.title}}</mat-checkbox>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </ng-container>
                            </ng-container> -->
                            <ng-container *ngFor="let boundary of (boundariesObs | async)">
                                <!-- <ng-container *ngIf="boundary.visible"> -->
                                    <mat-list-item>
                                        <mat-checkbox
                                            [checked]="(ranchWithUser.ranch['selectedBoundaries'].includes(boundary.id))"
                                            (change)="selectedBoundaryChanged($event, ranchWithUser)"
                                            [value]="boundary.id">{{boundary.title}}</mat-checkbox>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                <!-- </ng-container> -->
                            </ng-container>
                        </mat-list>
                    </div>
                    <div>
                        <h3>Subscriptions</h3>
                        <ng-container *ngFor="let sub of (ownedModuleSubscriptionsObs | async)[ranchWithUser.ranch.id]">
                            <mat-list-item>
                                {{sub.title}}
                            </mat-list-item>
                            <!-- <mat-divider></mat-divider> -->
                        </ng-container>
                    </div>
                </section>
    
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</div>
