import { NotificationsActions, NotificationsActionTypes } from './notifications.actions';
import { ActionWithPayload } from "../types";
import { NotificationType } from "../../model";


export interface NotificationsState {
  messageTypes: NotificationType[];
  subscriptionTypes: any[];
  subscriptions: {
    active: boolean
    description: string
    label: string
    notificationTypeId: string | number
    pushRegistrationToken: string
    userId: string | number
  }[]
}


export const notificationsInitialState: NotificationsState = {
  messageTypes: [],
  subscriptionTypes: [],
  subscriptions: []
};


export function reducer(state = notificationsInitialState, action: NotificationsActions): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.SET_MESSAGE_TYPES_ACTION:
      return {
        ...state,
        messageTypes: (action as ActionWithPayload<any>).payload,
      };
    case NotificationsActionTypes.SET_USER_PUSH_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: (action as ActionWithPayload<any>).payload,
      };
    default:
      return state;
  }
}
