import { Injectable } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { BehaviorSubject } from 'rxjs';
import { fromLonLat } from 'ol/proj';

const LOCATION_REQUESTED = 'location_requested';

export interface PositionData {
  resolution?: number;
  projectedCurrentPositionCoords?: Coordinate;
  projectedCurrentPositionPoint?: Point;
};

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private _position$: BehaviorSubject<PositionData> = new BehaviorSubject<PositionData>(null);
  private watchPositionCallbackId: string;

  public get position$(): BehaviorSubject<PositionData> {
    return this._position$;
  }

  readonly defaultCenter = {
    latitude: 29.749907,
    longitude: -95.358421
  };

  public get projectedDefaultCenter(): Coordinate {
    return fromLonLat([this.defaultCenter.longitude, this.defaultCenter.latitude]);
  }

  private readonly positionOptions = {
    timeout: 4000,
    enableHighAccuracy: true,
    maximumAge: 3600
  };

}
