import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { UiEffects } from "./store/ui/ui.effects";
import { CommonFeEffectsModule } from 'common-lib';
import { PortalUserEffects } from "./store/user/portal-user.effects";

/** Module that runs all effects for the app. */
@NgModule({
  imports: [
    CommonFeEffectsModule,
    EffectsModule.forFeature([
      UiEffects,
      PortalUserEffects
    ])]
})
export class PortalEffectsModule {
}
