
import { getArea, getLength } from 'ol/sphere';
import { Injectable } from '@angular/core';
import {Feature} from 'ol';
import * as olExtent from 'ol/extent';


const acresInSquareMeters = 0.000247105381;
const metersInMile = 1609.344;

@Injectable({
    providedIn: 'root'
})
export class MeasurementService {

    getExtentSize(feature: Feature) {
        return olExtent.getSize(feature.getGeometry().getExtent());
    }

    getArea(feature: Feature) {
        return getArea(feature.getGeometry());
    }

    getSumBoundaryArea(features: Feature[]) {
        const area = features.reduce((sum: number, f: Feature) => sum + this.getArea(f), 0);
        return area > 0 ? (area * acresInSquareMeters).toFixed(2) + '&nbsp;ac' : null;
    }

    getBoundaryArea(feature: Feature, withUnits = true) {
        try {
            let result = (getArea(feature.getGeometry()) * acresInSquareMeters).toFixed(2);
            if (withUnits) {
                result += '&nbsp;ac'
            }
            return result;
        }
        catch (ex) {
            return 'No information.'
        }
    }

    getLength(feature: Feature) {
        const lengthInM = getLength(feature.getGeometry());
        return (lengthInM / metersInMile).toFixed(4);
    }
}

