
import { Action } from "@ngrx/store";
import { TutorialEntity } from "../../model";
import { type } from "../types";



export const TutorialActionTypes = {
  LOAD_TUTORIALS: type('[app] [Tutorial] Load tutorials'),
  LOAD_TUTORIALS_SUCCESS: type('[app] [Tutorial] Load tutorials success'),
  LOAD_TUTORIALS_FAIL: type('[app] [Tutorial] Load tutorials fail'),
};

export class LoadTutorialsAction implements Action {
  type = TutorialActionTypes.LOAD_TUTORIALS;

  constructor(public payload: boolean = true) {
  }
}

export class LoadTutorialsFailedAction implements Action {
  type = TutorialActionTypes.LOAD_TUTORIALS_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadTutorialsSuccessAction implements Action {
  type = TutorialActionTypes.LOAD_TUTORIALS_SUCCESS;

  constructor(public payload: TutorialEntity[]) {
  }
}

export declare type TutorialActions = LoadTutorialsSuccessAction | LoadTutorialsFailedAction | LoadTutorialsAction;


