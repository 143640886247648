import { ActionWithPayload } from "../types";
import { ClimateSurveyQuestion } from "../../model";
import { ClimateSmartActionTypes } from "./climate_smart.actions";


export interface ClimateSmartState {
  questions: ClimateSurveyQuestion[];
}


export const gpsEarTagInitialState: ClimateSmartState = {
  questions: []
};


export function reducer(state = gpsEarTagInitialState, action: ClimateSmartActionTypes): ClimateSmartState {
  switch (action.type) {
    case ClimateSmartActionTypes.SET_SURVEY_QUESTIONS:
      return {
        ...state,
        questions: (action as ActionWithPayload<any>).payload,
      };
    default:
      return state;
  }
}
