import { Action } from '@ngrx/store';
import { ErrorsState } from './errors.reducer';
import { type } from "../types";

export const ErrorActionTypes = {
  CREATE_ERROR: type('[net] Show error message'),
  CLEAR_ERROR: type('[net] Clear error message'),
  ACTION_EFFECT_FAILED: type('[net] Action effect failed.'),
  HTTP_FAILED: type('[net] Http failed.')
};


export class CreateErrorMessage implements Action {
  type = ErrorActionTypes.CREATE_ERROR;

  constructor(public payload: ErrorsState | string) {
  }
}

export class ClearErrorMessage implements Action {
  type = ErrorActionTypes.CLEAR_ERROR;

  constructor() {
  }
}

export class ActionEffectFailedAction implements Action {
  type = ErrorActionTypes.ACTION_EFFECT_FAILED;

  constructor(public payload: any) {
  }
}

export class HTTPFailedAction implements Action {
  type = ErrorActionTypes.HTTP_FAILED;

  constructor(public payload: any) {
  }
}

export type ErrorActions
  = CreateErrorMessage
  | ActionEffectFailedAction
  | ClearErrorMessage;
