export enum PageItemType {
  ROOT
}

export class PageItem {

  public type: PageItemType;

  // the title, to display in the menu
  public title: string;

  // the page that should be opened when the menu item is pressed
  public url: any;

  //whether it should be pushed on the navcontroller, or replace the current root (yes = pushing), default: false
  public shouldPushOnCurrentNavigationStack: boolean;

  public logo: string;

  // if this is set, this url will open internally - pushed on top - important: stuff to load must be in the whitelist (config.xml)
  // public embeddedContent: EmbeddedContentData | null;

  // if this is true, then the componenet has checks in the viewCanEnter callback. In this case loading dialog should be shown
  public requiredSystemfunction: string;
  public displayOnHomePage: boolean = true;
  public displayInMenu: boolean = false;

  private constructor(
    type: PageItemType,
    title: string,
    url: any,
    shouldPushOnCurrentNavigationStack = false,
    logo: string,
    displayOnHomePage: boolean = true,
    requiredSystemfunction: string, displayInMenu) {
    this.type = type;
    this.title = title;
    this.url = url;
    this.shouldPushOnCurrentNavigationStack = shouldPushOnCurrentNavigationStack;
    this.logo = logo;
    this.displayOnHomePage = displayOnHomePage;
    this.requiredSystemfunction = requiredSystemfunction;
    this.displayInMenu = displayInMenu;
  }

  public static ofComponent(
    titleKey: string,
    url: string,
    shouldPushOnCurrentNavigationStack = false,
    logo: string,
    requiredSystemfunction: string,
    displayOnHomePage = true,
    displayInMenu = false
  ): PageItem {
    return new PageItem(
      PageItemType.ROOT,
      titleKey,
      url,
      shouldPushOnCurrentNavigationStack,
      logo,
      displayOnHomePage,
      requiredSystemfunction,
      displayInMenu
    );
  }
}
