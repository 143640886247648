<ng-container *ngIf="(selectedAttachments|async)?.length>0">
  <div *ngFor="let attachment of selectedAttachments|async" (click)="attachmentClicked(attachment)" class="attachment-container">
    <h3>
      <span>
        <p *ngIf="attachment.title">{{attachment.title}}</p>
        <p>{{attachment.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}</p>
      </span>
      <span>
        <button mat-icon-button color="warn" (click)="deleteAttachment(attachment)">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </span>
    </h3>
    <section>
      <img mat-card-image [src]="attachment.downloadUrl | authImage | async | safeUrl">
    </section>

    <section>
      <mat-form-field color="primary" appearance="standard">
        <mat-label>Notes</mat-label>
        <textarea matInput [(ngModel)]="attachment.description"></textarea>
      </mat-form-field>
    </section>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="null">Close</button>
      <button mat-flat-button color="primary" (click)="updateAttachment(attachment)">Update</button>
    </div>
  </div>


  <!-- <mat-card class="attachment-card" *ngFor="let attachment of selectedAttachments|async"
            (click)="attachmentClicked(attachment)">
    <mat-card-title-group>
      <mat-card-title>
        <span>{{attachment.title}}</span>
        <span *ngIf="attachment.title" g> (</span>
        <span>{{attachment.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}</span>
        <span *ngIf="attachment.title">)</span>
      </mat-card-title>
      <button class="delete-attachment-button" mat-icon-button color="warn" (click)="deleteAttachment(attachment)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-title-group>
    <img mat-card-image [src]="attachment.downloadUrl  | authImage | async | safeUrl ">
    <mat-card-content>
      <mat-form-field color="primary" appearance="standard">
        <input matInput placeholder="Notes..." [(ngModel)]="attachment.description">
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button (click)="updateAttachment(attachment)" mat-button>Update</button>
      <button (click)="close()" mat-button>Close</button>
    </mat-card-actions>
  </mat-card> -->
</ng-container>

<ng-container *ngIf="!((selectedAttachments|async)?.length>0)">
  <div class="attachment-container">
    <h3>
      <span>New Attachment</span>
    </h3>

    <section>
      <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
      <img mat-card-image *ngIf="file" [src]="imageSrc">
      <ng-container *ngIf="!file">
        <button mat-button class="new-attachment-button" color="primary" (click)="fileUpload.click()">
          <mat-icon class="new-attachment-icon">library_add</mat-icon>
        </button>
      </ng-container>
    </section>

    <section>
      <mat-form-field color="primary" appearance="standard">
        <mat-label>Notes</mat-label>
        <textarea matInput [(ngModel)]="description"></textarea>
      </mat-form-field>
    </section>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="null">Close</button>
      <button mat-flat-button color="primary" (click)="save()" [disabled]="!file || wip">Save</button>
    </div>
  </div>


  <!-- <mat-card class="attachment-card">
    <mat-card-title-group>
      <mat-card-title>
        <span>New</span>
      </mat-card-title>
    </mat-card-title-group>
    <input type="file" class="file-input"
            (change)="onFileSelected($event)" #fileUpload>
    <img mat-card-image *ngIf="file" [src]="imageSrc">
    <mat-card-content>
      <ng-container *ngIf="!file">
        <button mat-button class="new-attachment-image-button" color="primary" (click)="fileUpload.click()">
          <mat-icon class="new-attachment-image">library_add</mat-icon>
        </button>
      </ng-container>

      <mat-form-field color="primary" appearance="standard">
        <input matInput placeholder="Notes..." [(ngModel)]="description">
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button (click)="save()" mat-button>Save</button>
      <button (click)="close()" mat-button>Close</button>
    </mat-card-actions>
  </mat-card> -->
</ng-container>
