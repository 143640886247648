import { type } from "../../types";
import { Action } from "@ngrx/store";

export const MapPreselectionActionTypes = {
  SET_PRESELECTED_LOCATION: type('[app] [Maps Preselection] Set preselected location'),

};

export class SetPreselectedLocationAction implements Action {
  readonly type = MapPreselectionActionTypes.SET_PRESELECTED_LOCATION;

  constructor(public payload: { location: { locationLng: string, locationLat: string } }) {
  }
}

export type MapPreselectionActions = SetPreselectedLocationAction;
