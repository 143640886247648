import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of, zip, forkJoin } from 'rxjs';
import { switchMap, map, catchError, mergeMap, concatMap } from 'rxjs/operators';
import { CommonFeBaseEffects } from '../common-fe-base.effects';
import { CurrentState } from '../current-state';
import { CommonState } from '../reducer';
import { Action } from '@ngrx/store';
import { ImagesService } from '../../services/images.service';
import { ImageActionTypes } from './images.actions';
import * as imageActions from './images.actions';


@Injectable()
export class ImagesEffects extends CommonFeBaseEffects {

  @Effect()
  loadUserCameras$: Observable<Action>;

  @Effect()
  loadQuickLook$: Observable<Action>;

  @Effect()
  loadCameraImages$: Observable<Action>;

  @Effect()
  loadMultipleCameraImages$: Observable<Action>;

  @Effect()
  loadFavoriteImages: Observable<Action>;

  @Effect()
  addFavoriteImages: Observable<Action>;

  @Effect()
  removeFavoriteImages: Observable<Action>;

  constructor(
    protected currentState: CurrentState<CommonState>,
    private action$: Actions,
    private imageService: ImagesService
  ) {
    super();
    this.loadUserCameras$ = this.action$.pipe(
      ofType(ImageActionTypes.LOAD_USER_CAMERAS),
      map((action: imageActions.LoadUserCamerasAction) => action.payload),
      switchMap((payload) => this.imageService.getUserCameras(payload.userId, payload.loadSharedCameras).pipe(
        map(cameras => new imageActions.LoadUserCamerasSuccessAction(cameras)),
        catchError(this.handleHttpError)
      )),
    );
    this.loadCameraImages$ = this.action$.pipe(
      ofType(ImageActionTypes.LOAD_CAMERA_IMAGES),
      map((action: imageActions.LoadCameraImagesAction) => action.payload),
      concatMap(({ equipmentId, date }) => this.imageService.getCameraImages(equipmentId, date).pipe(
        map(images => new imageActions.LoadCameraImagesSuccessAction(images)),
        catchError(this.handleHttpError)
      )),
    );
    this.loadQuickLook$ = this.action$.pipe(
      ofType(ImageActionTypes.LOAD_QUICK_LOOK),
      map((action: imageActions.LoadQuickLookAction) => action.payload),
      switchMap(({ userId }) => this.imageService.getUserQuickLook(userId).pipe(
        map(images => new imageActions.LoadQuickLookSuccessAction(images)),
        catchError(this.handleHttpError)
      )),
    );
    this.loadMultipleCameraImages$ = this.action$.pipe(
      ofType(ImageActionTypes.LOAD_MULTIPLE_CAMERA_IMAGES),
      map((action: imageActions.LoadMultipleCameraImagesAction) => action.payload),
      switchMap(({ equipmentIds, date }) =>
        this.imageService.getMultipleCameraImages(equipmentIds, date).pipe(
          map(images => new imageActions.LoadMultipleCameraImagesSuccessAction(images)),
          catchError(this.handleHttpError)
        )),
      // forkJoin(cameraIds.map(id => this.imageService.getCameraImages(id, date))).pipe(
      //   map(images => new imageActions.LoadMultipleCameraImagesSuccessAction(images)),
      //   catchError(this.handleHttpError)
      // )),
    );

    this.loadFavoriteImages = this.action$.pipe(
      ofType(ImageActionTypes.LOAD_FAVORITE_IMAGES),
      switchMap(() => this.imageService.getFavoriteImages().pipe(
        map(images => new imageActions.LoadFavoriteImagesSuccessAction(images)),
        catchError(this.handleHttpError)
      )),
    );
    this.addFavoriteImages = this.action$.pipe(
      ofType(ImageActionTypes.ADD_FAVORITE_IMAGE),
      map((action: imageActions.AddFavoriteImageAction) => action.payload),
      switchMap((imageId) => this.imageService.addFavoriteImages(imageId).pipe(
        map(response => new imageActions.AddFavoriteImagesSuccessAction(response.imageId)),
        catchError(this.handleHttpError)
      )),
    );
    this.removeFavoriteImages = this.action$.pipe(
      ofType(ImageActionTypes.REMOVE_FAVORITE_IMAGE),
      map((action: imageActions.RemoveFavoriteImageAction) => action.payload),
      switchMap((imageId) => this.imageService.removeFavoriteImages(imageId).pipe(
        map(response => new imageActions.RemoveFavoriteImagesSuccessAction(imageId)),
        catchError(this.handleHttpError)
      )),
    );
  }
}
