import { Action } from '@ngrx/store';
import { type } from "../../types";
import { PageItem } from "common-lib";


export const ActionTypes = {
  CHECK_SESSION: type('[portal] [User] Check session'),
  INIT_ENABLED_PAGES: type('[portal] [User] Init pages'),
};

export class CheckSessionAction implements Action {
  type = ActionTypes.CHECK_SESSION;

  constructor() {
  }
}
export class InitEnabledPagesAction implements Action {
  type = ActionTypes.INIT_ENABLED_PAGES;

  constructor() {
  }
}


export declare type PortalUserActions =
  CheckSessionAction | InitEnabledPagesAction


